import { CALL_STATUS, PAGE } from '../../utils/constants';
import ACTIONS from '../../apiConfig/actions.constants';
import { getErrorResMsg } from '../../utils/formValidations';

const initialState = {
  data: {},
  inProgress: false,
  callStatus: CALL_STATUS.PENDING,
  errorCode: '',
  errorMsg: {}
};

const passwordResetStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.PASSWORD_RESET.FORGOT_PASSWORD:
      return { ...state, inProgress: true };
    case ACTIONS.PASSWORD_RESET.FORGOT_PASSWORD_SUCCESS:
      return { ...state, inProgress: false, callStatus: CALL_STATUS.RESPONDED };

    case ACTIONS.PASSWORD_RESET.EMAIL_SEND_FAIL:
      return {
        ...state,
        inProgress: false,
        callStatus: CALL_STATUS.RESEND
      };
    case ACTIONS.PASSWORD_RESET.RESET_STATE:
      return {
        ...state,
        errorCode: '',
        errorMsg: {},
        callStatus: payload ? payload.callStatus : CALL_STATUS.PENDING
      };

    case ACTIONS.PASSWORD_RESET.PASSWORD_RESET:
      return { ...state, inProgress: true, data: payload };

    case ACTIONS.PASSWORD_RESET.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        inProgress: false,
        callStatus: CALL_STATUS.TOKEN_RESPONDED
      };
    case ACTIONS.PASSWORD_RESET.VERIFY_TOKEN_FAIL:
      return {
        ...state,
        inProgress: false,
        callStatus: CALL_STATUS.TOKEN_ERROR,
        errorMsg: getErrorResMsg(payload.error_code, PAGE.RESET_PASSWORD)
      };
    case ACTIONS.PASSWORD_RESET.PASSWORD_RESET_SUCCESS:
      return { ...state, inProgress: false, callStatus: CALL_STATUS.RESPONDED };

    case ACTIONS.PASSWORD_RESET.FORGOT_PASSWORD_FAIL:
    case ACTIONS.PASSWORD_RESET.PASSWORD_RESET_FAIL:
      return {
        ...state,
        inProgress: false,
        callStatus: CALL_STATUS.ERROR,
        errorCode: payload.error_code
      };
    default:
      return state;
  }
};

const dummy = () => {};

export { passwordResetStore, dummy };
