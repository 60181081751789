const v1 = '/api/v1';
const FOOTPRINTS_API = {
  // Authentication apis
  LOGIN: `${v1}/auth/login/`,
  PASSWORD_RESET: `${v1}/auth/password-reset/`,
  FORGOT_PASSWORD: `${v1}/auth/forgot-password/`,
  CHANGE_PASSWORD: `${v1}/auth/password-change/`,
  PASSWORD_RESET_VERIFY_TOKEN: `${v1}/auth/token-verification/`,

  // User apis
  USER: `${v1}/users/`,
  USER_WITH_ID: `${v1}/users/:userId/`,
  GET_ASSIGNEES: `${v1}/users/org-unit-users/`,
  GET_USERS_CSV_FIELDS_CONFIG: `${v1}/users/csv-fields-config/`,
  USER_PROFILE: `${v1}/users/user-profile/`,

  // Service Account apis
  SERVICE_ACCOUNT: `${v1}/users/service-user/`,
  SERVICE_ACCOUNT_WITH_ID: `${v1}/users/service-user/:userId/`,
  GET_SERVICE_ACCOUNT_CSV_FIELDS_CONFIG: `${v1}/users/service-user/csv-fields-config/`,

  // Org-unit or Departments apis
  ORG_UNITS: `${v1}/units/`,
  DEPARTMENTS: `${v1}/department/`,
  ORG_UNITS_DETAILS: `${v1}/units/:orgUnitId/`,
  ORG_UNIT_ALL: `${v1}/units/all/`,
  UPDATE_ORG_UNIT: `${v1}/units/:orgUnitId/`,
  GET_DEPARTMENTS_CSV_FIELDS_CONFIG: `${v1}/units/csv-fields-config/`,
  ORGANISATION_DETAILS: `${v1}/units/organisation-details/`,

  // Assets apis
  ASSET_TYPES: `${v1}/assets/types/`,
  ASSET_TYPES_DETAILS: `${v1}/assets/types/:assetTypeId/`,
  ASSETS_LIST: `${v1}/assets/`,
  ASSET_DETAILS: `${v1}/assets/details/:assetId/`,
  GET_GENERIC_GET_ASSETS_COUNT: `${v1}/assets/assigned/count/`,
  UPDATE_ASSET_TYPE: `${v1}/assets/types/:assetTypeId/`,
  GET_ASSET_CURRENT_LOCATION: `${v1}/assets/:assetId/location/`,
  ADD_ASSET: `${v1}/assets/add/`,
  GENERATE_QR_CODES: `${v1}/assets/create/`,
  CHECK_PDF_STATUS: `${v1}/assets/pdf-status/`,
  GET_ASSE_TYPE_COUNT: `${v1}/assets/types/count/`,
  QR_CODES_LIST: `${v1}/assets/pdf-storages/`,
  ASSET_ATTACHMENTS: `${v1}/tracking/asset-files/`,
  REMOVE_ASSET_ASSIGNEE: `${v1}/tracking/assignee/`,
  UPDATE_ASSET_INFO: `${v1}/tracking/name/`,
  UPDATE_ATTRIBUTE_ASSET: `${v1}/tracking/attribute/`,
  ADD_ASSET_ASSIGNEE: `${v1}/tracking/assignee/`,
  ADD_ASSET_ATTACHMENTS: `${v1}/tracking/notes/`,
  GET_ASSET_CSV_FIELDS_CONFIG: `${v1}/assets/csv-fields-config/`,
  GET_ASSET_TYPES_CSV_FIELDS_CONFIG: `${v1}/assets/types/csv-fields-config/`,
  REMOVE_ASSET: `${v1}/assets/:assetId/delete/`,
  ASSET_REPORT: `${v1}/assets/date-report/`,
  ASSET_REPORT_EXPORT: `${v1}/assets/date-report-export/`,
  ASSET_CHECK_IN: `${v1}/tracking/checkin/`,

  // Attributes APIs
  ASSET_ATTRIBUTES: `${v1}/attributes/list/`,
  ADD_ASSET_ATTRIBUTES: `${v1}/attributes/`,
  ASSET_ATTRIBUTE_DETAILS: `${v1}/attributes/:assetAttrId/`,
  UPDATE_ASSET_ATTRIBUTE: `${v1}/attributes/:assetAttrId/`,
  GET_ATTRIBUTES_CSV_FIELDS_CONFIG: `${v1}/attributes/csv-fields-config/`,

  // New attribute APIs
  ATTRIBUTES_LIST: `${v1}/attributes/list/`,

  // Tracking logs apis
  TRACKING_LOGS: `${v1}/tracking/logs/`,
  TRACKING_LOG_DETAIL: `${v1}/tracking/:assetId/tracking-log/:logId/`,
  GET_TRACKING_LOGS_LOCATIONS: `${v1}/tracking/logs/location/`,

  // Customise
  CUSTOMISE: `${v1}/units/customise/`,

  // Excel Import
  ADD_ASSET_THROUGH_EXCEL: `${v1}/assets/add-excel/`,
  ADD_DEPARTMENTS_THROUGH_EXCEL: `${v1}/units/add-excel/`,
  ADD_USERS_THROUGH_EXCEL: `${v1}/users/add-excel/`,
  ADD_ASSET_TYPES_THROUGH_EXCEL: `${v1}/assets/types/add-excel/`,
  ADD_SERV_USERS_THROUGH_EXCEL: `${v1}/users/service-user/add-excel/`,

  // Excel Export
  EXPORT_USERS_CSV: `${v1}/users/export-csv/`,
  EXPORT_SERVICE_USERS_CSV: `${v1}/users/service-user/export-csv/`,
  EXPORT_ASSETS_CSV: `${v1}/assets/export-csv/`,
  EXPORT_ASSET_LOGS_CSV: `${v1}/tracking/export-csv/`,

  // Roles
  ROLES: `${v1}/auth/roles/`,
  ROLES_DETAIL: `${v1}/auth/roles/:roleId/`,

  // Permissions
  PERMISSIONS: `${v1}/auth/permissions/`,

  // Notification
  GET_NOTIFICATION: `${v1}/notifications/`,
  UPDATE_NOTIFICATION: `${v1}/notifications/:id/update/`,
  UPDATE_ALL_NOTIFICATION: `${v1}/notifications/update/all/`,

  // Business Rules
  RULES: `${v1}/rules/`,
  GET_RULE: `${v1}/rules/:businessRuleId/`,
  GET_RULES_RESOURCES: `${v1}/rules/resources/`,
  GET_RULES_ACTIONS: `${v1}/rules/actions/`
};

const GOOGLE_API = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDugQLdJuNC3PgY-azDCW3FY35Aqmf8UwM&libraries=places`;
export { v1, FOOTPRINTS_API, GOOGLE_API };
