/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';

import Layout from './Layout/Layout';
import NavBar from '../containers/NavBar/NavBar';
import { convertHextoRgb } from '../utils/common';
import { getSession, handleUrl, getCookieData } from '../utils/authService';
import UserPermissionsContextProvider from '../contexts/UserPermissionsContext';

const setThemeColor = (hexColor, rgbColor) => {
  document.documentElement.style.setProperty('--primary-color', `#${hexColor}`);
  document.documentElement.style.setProperty('--primary-color-rgb', rgbColor);
};

const AuthRoute = ({
  component: C,
  props: cProps,
  pageNotFound: notFound,
  page,
  ...rest
}) => {
  // Get props, session and cookie if present
  const sessionData = getSession(cProps.sessionCookie);
  const rememberData = getSession(cProps.rememberCookie);

  // Get top navigation bar related data
  const [
    firstName,
    lastName,
    organizationName,
    logo,
    permissions,
    organisationAdmin
  ] = getCookieData(sessionData, rememberData);

  // Set the theme color and logo
  setThemeColor(sessionData.color, convertHextoRgb(`#${sessionData.color}`));

  const fullName =
    (firstName && firstName.length > 0) || (lastName && lastName.length > 0)
      ? `${firstName} ${lastName}`
      : 'User';

  const authorizedComponent = (
    <UserPermissionsContextProvider value={permissions}>
      <NavBar userName={fullName} logo={logo} {...cProps} />
      <div className="container footprints-body">
        <Layout
          orgnizationName={organizationName}
          organisationAdmin={organisationAdmin}
          {...cProps}
        >
          <Route
            {...rest}
            render={props => (
              <C
                organisationAdmin={organisationAdmin}
                {...props}
                {...cProps}
                inProgress
              />
            )}
          />
        </Layout>
      </div>
    </UserPermissionsContextProvider>
  );

  return <>{handleUrl(authorizedComponent)}</>;
};

export default AuthRoute;
