import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  data: {},
  errorCode: '',
  inProgress: false,
  showAttachment: false
};

const DetailsReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.USERS.GET_USER_INFO:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.USERS.GET_USER_INFO_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_INFO:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_INFO_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };
    case ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_INFO_SUCCESS: {
      return {
        ...state,
        data: payload,
        inProgress: false
      };
    }

    case ACTIONS.ASSET_ATTRIBUTES.ASSET_ATTRIBUTE_DETAILS:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSET_ATTRIBUTES.ASSET_ATTRIBUTE_DETAILS_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSET_TYPES.ASSET_TYPES_DETAILS:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSET_TYPES.ASSET_TYPES_DETAILS_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };
    case ACTIONS.ASSET_ATTRIBUTES.RESET_STATE:
      return { ...initialState };

    case ACTIONS.ASSETS.GET_ASSET_DETAILS:
      return { ...state, data: payload, inProgress: true };

    case ACTIONS.ASSETS.GET_ASSET_DETAILS_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ORG_UNITS.GET_ORG_UNITS_DETAILS:
      return { ...state, inProgress: true };

    case ACTIONS.ASSETS.GET_ASSET_DETAILS_SUCCESS:
    case ACTIONS.ASSET_TYPES.ASSET_TYPES_DETAILS_SUCCESS:
    case ACTIONS.USERS.GET_USER_INFO_SUCCESS:
    case ACTIONS.ASSET_ATTRIBUTES.ASSET_ATTRIBUTE_DETAILS_SUCCESS:
    case ACTIONS.ORG_UNITS.GET_ORG_UNITS_DETAILS_SUCCESS: {
      return {
        ...state,
        data: payload,
        inProgress: false
      };
    }

    case ACTIONS.ASSETS.GET_ASSET_TRACKING_LOG:
      return { ...state, inProgress: true };
    case ACTIONS.ASSETS.GET_ASSET_TRACKING_LOG_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ASSETS.GET_ASSET_TRACKING_LOG_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ORG_UNITS.GET_ORG_UNITS_DETAILS_FAIL:
      return { ...state, data: payload.error_code, inProgress: false };

    case ACTIONS.DETAILS.RESET_STATE: {
      return { ...state, errorCode: '' };
    }

    case ACTIONS.ASSETS.SHOW_ASSET_ATTACHMENT_IMAGE:
      return { ...state, showAttachment: true };

    case ACTIONS.ASSETS.HIDE_ASSET_ATTACHMENT_IMAGE:
      return { ...state, showAttachment: false };

    case ACTIONS.ASSETS.REMOVE_ASSET_ASSIGNEE:
      return { ...state, inProgress: true };
    case ACTIONS.ASSETS.REMOVE_ASSET_ASSIGNEE_SUCCESS:
      return { ...state, inProgress: false };
    case ACTIONS.ASSETS.REMOVE_ASSET_ASSIGNEE_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSETS.REMOVE_ASSET:
      return { ...state, inProgress: true };
    case ACTIONS.ASSETS.REMOVE_ASSET_SUCCESS:
      return { ...state, inProgress: false };
    case ACTIONS.ASSETS.REMOVE_ASSET_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.TRAKING_LOGS.SET_LOADER_TRUE:
      return { ...state, inProgress: true };
    case ACTIONS.TRAKING_LOGS.SET_LOADER_FALSE:
      return { ...state, inProgress: false };

    default:
      return state;
  }
};

const dummy = () => {};

export { DetailsReducerStore, dummy };
