import { call, put } from 'redux-saga/effects';
import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* createServiceAccount(action) {
  let response;
  const createData = {
    service_users: action.data.data
  };
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.SERVICE_ACCOUNT,
      createData
    );

    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNT_SUCCESS,
        payload: response.data
      });
      action.data.props.history.goBack();
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNT_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNT_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* updateServiceAccount(action) {
  let response;
  const { userId } = action.data.props.match.params;
  try {
    const url = reverse(FOOTPRINTS_API.SERVICE_ACCOUNT_WITH_ID, {
      userId
    });
    response = yield call(window.axios.putData, url, action.data.data[0]);

    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ACTIONS.SERVICE_ACCOUNTS.UPDATE_SERVICE_ACCOUNT_SUCCESS,
        payload: response.data
      });
      action.data.props.history.goBack();
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.SERVICE_ACCOUNTS.UPDATE_SERVICE_ACCOUNT_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.SERVICE_ACCOUNTS.UPDATE_SERVICE_ACCOUNT_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getUserDepartments(action) {
  let response;
  try {
    const URL = `${FOOTPRINTS_API.ORG_UNITS}`;
    response = yield call(window.axios.getData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.SERVICE_ACCOUNTS.GET_USER_DEPARTMENTS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.SERVICE_ACCOUNTS.GET_USER_DEPARTMENTS_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.SERVICE_ACCOUNTS.GET_USER_DEPARTMENTS_FAIL,
      payload: e
    });
  }
}
export { createServiceAccount, updateServiceAccount, getUserDepartments };
