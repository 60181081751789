import ACTIONS from '../../apiConfig/actions.constants';
import { STORE } from '../../utils/constants';

const initialState = {
  [STORE.USER_DETAILS]: {},
  [STORE.USER_LIST]: {},
  [STORE.ORG_UNITS_LIST]: {},
  [STORE.ASSET_ATTRIBUTES]: {},
  [STORE.ASSET_TYPES]: {},
  [STORE.ORG_UNITS]: [],
  [STORE.ORG_UNITS_DETAILS]: {},
  [STORE.QR_CODES_LIST]: {},
  [STORE.ASSETS_LIST]: {},
  [STORE.SERVICE_ACCOUNT_LIST]: {},
  errorCode: '',
  inProgress: false
};

const listReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.USERS.GET_USER_LIST:
      return { ...state, inProgress: true };
    case ACTIONS.USERS.GET_USER_LIST_SUCCESS: {
      return { ...state, [STORE.USER_LIST]: payload, inProgress: false };
    }
    case ACTIONS.USERS.GET_USER_LIST_FAIL:
      return { ...state, data: payload, inProgress: false };

    case ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_LIST:
      return { ...state, inProgress: true };
    case ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_LIST_SUCCESS: {
      return {
        ...state,
        [STORE.SERVICE_ACCOUNT_LIST]: payload,
        inProgress: false
      };
    }
    case ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_LIST_FAIL:
      return { ...state, data: payload, inProgress: false };

    case ACTIONS.USERS.ADD_USER:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.USERS.ADD_USER_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.USERS.ADD_USER_FAIL:
      return { ...state, data: payload, inProgress: false };

    case ACTIONS.USERS.UPDATE_USER:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.USERS.UPDATE_USER_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.USERS.UPDATE_USER_FAIL:
      return { ...state, data: payload, inProgress: false };

    case ACTIONS.ORG_UNITS.GET_ORG_UNITS_LIST:
      return { ...state, inProgress: true };
    case ACTIONS.ORG_UNITS.GET_ORG_UNITS_LIST_SUCCESS: {
      return { ...state, [STORE.ORG_UNITS_LIST]: payload, inProgress: false };
    }
    case ACTIONS.ORG_UNITS.GET_ORG_UNITS_LIST_FAIL:
      return { ...state, data: payload, inProgress: false };

    case ACTIONS.ORG_UNITS.GET_ORG_UNITS_DETAILS:
      return { ...state, inProgress: true };
    case ACTIONS.ORG_UNITS.GET_ORG_UNITS_DETAILS_SUCCESS: {
      return {
        ...state,
        title: payload.name,
        [STORE.ORG_UNITS_LIST]: payload.child,
        [STORE.ORG_UNITS_DETAILS]: payload,
        orgId: payload.id,
        inProgress: false
      };
    }
    case ACTIONS.ORG_UNITS.GET_ORG_UNITS_DETAILS_FAIL:
      return { ...state, data: payload, inProgress: false };

    case ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_ATTRIBUTES:
      return { ...state, inProgress: true };
    case ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_ATTRIBUTES_SUCCESS: {
      return { ...state, [STORE.ASSET_ATTRIBUTES]: payload, inProgress: false };
    }
    case ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_ATTRIBUTES_FAIL:
      return { ...state, inProgress: false };

    case ACTIONS.ASSET_TYPES.GET_ASSET_TYPES:
      return { ...state, inProgress: true };
    case ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_SUCCESS: {
      return { ...state, [STORE.ASSET_TYPES]: payload, inProgress: false };
    }
    case ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_FAIL: {
      return { ...state, inProgress: false };
    }

    case ACTIONS.QR_CODES.GET_QR_CODES:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.QR_CODES.GET_QR_CODES_SUCCESS:
      return { ...state, [STORE.QR_CODES_LIST]: payload, inProgress: false };
    case ACTIONS.QR_CODES.GET_QR_CODES_FAIL:
      return { ...state, data: payload, inProgress: false };

    case ACTIONS.ASSETS.GET_ASSETS_LIST:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSETS.GET_ASSETS_LIST_SUCCESS:
      return { ...state, [STORE.ASSETS_LIST]: payload, inProgress: false };
    case ACTIONS.ASSETS.GET_ASSETS_LIST_FAIL:
      return { ...state, data: payload, inProgress: false };
    default:
      return state;
  }
};

const dummy = () => {};

export { listReducerStore, dummy };
