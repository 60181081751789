import React from 'react';
import { reverse } from 'named-urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FOOTPRINTS_ROUTES, ASSET_UPDATE_PAGES } from '../config';
import { getNormalId } from './common';
import DataNotFound from '../components/DataNotFound/DataNotFound';
import { ATTRIBUTE_TYPES, SWITCH, APP_RESOURCE_CLASSES } from './constants';
import ACTIONS from '../apiConfig/actions.constants';
import footprintsHitoLogo from '../images/footprints-white-wide.svg';
import footprintsVidaLogo from '../images/footprints-logo-vida-white.png';

const getFootprintsLogo = () => {
  let img = footprintsHitoLogo;
  let className = 'hito';
  const domain = window.location.hostname;

  if (domain.includes('vida')) {
    img = footprintsVidaLogo;
    className = 'vida';
  }

  return [img, className];
};

const getNavBarLogo = () => {
  let img = footprintsHitoLogo;
  let className = 'hito';
  const domain = window.location.hostname;

  if (domain.includes('vida')) {
    img = footprintsVidaLogo;
    className = 'vida';
  }

  return [img, className];
};

const showUserDetails = (userId, props) => {
  const url = reverse(FOOTPRINTS_ROUTES.USERS.DETAIL.SHOW, { userId });
  props.history.push(url);
  return true;
};

const showServiceAccountDetails = (userId, props) => {
  const url = reverse(FOOTPRINTS_ROUTES.SERVICE_ACCOUNTS.DETAIL.SHOW, {
    userId
  });
  props.history.push(url);
  return true;
};

const getUserCard = data => {
  const userCard = Object.values(data).map(userData => {
    const Id = userData.id;
    const title =
      userData.first_name && userData.last_name
        ? `${userData.first_name} ${userData.last_name}`
        : 'User';

    const body = (
      <div className="card-data-body user-email">Email: {userData.email}</div>
    );
    const serviceUser = userData.service_user;
    return { title, body, Id, serviceUser };
  });

  return userCard;
};

const getServiceAccountCard = data => {
  const serviceAccountCard = Object.values(data).map(serviceAccountData => {
    const Id = serviceAccountData.id;
    const title = serviceAccountData.first_name
      ? serviceAccountData.first_name
      : 'Service Account';

    const body = (
      <div className="card-data-body user-email">
        Department: {serviceAccountData.departments[0].name}
      </div>
    );
    const serviceUser = serviceAccountData.service_user;
    return { title, body, Id, serviceUser };
  });

  return serviceAccountCard;
};

const getOrgUnits = data => {
  const orgCard =
    data && data !== 'null'
      ? Object.values(data).map(orgData => {
          const Id = orgData.id;
          const title = orgData.name;
          const disable = !orgData.has_children;
          const body = <div className="card-data-body">testing</div>;
          return { title, body, Id, disable };
        })
      : [];
  return orgCard;
};

const getOrgUnitsNext = (orgUnitId, props) => {
  const url = reverse(FOOTPRINTS_ROUTES.ORG_UNITS.DETAIL.SHOW, { orgUnitId });
  props.history.push(url);
};

const getOrgUnitUrl = orgUnitId => {
  const url = reverse(FOOTPRINTS_ROUTES.ORG_UNITS.DETAIL.SHOW, { orgUnitId });
  return url;
};

const getAssetAttributes = data => {
  const assetCard =
    data &&
    data instanceof Array &&
    data.map(attribute => {
      const Id = attribute.id;
      const title = attribute.name;
      const attributeType = ATTRIBUTE_TYPES[attribute.attr_type];
      const IsBoolean = attributeType === SWITCH;
      const values =
        attribute &&
        attribute.values &&
        attribute.values.map((each, i) => {
          const value = IsBoolean ? String(each.choice) : each.choice;
          if (i < attribute.values.length - 1) {
            return `${value}, `;
          }
          return value;
        });
      const body = <div className="card-data-body">{values || '-'}</div>;
      return { title, body, Id };
    });
  return assetCard || [];
};

const getAssetTypes = data => {
  const assetCard = Object.values(data).map(assetData => {
    const Id = assetData.id;
    const title = assetData.name;
    const body = <div className="card-data-body">{assetData.color}</div>;
    return { title, body, Id };
  });
  return assetCard;
};

const redirect = (props, url) => {
  props.history.push(url);
};

const getFormatOfdData = dData => {
  const format = dData
    ? dData.map(department => {
        return { value: department.id, label: department.name };
      })
    : [];
  return format;
};

const redirectToDepartments = () => {
  return true;
};

const redirectAssetTypeDetail = (assetTypeId, props) => {
  const url = reverse(FOOTPRINTS_ROUTES.ASSET_TYPES.DETAIL.SHOW, {
    assetTypeId
  });
  props.history.push(url);
};

const redirectAssetAttributeDetail = (assetAttrId, props) => {
  const url = reverse(FOOTPRINTS_ROUTES.ATTRIBUTES.DETAIL.SHOW, {
    assetAttrId
  });
  props.history.push(url);
};

function getRecursiveData(data, preData) {
  for (const dData of data) {
    const { name, id, child } = dData;
    dData.label = name;
    dData.orgUnitId = id;

    if (preData.length > 0 && preData.includes(dData.orgUnitId)) {
      dData.checked = true;
    }

    dData.children = child || [];
    if (dData.child && dData.child.length > 0) {
      getRecursiveData(dData.children, preData);
    } else {
      delete dData.child;
    }
  }
  return data;
}

const getFomatedOrgUnitData = (OrgUnitData, preData) => {
  let formattedData = [];
  const pData = getNormalId(preData);
  if (OrgUnitData && OrgUnitData.length > 0) {
    formattedData = getRecursiveData(OrgUnitData, pData);
  }
  return formattedData;
};

function onPagination(stage, totalPages) {
  let { currentPage } = this.state;
  let { start } = this.state;
  let { end } = this.state;
  if (stage === 'next') {
    currentPage += 1;
    if (currentPage === this.state.end + 1) {
      start += 1;
      end = currentPage;
    }
  } else if (stage === 'prev') {
    currentPage -= 1;
    if (currentPage === this.state.start - 1) {
      start = currentPage;
      end -= 1;
    }
  } else if (stage === 'prevEllipsis') {
    if (this.state.start > 5) {
      start = this.state.start - 5;
      end = this.state.end - 5;
    } else if (this.state.start < 5) {
      start = 1;
      end = start + 4;
    } else {
      start = this.state.start;
      end = this.state.end;
    }
    currentPage = start;
  } else if (stage === 'lastPage') {
    end = totalPages;
    start = totalPages - 4;
    currentPage = totalPages;
  } else if (stage === 'firstPage') {
    end = 5;
    currentPage = 1;
    start = 1;
  } else {
    if (this.state.end + 4 <= totalPages) {
      start = this.state.start + 5;
      end = this.state.end + 5;
    } else {
      start = totalPages - 4;
      end = totalPages;
    }
    currentPage = start;
  }
  this.setState({
    end,
    start,
    currentPage
  });
}

function onPageChange(number) {
  this.setState({ currentPage: number });
}

function getBodyComponenet(component, isDataFound, name) {
  return isDataFound ? component : <DataNotFound name={name} />;
}

function submitFilters(event) {
  event.preventDefault();
  const searchString = this.state[this.SEARCH_FIELD_ID];
  const { page, makeCall } = this.props;
  if (searchString.length > 0) {
    this.setState({ enableSearch: true });
  } else {
    this.setState({ enableSearch: false });
  }
  makeCall(ACTIONS.FILTER.GET_FILTER_RESULT, { searchString, page });
}

function handleFiltersChange(event) {
  this.setState({ [event.target.id]: event.target.value });
  this.props.makeCall(ACTIONS.COMMON_STORE.COMMON_RESET_STATE);
}

function onEnter(event) {
  const code = event.keyCode || event.which;
  if (code === 13) {
    // 13 is the enter keycode
    this.submitFilters(event);
  }
}

const showAssetDetails = (assetId, props) => {
  const { history } = props;
  const url = reverse(FOOTPRINTS_ROUTES.ASSET.DETAIL.SHOW, { assetId });

  history.push(url);
  return true;
};

const getFormattedAssets = (Data, showAsset, props) => {
  if (
    Data &&
    Object.keys(Data).length > 0 &&
    Data.results &&
    Data.results.length > 0
  ) {
    const Theader = (
      <tr>
        <th>Asset</th>
        <th>Name</th>
        <th>Asset Type</th>
      </tr>
    );
    let customKey = 0;
    const Tbody = Data.results.map(each => {
      const {
        asset_type: AssetType,
        asset_id: assetIds,
        name: AssetName,
        id: assetPkId,
        assigned: assetAssigned
      } = each;
      const { asset_fp_id: assetId } = assetIds;
      customKey += 1;
      return (
        <tr
          key={customKey}
          onClick={eve => {
            showAsset(assetPkId, props);
          }}
        >
          <td id="assetId">{String(assetId).slice(0, 7)}</td>
          <td id="assetName">{AssetName || '-'}</td>
          <td id="assetType">
            {AssetType.name}
            {assetAssigned && (
              <span className="float-right mr-3">
                <FontAwesomeIcon icon={faCircle} />
              </span>
            )}
          </td>
        </tr>
      );
    });
    return [{ Theader, Tbody, count: Data.count }];
  }
  return [];
};

const getFormattedQrCodes = Data => {
  if (
    Data &&
    Object.keys(Data).length > 0 &&
    Data.pdf_storages &&
    Data.pdf_storages.results.length > 0
  ) {
    const Theader = (
      <tr>
        <th>PDF</th>
        <th>Asset Type</th>
        <th>Assets Count</th>
        <th>Status</th>
        <th>Link</th>
      </tr>
    );
    let customKey = 0;
    const Tbody = Data.pdf_storages.results.map(each => {
      const {
        assets_count: AssetsCount,
        pdf_id: PdfId,
        state: Status,
        asset_type: AssetType,
        url
      } = each;
      customKey += 1;
      return (
        <tr key={customKey}>
          <td id="pdfId">{PdfId.slice(0, 7)}</td>
          <td id="assetTYpe">{AssetType || '-'}</td>
          <td id="assetsCount">{AssetsCount || '-'}</td>
          <td id="status">{Status || '-'}</td>
          <td id="pdfLink">
            {url ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                PDF
              </a>
            ) : (
              '-'
            )}
          </td>
        </tr>
      );
    });

    return [{ Theader, Tbody, count: Data.pdf_storages.count }];
  }
  return [];
};

const showTrackingLogDetails = (assetId, logId, props) => {
  const url = reverse(FOOTPRINTS_ROUTES.ASSET.DETAIL.LOG.SHOW, {
    assetId,
    logId
  });
  props.history.push(url);
};

const showAddEditAssetPage = (page, props) => {
  const { match } = props;
  const { params } = match;
  const url = ASSET_UPDATE_PAGES[page];
  props.history.push(reverse(url, { assetId: params.assetId }));
};

const getAssigneeFormat = data => {
  const newFormatData = [];
  data &&
    data.length > 0 &&
    data.map(each => {
      const fullname = `${each.first_name} ${each.last_name}`;
      newFormatData.push({ label: fullname, value: each.id });
      return true;
    });
  return newFormatData;
};

const getDepartmentsForTree = data => {
  let returnData = [];
  if (data && data instanceof Array) {
    returnData = data.map(depart => {
      const department = { ...depart };
      department.open = false;
      return department;
    });
  }
  return returnData;
};

const getAttributesApplicableTo = values => {
  const applicability = values.map(value => APP_RESOURCE_CLASSES[value]);
  return applicability.join(', ');
};

export {
  getUserCard,
  showUserDetails,
  getOrgUnits,
  getOrgUnitsNext,
  redirect,
  getFormatOfdData,
  redirectToDepartments,
  getAssetAttributes,
  getAssetTypes,
  getOrgUnitUrl,
  redirectAssetTypeDetail,
  redirectAssetAttributeDetail,
  getFomatedOrgUnitData,
  onPagination,
  onPageChange,
  getBodyComponenet,
  handleFiltersChange,
  submitFilters,
  showAssetDetails,
  getFormattedAssets,
  getFormattedQrCodes,
  onEnter,
  showTrackingLogDetails,
  showAddEditAssetPage,
  getAssigneeFormat,
  showServiceAccountDetails,
  getServiceAccountCard,
  getFootprintsLogo,
  getDepartmentsForTree,
  getAttributesApplicableTo,
  getNavBarLogo
};
