import axios from 'axios';
import { getSession, logout } from './authService';

class AxiosWrapper {
  constructor(sessionCookie, rememberMeCookie) {
    this.sessionCookie = sessionCookie;
    this.rememberMeCookie = rememberMeCookie;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.interceptors.response.use(response => response, this.handleAPIError);
  }

  getAuthHeader = () => {
    const sessionData = getSession(this.sessionCookie);
    const rememberMeData = getSession(this.rememberMeCookie);
    if (Object.keys(sessionData).length) {
      if (sessionData.token)
        return { Authorization: `Token ${sessionData.token}` };
      if (sessionData.tempSignupToken)
        return { temporaryToken: sessionData.tempSignupToken };
    } else if (Object.keys(rememberMeData).length) {
      if (rememberMeData.token)
        return { Authorization: `Token ${rememberMeData.token}` };
      if (rememberMeData.tempSignupToken)
        return { temporaryToken: rememberMeData.tempSignupToken };
    }
    return {};
  };

  _asyncAjax = ({ method, url, data = {}, headers }) => {
    const requestHeaders = { ...headers, ...this.getAuthHeader() };
    return axios({ method, url, data, headers: requestHeaders });
  };

  _ajax = ({ method, url, data = {}, headers }) => {
    return this._asyncAjax({ method, url, data, headers })
      .then(response => response)
      .catch(error => error.response);
  };

  postData = (url, data, headers) =>
    this._ajax({ method: 'post', url, data, headers });

  getData = (url, data, headers) =>
    this._ajax({ method: 'get', url, data, headers });

  asyncGetData = (url, data, headers) =>
    this._asyncAjax({ method: 'get', url, data, headers });

  asyncPostData = (url, data, headers) =>
    this._asyncAjax({ method: 'post', url, data, headers });

  putData = (url, data) => this._ajax({ method: 'put', url, data });

  deleteData = (url, data) => this._ajax({ method: 'delete', url, data });

  asyncPatchData = (url, data, headers) =>
    this._asyncAjax({ method: 'patch', url, data, headers });

  patchData = (url, data) => this._ajax({ method: 'patch', url, data });

  handleAPIError = error => {
    if (error.response && error.response.status === 401) {
      // logout user in this case
      logout(this.sessionCookie, this.rememberMeCookie);
    } else if (
      error.response &&
      error.response.status === 500 &&
      !(error.response.data instanceof Object)
    ) {
      const err = { ...error };
      err.response.data = { error_code: 1500 };
      return Promise.reject(err);
    } else if (error.message === 'Network Error' && !navigator.onLine) {
      // Handle internet connectivity error
      const err = { ...error };
      err.response.data = { error_code: 1530 };
      return err;
    } else {
      return Promise.reject(error);
    }
    return false;
  };
}

export default AxiosWrapper;
