import React from 'react';
import PropTypes from 'prop-types';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputGroup, FormControl } from 'react-bootstrap';

import DisplayErrors from './DisplayErrors';
import { SUBMIT } from '../utils/constants';
import CustomButton from './CustomButton/CustomButton';

const ChangePasswordForm = props => {
  return (
    <div className="card-text text-left">
      <form onSubmit={props.handleSubmit} autoComplete="off">
        <DisplayErrors errors={props.formErrors} />
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className="pl-0">
              <FontAwesomeIcon icon={faUnlockAlt} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="password"
            placeholder="Old Password"
            id={props.currentPasswordId}
            value={props.currentPasswordValue}
            name={props.currentPasswordId}
            onChange={props.handleChange}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className="pl-0">
              <FontAwesomeIcon icon={faUnlockAlt} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="password"
            placeholder="New Password"
            id={props.newPasswordId}
            value={props.newPasswordValue}
            name={props.newPasswordId}
            onChange={props.handleChange}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className="pl-0">
              <FontAwesomeIcon icon={faUnlockAlt} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="password"
            placeholder="Confirm Password"
            id={props.confirmPasswordId}
            value={props.confirmPasswordValue}
            name={props.confirmPasswordId}
            onChange={props.handleChange}
          />
        </InputGroup>
        <CustomButton
          disabled={props.disabled}
          isLoading={props.inProgress}
          submitFunction={props.handleSubmit}
          id="changePasswordButton"
          className="btn onboarding-btn"
          type="submit"
          text={SUBMIT}
          isBlock
        />
      </form>
    </div>
  );
};

ChangePasswordForm.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  currentPasswordId: PropTypes.string.isRequired,
  currentPasswordValue: PropTypes.string.isRequired,
  newPasswordId: PropTypes.string.isRequired,
  newPasswordValue: PropTypes.string.isRequired,
  confirmPasswordId: PropTypes.string.isRequired,
  confirmPasswordValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired
};

ChangePasswordForm.defaultProps = {
  disabled: true
};

export default ChangePasswordForm;
