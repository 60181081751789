import { call, put } from 'redux-saga/effects';
import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* getAssetLiveLocation(action) {
  let response;
  try {
    const { assetId } = action.data;

    const API_URL = reverse(FOOTPRINTS_API.GET_ASSET_CURRENT_LOCATION, {
      assetId
    });
    response = yield call(window.axios.getData, API_URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.GOOGLE_MAPS.GET_ASSETS_CURRENT_LOCATION_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.GOOGLE_MAPS.GET_ASSETS_CURRENT_LOCATION_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.GOOGLE_MAPS.GET_ASSETS_CURRENT_LOCATION_FAIL,
      payload: e
    });
  }
}

export default getAssetLiveLocation;
