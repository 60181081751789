import { call, put } from 'redux-saga/effects';

import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* getAssetCsvFieldsConfig(action) {
  let response;
  try {
    const API_URL = reverse(FOOTPRINTS_API.GET_ASSET_CSV_FIELDS_CONFIG);
    response = yield call(window.axios.getData, API_URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSET_CSV_FIELDS_CONFIG_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSET_CSV_FIELDS_CONFIG_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSETS.GET_ASSET_CSV_FIELDS_CONFIG_FAIL,
      payload: e
    });
  }
}

function* addAssetThroughExcel(action) {
  let response;
  const { postData } = action.data;
  const assets = {
    assets: postData
  };
  try {
    const API_URL = reverse(FOOTPRINTS_API.ADD_ASSET_THROUGH_EXCEL);
    response = yield call(window.axios.postData, API_URL, assets);
    if (response.status === 204) {
      action.data.history.goBack();
      yield put({
        type: ACTIONS.ASSETS.ADD_ASSET_THROUGH_EXCEL_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.ADD_ASSET_THROUGH_EXCEL_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSETS.ADD_ASSET_THROUGH_EXCEL_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getDepartmentsCsvFieldsConfig(action) {
  let response;
  try {
    const API_URL = reverse(FOOTPRINTS_API.GET_DEPARTMENTS_CSV_FIELDS_CONFIG);
    response = yield call(window.axios.getData, API_URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ORG_UNITS.GET_DEPARTMENTS_CSV_FIELDS_CONFIG_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ORG_UNITS.GET_DEPARTMENTS_CSV_FIELDS_CONFIG_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ORG_UNITS.GET_DEPARTMENTS_CSV_FIELDS_CONFIG_FAIL,
      payload: e
    });
  }
}

function* addDepartmentsThroughExcel(action) {
  let response;
  const { postData } = action.data;
  const departments = {
    departments: postData
  };
  try {
    const API_URL = reverse(FOOTPRINTS_API.ADD_DEPARTMENTS_THROUGH_EXCEL);
    response = yield call(window.axios.postData, API_URL, departments);
    if (response.status === 204) {
      action.data.history.goBack();
      yield put({
        type: ACTIONS.ORG_UNITS.ADD_DEPARTMENTS_THROUGH_EXCEL_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ORG_UNITS.ADD_DEPARTMENTS_THROUGH_EXCEL_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ORG_UNITS.ADD_DEPARTMENTS_THROUGH_EXCEL_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getUsersCsvFieldsConfig(action) {
  let response;
  try {
    const API_URL = reverse(FOOTPRINTS_API.GET_USERS_CSV_FIELDS_CONFIG);
    response = yield call(window.axios.getData, API_URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.USERS.GET_USERS_CSV_FIELDS_CONFIG_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.USERS.GET_USERS_CSV_FIELDS_CONFIG_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.USERS.GET_USERS_CSV_FIELDS_CONFIG_FAIL,
      payload: e
    });
  }
}

function* addUsersThroughExcel(action) {
  let response;
  const { postData } = action.data;
  const users = {
    users: postData
  };
  try {
    const API_URL = reverse(FOOTPRINTS_API.ADD_USERS_THROUGH_EXCEL);
    response = yield call(window.axios.postData, API_URL, users);
    if (response.status === 201 || response.status === 200) {
      action.data.history.goBack();
      yield put({
        type: ACTIONS.USERS.ADD_USERS_THROUGH_EXCEL_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.USERS.ADD_USERS_THROUGH_EXCEL_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.USERS.ADD_USERS_THROUGH_EXCEL_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getAttributesCsvFieldsConfig(action) {
  let response;
  try {
    const API_URL = reverse(FOOTPRINTS_API.GET_ATTRIBUTES_CSV_FIELDS_CONFIG);
    response = yield call(window.axios.getData, API_URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.GET_ATTRIBUTES_CSV_FIELDS_CONFIG_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.GET_ATTRIBUTES_CSV_FIELDS_CONFIG_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_ATTRIBUTES.GET_ATTRIBUTES_CSV_FIELDS_CONFIG_FAIL,
      payload: e
    });
  }
}

function* addAttributesThroughExcel(action) {
  let response;
  const { postData } = action.data;
  const attributes = {
    attributes: postData
  };
  try {
    const API_URL = reverse(FOOTPRINTS_API.ADD_ASSET_ATTRIBUTES);
    response = yield call(window.axios.postData, API_URL, attributes);
    if (response.status === 201) {
      action.data.history.goBack();
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.ADD_ATTRIBUTES_THROUGH_EXCEL_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.ADD_ATTRIBUTES_THROUGH_EXCEL_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_ATTRIBUTES.ADD_ATTRIBUTES_THROUGH_EXCEL_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getAssetTypesCsvFieldsConfig(action) {
  let response;
  try {
    const API_URL = reverse(FOOTPRINTS_API.GET_ASSET_TYPES_CSV_FIELDS_CONFIG);
    response = yield call(window.axios.getData, API_URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_CSV_FIELDS_CONFIG_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_CSV_FIELDS_CONFIG_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_CSV_FIELDS_CONFIG_FAIL,
      payload: e
    });
  }
}

function* addAssetTypesThroughExcel(action) {
  let response;
  const { postData } = action.data;
  const assetTypes = {
    asset_types: postData
  };
  try {
    const API_URL = reverse(FOOTPRINTS_API.ADD_ASSET_TYPES_THROUGH_EXCEL);
    response = yield call(window.axios.postData, API_URL, assetTypes);
    if (response.status === 204) {
      action.data.history.goBack();
      yield put({
        type: ACTIONS.ASSET_TYPES.ADD_ASSET_TYPES_THROUGH_EXCEL_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_TYPES.ADD_ASSET_TYPES_THROUGH_EXCEL_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_TYPES.ADD_ASSET_TYPES_THROUGH_EXCEL_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getServAccountsCsvFieldsConfig(action) {
  let response;
  try {
    const API_URL = reverse(
      FOOTPRINTS_API.GET_SERVICE_ACCOUNT_CSV_FIELDS_CONFIG
    );
    response = yield call(window.axios.getData, API_URL);
    if (response.status === 200) {
      yield put({
        type:
          ACTIONS.SERVICE_ACCOUNTS
            .GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type:
          ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type:
        ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG_FAIL,
      payload: e
    });
  }
}

function* addServAccountsThroughExcel(action) {
  let response;
  const { postData } = action.data;
  const serviceUsers = {
    service_users: postData
  };
  try {
    const API_URL = reverse(FOOTPRINTS_API.ADD_SERV_USERS_THROUGH_EXCEL);
    response = yield call(window.axios.postData, API_URL, serviceUsers);
    if (response.status >= 200 && response.status < 300) {
      action.data.history.goBack();
      yield put({
        type:
          ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

export {
  getAssetCsvFieldsConfig,
  addAssetThroughExcel,
  getDepartmentsCsvFieldsConfig,
  addDepartmentsThroughExcel,
  getUsersCsvFieldsConfig,
  addUsersThroughExcel,
  getAttributesCsvFieldsConfig,
  addAttributesThroughExcel,
  getAssetTypesCsvFieldsConfig,
  addAssetTypesThroughExcel,
  getServAccountsCsvFieldsConfig,
  addServAccountsThroughExcel
};
