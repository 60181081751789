import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reverse } from 'named-urls';

import {
  FOOTPRINTS_ROUTES,
  userSessionCookie,
  rememberUserCookie
} from '../../config';
import { logout } from '../../utils/authService';
import { fetchUserProfile } from '../../utils/common';
import Navbar from '../../components/Navbar/Navbar';
import ACTIONS from '../../apiConfig/actions.constants';
import ChangePassword from '../ChangePassword/ChangePassword';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';
import NotificationIcon from '../../images/bell.svg';
import NotificationActiveIcon from '../../images/bell-active.svg';

const NavBar = props => {
  const [notifications, setNotificatons] = useState([]);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [newNotification, setNewNotification] = useState(false);

  const ref = useRef();

  // Get hold of the history
  const history = useHistory();

  // to fetch all the notifications of the user
  const fetchNotification = () => {
    window.axios
      .asyncGetData(FOOTPRINTS_API.GET_NOTIFICATION)
      .then(response => {
        setNotificatons(response.data.results);
        setNewNotification(response.data.new_notification);
      });
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setNotificationOpen(false);
    }
  };

  useEffect(() => {
    fetchNotification();
    fetchUserProfile(false);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [props]);

  const changePassword = useDispatch();

  const toChangePassword = () => {
    changePassword({ type: ACTIONS.PROFILE.SHOW_CHANGE_PASSWORD });
  };

  const toViewProfile = () => {
    history.push(FOOTPRINTS_ROUTES.USER_PROFILE);
  };

  const onNotificationClick = () => {
    setNotificationOpen(!notificationOpen);
  };

  const markAsRead = () => {
    const data = notifications.map(notification => {
      const curNotification = notification;
      curNotification.seen = true;
      return notification.id;
    });
    setNotificatons(notifications);
    setNewNotification(false);
    window.axios
      .asyncPostData(FOOTPRINTS_API.UPDATE_ALL_NOTIFICATION, data)
      .then(response => {});
  };

  const viewNotification = (assetId, id) => {
    setNotificationOpen(false);
    window.axios
      .asyncPatchData(reverse(FOOTPRINTS_API.UPDATE_NOTIFICATION, { id }))
      .then(response => {});
    history.push(reverse(FOOTPRINTS_ROUTES.ASSET.DETAIL.SHOW, { assetId }));
  };

  const signOut = () => {
    logout(userSessionCookie, rememberUserCookie);
  };

  return (
    <div className="navbar-wrapper fixed-top">
      <Navbar
        userName={props.userName}
        logo={props.logo}
        class={props.class}
        logoLink={props.logoLink}
        onSignout={signOut}
        toChangePassword={toChangePassword}
        onNotificationClick={onNotificationClick}
        notificationOpen={notificationOpen}
        notificationIcon={
          newNotification ? NotificationActiveIcon : NotificationIcon
        }
        notifications={notifications}
        markAsRead={markAsRead}
        viewNotification={viewNotification}
        parentRef={ref}
        newNotification={newNotification}
        toViewProfile={toViewProfile}
      />
      <ChangePassword />
    </div>
  );
};

NavBar.propTypes = {
  class: PropTypes.string,
  userName: PropTypes.string,
  logoLink: PropTypes.string,
  logo: PropTypes.any
};

NavBar.defaultProps = {
  class: 'nav-1',
  logo: null,
  userName: 'Footprints User',
  logoLink: FOOTPRINTS_ROUTES.ANALYTICS
};

export default NavBar;
