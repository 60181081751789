import { call, put } from 'redux-saga/effects';
import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';
import { FOOTPRINTS_ROUTES } from '../../config';

function* addOrgUnit(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.ORG_UNITS,
      action.data.data
    );

    if (response.status === 201 || response.status === 200) {
      action.data.props.history.push(reverse(FOOTPRINTS_ROUTES.ORG_UNITS.LIST));
      yield put({
        type: ACTIONS.ORG_UNITS.ADD_ORG_UNIT_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ORG_UNITS.ADD_ORG_UNIT_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ORG_UNITS.ADD_ORG_UNIT_FAIL, payload: e });
  }
}

function* updateOrgUnit(action) {
  let response;
  try {
    const url = reverse(FOOTPRINTS_API.UPDATE_ORG_UNIT, {
      ...action.data.props.match.params
    });
    response = yield call(window.axios.patchData, url, action.data.data);
    if (response.status === 201 || response.status === 200) {
      action.data.props.history.goBack();
      yield put({
        type: ACTIONS.ORG_UNITS.UPDATE_ORG_UNIT_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ORG_UNITS.UPDATE_ORG_UNIT_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ORG_UNITS.ADD_ORG_UNIT_FAIL, payload: e });
  }
}

export { addOrgUnit, updateOrgUnit };
