import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ACTIONS from '../../apiConfig/actions.constants';
import { PASSWORDS_NO_MATCH } from '../../utils/errorMessages';
import {
  checkPasswordStrength,
  getErrorResMsg
} from '../../utils/formValidations';
import {
  CHANGE_PASSWORD_HEADER,
  PAGE,
  CALL_STATUS
} from '../../utils/constants';
import ChangePasswordForm from '../../components/ChangePasswordForm';
import ModalWrapper from '../../components/ModalWrapper/ModalWrapper';
import { logout } from '../../utils/authService';
import { userSessionCookie, rememberUserCookie } from '../../config';

class ChangePassword extends React.Component {
  NEW_PASSWORD_FIELD_ID = 'new-password';

  CURRENT_PASSWORD_FIELD_ID = 'current-password';

  CONFIRM_NEW_PASSWORD_FIELD_ID = 'confirm-new-password';

  constructor(props) {
    super(props);
    this.state = {
      submitDisabled: true,
      [this.NEW_PASSWORD_FIELD_ID]: '',
      [this.CURRENT_PASSWORD_FIELD_ID]: '',
      [this.CONFIRM_NEW_PASSWORD_FIELD_ID]: '',
      formErrors: {},
      errorCode: ''
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { errorCode } = nextProps.changePassword;
    if (errorCode && errorCode !== state.errorCode) {
      const errMsg = getErrorResMsg(errorCode, PAGE.CHANGE_PASSWORD);

      return { formErrors: errMsg, errorCode };
    }
    return {};
  }

  enableSubmit = () => {
    const currentPassword = this.state[this.CURRENT_PASSWORD_FIELD_ID];
    const newPassword = this.state[this.NEW_PASSWORD_FIELD_ID];
    const conformNewPassword = this.state[this.CONFIRM_NEW_PASSWORD_FIELD_ID];
    const disabled = !(
      currentPassword.length > 0 &&
      newPassword.length > 0 &&
      conformNewPassword.length > 0
    );
    this.setState({ submitDisabled: disabled });
  };

  handleChange = event => {
    this.setState(
      { [event.target.id]: event.target.value, formErrors: {} },
      () => this.enableSubmit()
    );
  };

  validateForm = () => {
    const [newPasswordValidity, newPasswordErr] = checkPasswordStrength(
      this.state[this.NEW_PASSWORD_FIELD_ID],
      'Password'
    );

    const formErrors = {};
    const isEqual =
      this.state[this.NEW_PASSWORD_FIELD_ID] ===
      this.state[this.CONFIRM_NEW_PASSWORD_FIELD_ID];
    const formValidity = newPasswordValidity && isEqual;

    if (!isEqual) {
      formErrors[this.CONFIRM_NEW_PASSWORD_FIELD_ID] = [PASSWORDS_NO_MATCH];
    }

    this.setState({ formErrors: { ...newPasswordErr, ...formErrors } });

    return formValidity;
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.dispatch({ type: ACTIONS.PROFILE.RESET_STATE });
    if (this.validateForm()) {
      const data = {
        old_password: this.state[this.CURRENT_PASSWORD_FIELD_ID],
        new_password: this.state[this.NEW_PASSWORD_FIELD_ID],
        confirm_password: this.state[this.CONFIRM_NEW_PASSWORD_FIELD_ID]
      };
      this.submit(data);
    }
  };

  submit = data => {
    this.setState({ errorCode: '' });
    this.props.dispatch({
      type: ACTIONS.PROFILE.CHANGE_PASSWORD,
      data
    });
  };

  closeModal = () => {
    this.props.dispatch({ type: ACTIONS.PROFILE.HIDE_CHANGE_PASSWORD });
    this.setState({
      submitDisabled: true,
      [this.NEW_PASSWORD_FIELD_ID]: '',
      [this.CURRENT_PASSWORD_FIELD_ID]: '',
      [this.CONFIRM_NEW_PASSWORD_FIELD_ID]: '',
      formErrors: {}
    });
  };

  render() {
    const { changePassword } = this.props;
    const { show, inProgress } = changePassword;
    let common = (
      <ChangePasswordForm
        disabled={this.state.submitDisabled}
        handleSubmit={this.handleSubmit}
        formErrors={this.state.formErrors}
        currentPasswordId={this.CURRENT_PASSWORD_FIELD_ID}
        currentPasswordValue={this.state[this.CURRENT_PASSWORD_FIELD_ID]}
        newPasswordId={this.NEW_PASSWORD_FIELD_ID}
        newPasswordValue={this.state[this.NEW_PASSWORD_FIELD_ID]}
        confirmPasswordId={this.CONFIRM_NEW_PASSWORD_FIELD_ID}
        confirmPasswordValue={this.state[this.CONFIRM_NEW_PASSWORD_FIELD_ID]}
        handleChange={this.handleChange}
        inProgress={inProgress}
      />
    );
    const changePasswordSucc = (
      <div className="card-text text-left" id="changePasswordSuccess">
        Password has been set, redirecting to login ...
      </div>
    );
    if (this.props.changePassword.callStatus === CALL_STATUS.RESPONDED) {
      common = changePasswordSucc;
      setTimeout(() => {
        logout(userSessionCookie, rememberUserCookie);
      }, 5000);
    }
    const wrapper = (
      <ModalWrapper
        title={CHANGE_PASSWORD_HEADER}
        onClose={this.closeModal}
        show={show}
      >
        {common}
      </ModalWrapper>
    );

    return <div className="change-password-container">{wrapper}</div>;
  }
}

ChangePassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
  changePassword: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  changePassword: state.changePasswordStore
});

export default withRouter(connect(mapStateToProps)(ChangePassword));
