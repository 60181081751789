import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  errorCode: '',
  inProgress: false,
  csvFieldsConfig: {}
};

const importExcelReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ASSETS.GET_ASSET_CSV_FIELDS_CONFIG:
      return { ...state, csvFieldsConfig: payload, inProgress: true };
    case ACTIONS.ASSETS.GET_ASSET_CSV_FIELDS_CONFIG_SUCCESS:
      return { ...state, csvFieldsConfig: payload, inProgress: false };
    case ACTIONS.ASSETS.GET_ASSET_CSV_FIELDS_CONFIG_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSETS.ADD_ASSET_THROUGH_EXCEL:
      return { ...state, inProgress: true };
    case ACTIONS.ASSETS.ADD_ASSET_THROUGH_EXCEL_SUCCESS:
      return { ...state, inProgress: false };
    case ACTIONS.ASSETS.ADD_ASSET_THROUGH_EXCEL_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ORG_UNITS.GET_DEPARTMENTS_CSV_FIELDS_CONFIG:
      return { ...state, csvFieldsConfig: payload, inProgress: true };
    case ACTIONS.ORG_UNITS.GET_DEPARTMENTS_CSV_FIELDS_CONFIG_SUCCESS:
      return { ...state, csvFieldsConfig: payload, inProgress: false };
    case ACTIONS.ORG_UNITS.GET_DEPARTMENTS_CSV_FIELDS_CONFIG_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ORG_UNITS.ADD_DEPARTMENTS_THROUGH_EXCEL:
      return { ...state, inProgress: true };
    case ACTIONS.ORG_UNITS.ADD_DEPARTMENTS_THROUGH_EXCEL_SUCCESS:
      return { ...state, inProgress: false };
    case ACTIONS.ORG_UNITS.ADD_DEPARTMENTS_THROUGH_EXCEL_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.USERS.GET_USERS_CSV_FIELDS_CONFIG:
      return { ...state, csvFieldsConfig: payload, inProgress: true };
    case ACTIONS.USERS.GET_USERS_CSV_FIELDS_CONFIG_SUCCESS:
      return { ...state, csvFieldsConfig: payload, inProgress: false };
    case ACTIONS.USERS.GET_USERS_CSV_FIELDS_CONFIG_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.USERS.ADD_USERS_THROUGH_EXCEL:
      return { ...state, inProgress: true };
    case ACTIONS.USERS.ADD_USERS_THROUGH_EXCEL_SUCCESS:
      return { ...state, inProgress: false };
    case ACTIONS.USERS.ADD_USERS_THROUGH_EXCEL_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSET_ATTRIBUTES.GET_ATTRIBUTES_CSV_FIELDS_CONFIG:
      return { ...state, csvFieldsConfig: payload, inProgress: true };
    case ACTIONS.ASSET_ATTRIBUTES.GET_ATTRIBUTES_CSV_FIELDS_CONFIG_SUCCESS:
      return { ...state, csvFieldsConfig: payload, inProgress: false };
    case ACTIONS.ASSET_ATTRIBUTES.GET_ATTRIBUTES_CSV_FIELDS_CONFIG_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSET_ATTRIBUTES.ADD_ATTRIBUTES_THROUGH_EXCEL:
      return { ...state, inProgress: true };
    case ACTIONS.ASSET_ATTRIBUTES.ADD_ATTRIBUTES_THROUGH_EXCEL_SUCCESS:
      return { ...state, inProgress: false };
    case ACTIONS.ASSET_ATTRIBUTES.ADD_ATTRIBUTES_THROUGH_EXCEL_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_CSV_FIELDS_CONFIG:
      return { ...state, csvFieldsConfig: payload, inProgress: true };
    case ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_CSV_FIELDS_CONFIG_SUCCESS:
      return { ...state, csvFieldsConfig: payload, inProgress: false };
    case ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_CSV_FIELDS_CONFIG_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSET_TYPES.ADD_ASSET_TYPES_THROUGH_EXCEL:
      return { ...state, inProgress: true };
    case ACTIONS.ASSET_TYPES.ADD_ASSET_TYPES_THROUGH_EXCEL_SUCCESS:
      return { ...state, inProgress: false };
    case ACTIONS.ASSET_TYPES.ADD_ASSET_TYPES_THROUGH_EXCEL_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG:
      return { ...state, csvFieldsConfig: payload, inProgress: true };
    case ACTIONS.SERVICE_ACCOUNTS
      .GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG_SUCCESS:
      return { ...state, csvFieldsConfig: payload, inProgress: false };
    case ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL:
      return { ...state, inProgress: true };
    case ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL_SUCCESS:
      return { ...state, inProgress: false };
    case ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSETS.RESET_STATE:
      return { ...initialState };

    default:
      return state;
  }
};

const dummy = () => {};

export { importExcelReducerStore, dummy };
