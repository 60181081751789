import { include } from 'named-urls';
import { PAGE, USER_PERMISSIONS } from './utils/constants';
import sidenavIcons from './images/sidenav-icons';

const APP_VERSION = 'Version 1.0.0';

const userSessionCookie = 'upayogakarta';
const rememberUserCookie = 'yaadrakhna';

const FOOTPRINTS_ROUTES = {
  LOGIN: '/login/',
  ANALYTICS: '/analytics/',
  DASHBOARD: '/dashboard/',
  USER_PROFILE: '/profile/',

  BUSINESS_RULES: include('/business-rules/', {
    LIST: '',
    ADD: 'add/',
    DETAIL: include(':businessRuleId/', {
      SHOW: '',
      MODIFY: 'modify/'
    })
  }),

  ORG_UNITS: include('/org-units/', {
    LIST: '',
    ADD: 'add/',
    DETAIL: include(':orgUnitId/', {
      SHOW: '',
      MODIFY: 'modify/'
    }),
    IMPORT_CSV: 'import-excel'
  }),

  USERS: include('/users/', {
    LIST: '',
    ADD: 'add/',
    DETAIL: include(':userId/', {
      SHOW: '',
      MODIFY: 'modify/'
    }),
    IMPORT_CSV: 'import-excel'
  }),

  SERVICE_ACCOUNTS: include('/service-accounts/', {
    LIST: '',
    ADD: 'add/',
    DETAIL: include(':userId/', {
      SHOW: '',
      MODIFY: 'modify/'
    }),
    IMPORT_CSV: 'import-excel'
  }),

  ASSET_TYPES: include('/asset-types/', {
    LIST: '',
    ADD: 'add/',
    DETAIL: include(':assetTypeId/', {
      SHOW: '',
      MODIFY: 'modify/'
    }),
    IMPORT_CSV: 'import-excel'
  }),

  ATTRIBUTES: include('/attributes/', {
    LIST: '',
    ADD: 'add/',
    DETAIL: include(':assetAttrId/', {
      SHOW: '',
      MODIFY: 'modify/'
    }),
    IMPORT_CSV: 'import-excel'
  }),

  ASSET: include('/assets/', {
    LIST: '',
    ADD: 'add/',
    DETAIL: include(':assetId/', {
      SHOW: '',
      MODIFY: 'modify/',
      LOG: include('tracking-log/:logId/', {
        SHOW: ''
      }),
      MODIFY_INFO: 'info',
      MODIFY_ATTRIBUTE: 'attributes',
      MODIFY_ASSIGNEE: 'assignee',
      LOCATION_HISTORY: 'location',
      LIVE_LOCATION: 'live-tracking',
      ADD_ATTACHMENTS: 'add-attachments'
    }),
    IMPORT_CSV: 'import-excel'
  }),

  QR_CODES: include('/qr-codes/', {
    LIST: '',
    ADD: 'add/'
  }),

  ROLES: include('/roles/', {
    LIST: '',
    ADD: 'add/',
    DETAIL: include(':roleId/', {
      SHOW: '',
      MODIFY: 'modify/'
    })
  }),

  FORGOT_PASSWORD: '/forgot-password',
  PASSWORD_RESET: '/password-reset/:token',
  CUSTOMISE: '/customise/',
  ORGANISATION: '/organisation/',
  LICENCE: '/licence/',
  ABOUT_US: '/about-us/'
};

const ALL_ALLOWED = '__all__';
const SIDENAV_ITEMS = [
  {
    position: 1,
    displayName: 'Dashboard',
    route: FOOTPRINTS_ROUTES.ANALYTICS,
    icon: sidenavIcons.Analytics,
    activeIcon: sidenavIcons.AnalyticsWhite,
    id: 'analytics',
    page: PAGE.ANALYTICS,
    permission: ALL_ALLOWED
  },
  {
    position: 2,
    displayName: 'Departments',
    route: FOOTPRINTS_ROUTES.ORG_UNITS.LIST,
    icon: sidenavIcons.Departments,
    activeIcon: sidenavIcons.DepartmentsWhite,
    id: 'org-untis',
    page: PAGE.ORG_UNITS,
    permission: USER_PERMISSIONS.DEPT_VIEW
  },
  {
    position: 3,
    displayName: 'Users',
    route: FOOTPRINTS_ROUTES.USERS.LIST,
    icon: sidenavIcons.Users,
    activeIcon: sidenavIcons.UsersWhite,
    id: 'users',
    page: PAGE.USERS,
    permission: USER_PERMISSIONS.USER_VIEW
  },
  {
    position: 4,
    displayName: 'Assets',
    route: FOOTPRINTS_ROUTES.ASSET.LIST,
    icon: sidenavIcons.Assets,
    activeIcon: sidenavIcons.AssetsWhite,
    id: 'assets',
    page: PAGE.ASSET,
    permission: USER_PERMISSIONS.ASSET_VIEW
  },
  {
    position: 5,
    displayName: 'ID Readers',
    route: FOOTPRINTS_ROUTES.SERVICE_ACCOUNTS.LIST,
    icon: sidenavIcons.IdReaders,
    activeIcon: sidenavIcons.IdReadersWhite,
    id: 'id-readers',
    page: PAGE.SERVICE_ACCOUNTS,
    permission: USER_PERMISSIONS.USER_VIEW
  },
  {
    position: 6,
    displayName: 'Attributes',
    route: FOOTPRINTS_ROUTES.ATTRIBUTES.LIST,
    icon: sidenavIcons.Attributes,
    activeIcon: sidenavIcons.AttributesWhite,
    id: 'asset-attributes',
    page: PAGE.ASSET_ATTRIBUTES,
    permission: USER_PERMISSIONS.ATTR_VIEW
  },
  {
    position: 7,
    displayName: 'Asset Types',
    route: FOOTPRINTS_ROUTES.ASSET_TYPES.LIST,
    icon: sidenavIcons.AssetTypes,
    activeIcon: sidenavIcons.AssetTypesWhite,
    id: 'asset-types',
    page: PAGE.ASSET_TYPES,
    permission: USER_PERMISSIONS.ASSET_TYPE_VIEW
  },
  {
    position: 8,
    displayName: 'User Roles',
    route: FOOTPRINTS_ROUTES.ROLES.LIST,
    icon: sidenavIcons.UserRoles,
    activeIcon: sidenavIcons.UserRolesWhite,
    id: 'userRoles',
    page: PAGE.ROLE.LIST,
    permission: USER_PERMISSIONS.ROLE_VIEW
  }
  // {
  //   position: 9,
  //   displayName: 'Business Rules',
  //   route: FOOTPRINTS_ROUTES.BUSINESS_RULES.LIST,
  //   icon: sidenavIcons.BusinessRules,
  //   activeIcon: sidenavIcons.BusinessRulesWhite,
  //   id: 'businessRules',
  //   page: PAGE.BUSINESS_RULES.LIST,
  //   permission: USER_PERMISSIONS.RULE_VIEW
  // }
];

const SIDENAV_MIDDLE_ITEMS = [
  {
    position: 1,
    displayName: 'Organisation',
    route: FOOTPRINTS_ROUTES.ORGANISATION,
    icon: sidenavIcons.Organisation,
    activeIcon: sidenavIcons.OrganisationWhite,
    id: 'organisation',
    page: PAGE.ORGANISATION,
    permission: ALL_ALLOWED
  },
  {
    position: 2,
    displayName: 'Licence',
    route: FOOTPRINTS_ROUTES.LICENCE,
    icon: sidenavIcons.BusinessRules,
    activeIcon: sidenavIcons.BusinessRulesWhite,
    id: 'licence',
    page: PAGE.LICENCE,
    permission: ALL_ALLOWED
  }
];

const SIDENAV_BOTTOM_ITEMS = [
  {
    position: 1,
    displayName: 'Customise Theme',
    route: FOOTPRINTS_ROUTES.CUSTOMISE,
    icon: sidenavIcons.Customise,
    activeIcon: sidenavIcons.Customise,
    id: 'customise',
    page: PAGE.CUSTOMISE,
    permission: USER_PERMISSIONS.ORG_THEME_CHANGE
  },
  {
    position: 2,
    displayName: 'About Us',
    route: FOOTPRINTS_ROUTES.ABOUT_US,
    icon: sidenavIcons.UserRoles,
    activeIcon: sidenavIcons.UserRolesWhite,
    id: 'about-us',
    page: PAGE.ABOUT_US,
    permission: ALL_ALLOWED
  }
];

const ASSET_UPDATE_PAGES = {
  [PAGE.EDIT_ASSET_ATTRIBUTES]: FOOTPRINTS_ROUTES.ASSET.DETAIL.MODIFY_ATTRIBUTE,
  [PAGE.EDIT_ASSET_INFO]: FOOTPRINTS_ROUTES.ASSET.DETAIL.MODIFY_INFO,
  [PAGE.ADD_ASSET_ASSIGNEE]: FOOTPRINTS_ROUTES.ASSET.DETAIL.MODIFY_ASSIGNEE,
  [PAGE.ADD_ATTACHMENTS]: FOOTPRINTS_ROUTES.ASSET.DETAIL.ADD_ATTACHMENTS
};

export {
  userSessionCookie,
  rememberUserCookie,
  FOOTPRINTS_ROUTES,
  SIDENAV_ITEMS,
  ASSET_UPDATE_PAGES,
  SIDENAV_BOTTOM_ITEMS,
  APP_VERSION,
  SIDENAV_MIDDLE_ITEMS,
  ALL_ALLOWED
};
