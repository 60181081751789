import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';

import './Layout.scss';
import {
  SIDENAV_ITEMS,
  SIDENAV_BOTTOM_ITEMS,
  APP_VERSION,
  SIDENAV_MIDDLE_ITEMS,
  ALL_ALLOWED
} from '../../config';
import AlertMessage from '../AlertMessage/AlertMessage';
import { UserPermissionsContext } from '../../contexts/UserPermissionsContext';

const Layout = props => {
  const permissions = useContext(UserPermissionsContext);

  const { children, organisationAdmin } = props;
  const sidebarId = 'sidebar';
  const contentId = 'content';
  const expandClassName = 'expanded';

  const toggleSidebar = event => {
    event.preventDefault();
    const sidebar = document.getElementById(sidebarId);
    sidebar.classList.toggle(expandClassName);
  };

  const hideSidebar = event => {
    event.preventDefault();
    const sidebar = document.getElementById(sidebarId);
    sidebar.classList.toggle(expandClassName);
  };

  const isUserPermitted = (perm, perms) =>
    perm === ALL_ALLOWED || perms.includes(perm);

  const sidenavItems = SIDENAV_ITEMS.map((item, i) => {
    const NavTo = item.route;
    const userAllowed =
      organisationAdmin || isUserPermitted(item.permission, permissions);

    return userAllowed ? (
      <li className="sidenav-list-item" key={`topsidenav-${item.position}`}>
        <NavLink
          strict
          to={NavTo}
          key={item.position}
          className="nav-link"
          activeClassName="selected"
          id={item.id}
        >
          <img className="inactive" src={item.icon} alt="" />
          <img className="active" src={item.activeIcon} alt="" />
          <span className="sidenav-item-text">{item.displayName}</span>
        </NavLink>
      </li>
    ) : null;
  });

  const sidenavMiddleItems = SIDENAV_MIDDLE_ITEMS.map((item, i) => {
    const NavTo = item.route;
    const userAllowed =
      organisationAdmin || isUserPermitted(item.permission, permissions);
    return userAllowed ? (
      <li className="sidenav-list-item" key={`middlesidenav-${item.position}`}>
        <NavLink
          strict
          to={NavTo}
          key={item.position}
          className="nav-link"
          activeClassName="selected"
          id={item.id}
        >
          <img className="inactive" src={item.icon} alt="" />
          <img className="active" src={item.activeIcon} alt="" />
          <span className="sidenav-item-text">{item.displayName}</span>
        </NavLink>
      </li>
    ) : null;
  });

  const bottomSideNavItems = SIDENAV_BOTTOM_ITEMS.map((item, i) => {
    const NavTo = item.route;
    const userAllowed =
      organisationAdmin || isUserPermitted(item.permission, permissions);
    return userAllowed ? (
      <li className="sidenav-list-item" key={`bottomsidenav-${item.position}`}>
        <NavLink
          strict
          to={NavTo}
          key={item.position}
          className="nav-link"
          activeClassName="selected"
          id={item.id}
        >
          <img src={item.icon} alt="" />
          <span className="sidenav-bottom-item-text">{item.displayName}</span>
        </NavLink>
      </li>
    ) : null;
  });

  const appVer = `${APP_VERSION}`;

  bottomSideNavItems.push(
    <li className="sidenav-list-item" key="versionText">
      <div className="nav-link version text-left">{appVer}</div>
    </li>
  );

  const sidenavBar = (
    <nav
      className="sidebar d-flex align-items-start flex-column"
      id={sidebarId}
    >
      <div className="top-items">
        <ul className="list-unstyled components">
          <li
            className="sidenav-list-item"
            id="dismissSidebar"
            key="dismissSidebar"
          >
            <div
              className="sidebar-dismiss"
              role="button"
              onClick={hideSidebar}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </div>
          </li>
          <li className="sidenav-list-item" id="openSidebar" key="openSidebar">
            <div
              className="header-small-width"
              onClick={toggleSidebar}
              role="button"
            >
              <FontAwesomeIcon
                id="arrowExpand"
                icon={faAngleDoubleRight}
                size="lg"
                className="open-sidenav"
              />
            </div>
          </li>
          {sidenavItems}
        </ul>
      </div>
      <div className="middle-items mb-auto">
        <ul className="list-unstyled components">{sidenavMiddleItems}</ul>
      </div>
      <div className="bottom-items">
        <ul className="list-unstyled components bottom-list">
          {bottomSideNavItems}
        </ul>
      </div>
    </nav>
  );

  const contentComponent = (
    <div className="content" id={contentId}>
      <nav className="navbar navbar-expand-lg breadcrumbs-nav">
        <button
          type="button"
          id="sidebarCollapse"
          onClick={toggleSidebar}
          className="icon-btn sidebar-collapse-btn"
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
      </nav>
      {children}
    </div>
  );

  return (
    <div className="layout">
      {sidenavBar}
      <AlertMessage />
      {contentComponent}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  organisationAdmin: PropTypes.bool.isRequired
};

export default Layout;
