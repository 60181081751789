import moment from 'moment';
import Swal from 'sweetalert2';
import { updateSession } from './authService';
import { FOOTPRINTS_API } from '../apiConfig/api.config';

const getFullName = (firstName = '-', lastName = '-') =>
  `${firstName} ${lastName}`;

const isLoadMore = (pageNum, totalPages) =>
  pageNum <= totalPages || pageNum === 1;

const isDataExists = data => data && data.length > 0;

const secToHHMM = (sec, format) => moment.utc(sec * 1000).format(format);

const getMaxPages = (totalCount, limit) => Math.ceil(totalCount / limit);

const isFetchData = (totalCount, limit, pageNum) => {
  const totalPages = getMaxPages(totalCount, limit);
  isLoadMore(pageNum, totalPages);
};

const isNumber = val => /^[0-9]+$/.test(val);

const isAlphaNumeric = val => /^[0-9a-zA-Z]*$/.test(val);

const checkFetchStatus = isFetching => {
  let showLoader = false;
  Object.keys(isFetching).forEach(key => {
    if (isFetching[key]) {
      showLoader = true;
    }
  });
  return showLoader;
};

const getTokenFromUrl = urlParams => urlParams && urlParams.token;

const setLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getLocalStorage = key => JSON.parse(localStorage.getItem(key));

const removeLocalStorage = key => localStorage.removeItem(key);

const getUniqueId = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

const secondsToHrmins = time => {
  const t = Number(time);
  if (t < 60) return `${t} secs`;
  const h = Math.floor(t / 3600);
  let m = Math.floor((t % 3600) / 60);
  const s = Math.floor((t % 3600) % 60);
  if (s > 30) m += 1;
  const hourString = h + (h === 1 ? ' hr' : ' hrs');
  const minString = m + (m === 1 ? ' min' : ' mins');
  const hrs = h > 0 ? hourString : '';
  const mins = m > 0 ? minString : '';
  return h > 0 && m > 0 ? `${hrs} and ${mins}` : `${hrs}${mins}`;
};

const toSentenceCase = word => word.charAt(0).toUpperCase() + word.slice(1);

const getStrippedNum = number => number.replace(/-/g, '');

const loadJS = (url, callback) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.defer = true;
  script.async = true;
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
      }
    };
  }
  script.onload = () => {
    if (callback) callback();
  };
  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const humanlyDateFormat = 'MMM D, YYYY';

const systemDateFormat = 'DD/MM/YYYY';

const getUnixTime = time => moment(time).unix();

const getDateFromUnixTime = (unixTime, dateFormat) =>
  moment.unix(unixTime).format(dateFormat);

const getTimeFromUnixTime = unixTime => moment.unix(unixTime).format('hh:mm A');

const sortByTimestamp = messages => {
  messages.sort(
    (a, b) => new Date(a.unixTime * 1000) - new Date(b.unixTime * 1000)
  );
  return messages;
};

const getUnixFromDateTime = (date, time) => {
  const d = moment(date).format('YYYY-MM-DD');
  const t = moment(time).format('HH:mm');
  const dateString = `${d} ${t}`;
  return moment(dateString, 'YYYY-MM-DD HH:mm').valueOf() / 1000;
};

const getDayOfMonthFromUnix = unixTime => moment.unix(unixTime).format('MMM D');

const getTimeFromUnix = unixTime => moment.unix(unixTime).format('hh:mm a');

const isLessThanTime = (timestamp, noOfMins) => {
  const now = moment().toDate();
  const then = moment(new Date(timestamp * 1000));
  const minsDiff = then.diff(now, 'minutes');
  if (minsDiff > -noOfMins && minsDiff <= 0) {
    return true;
  }
  return false;
};

const blockBackButton = () => {
  window.history.pushState(null, document.title, window.location.href);
  window.addEventListener('popstate', event => {
    window.history.pushState(null, document.title, window.location.href);
  });
};

const roundToDecimalPlace = (value, noOfDecimalPlaces = 1) =>
  value ? parseFloat(value).toFixed(noOfDecimalPlaces) : 0.0;

const getActionsFormat = (action, data) => {
  const format = data ? { type: action, data } : { type: action };
  return format;
};

function getOrgUnitIds(data) {
  const Ids = data
    ? data.map(oData => {
        return oData.orgUnitId;
      })
    : [];
  return Ids;
}

function getNormalId(data) {
  const Ids = data
    ? data.map(tData => {
        return tData.id;
      })
    : [];
  return Ids;
}

function getFormattedDate(date) {
  const formattedDate = moment(date).format('DD-MM-YYYY');
  const time = moment(date).format('hh:mm A');
  return { formattedDate, time };
}

function isValidData(data) {
  if (data && Object.keys(data).length > 0) {
    return true;
  }
  return false;
}

function getAnalyFormOfAsset(data) {
  let isAllEmpty = true;
  const analyticsData =
    data &&
    data.length > 0 &&
    data.map(each => {
      if (isValidData(each)) {
        const { asset_type: AssetType, count } = each;
        if (count > 0) {
          isAllEmpty = false;
        }
        return [AssetType.name, count];
      }
      return [];
    });
  const formattedData = {
    columns: analyticsData || [],
    type: 'donut'
  };

  const legend = {
    show: true
  };
  const options = {
    responsive: true,
    maintainAspectRatio: true
  };

  return { formattedData, isAllEmpty, options, legend };
}

function IsOldNewAttrValueEquals(attribute, details) {
  for (const eachValue of details.values) {
    if (eachValue.id === attribute.id && eachValue.choice !== attribute.name) {
      return false;
    }
  }
  return true;
}

const getExistedAttributes = (attributes, attributesDropdown) => {
  const attributesDetails = [];

  for (let attribute = 0; attribute < attributes.length; attribute += 1) {
    const values = attributesDropdown.filter(element => {
      return element.id === attributes[attribute].attribute.id;
    });

    const attr = values.length > 0 ? values[0] : false;
    const attributeValues = attr && attr.values ? attr.values : [];
    const attributeType = attr ? attr.attr_type : '';
    const constrainType = attr ? attr.type_constraint : '';

    attributesDetails.push({
      id: attributes[attribute].attribute.id,
      values: attributeValues,
      attributeType,
      constrainType,
      selectedValue: attributes[attribute].value
        ? attributes[attribute].value.id || attributes[attribute].value.choice
        : '-',
      name: attributes[attribute].attribute.name,
      device: attributes[attribute].device,
      mandatory: attributes[attribute].mandatory || false,
      errors: {}
    });
  }
  return attributesDetails;
};

const getLatLongFormat = data => {
  const latLongForm = [];
  let lastLatLng;
  data &&
    data.length > 0 &&
    data.map((each, i) => {
      if (i === 0) {
        lastLatLng = each.location;
      }
      if (
        each.location[0] === lastLatLng[0] &&
        each.location[1] === lastLatLng[1] &&
        i !== 0
      ) {
        return true;
      }
      lastLatLng = each.location;

      latLongForm.push({
        latLng: { lat: each.location[0], lng: each.location[1] },
        timestamp: moment.unix(each.timestamp).format('DD MMM YYYY HH:mm')
      });
      return true;
    });
  return latLongForm;
};

const getLatLongFormatList = data => {
  const latLongForm = [];
  data &&
    data.length > 0 &&
    data.map(each => {
      latLongForm.push({ lat: each.latLng.lat, lng: each.latLng.lng });
      return true;
    });

  return latLongForm;
};

function convertHextoRgb(hexColor) {
  const hex = hexColor.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = `${r}, ${g}, ${b}`;
  return result;
}

function goBack(props) {
  props.history.goBack();
}

const getRoleName = name => {
  const re = /(orgid\d+_)?(.*)/i;
  const matches = re.exec(name);

  // First element is the input string
  // Second element is the first group
  // Third element is the required name
  return matches[2];
};

function getSelectedRolesFormat(roles) {
  const userRoles = roles.map(role => {
    return { value: role.id, label: getRoleName(role.name) };
  });
  return userRoles || ['-'];
}

function paginationUrlChange(url, apiUrl) {
  const limit = getUrlParameter(url, 'limit');
  const offset = getUrlParameter(url, 'offset');
  const search = getUrlParameter(url, 'search');
  const date = getUrlParameter(url, 'date');
  const department = getUrlParameter(url, 'department');
  let paginationUrl = apiUrl;
  if (limit > 0 && offset >= 0) {
    paginationUrl = `${apiUrl}?limit=${limit}&offset=${offset}`;
  }
  if (search) {
    paginationUrl += `&search=${search}`;
  }
  if (date) {
    paginationUrl += `&date=${date}`;
  }
  if (department) {
    paginationUrl += `&department=${department}`;
  }
  return paginationUrl;
}

function getUrlParameter(url, name) {
  // eslint-disable-next-line no-useless-escape
  const newName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${newName}=([^&#]*)`);
  const results = regex.exec(url);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function showAlertMessage(alertData) {
  const { alertTitle, alertIcon } = alertData;
  Swal.fire({
    title: alertTitle,
    icon: alertIcon
  });
}

// to fetch user profile details
const fetchUserProfile = refresh => {
  window.axios.asyncGetData(FOOTPRINTS_API.USER_PROFILE).then(response => {
    updateSession(response.data);
    if (refresh) {
      window.location.reload();
    }
  });
};

export {
  getUnixTime,
  isLessThanTime,
  getTimeFromUnix,
  sortByTimestamp,
  systemDateFormat,
  humanlyDateFormat,
  getDateFromUnixTime,
  getTimeFromUnixTime,
  getUnixFromDateTime,
  getDayOfMonthFromUnix,
  blockBackButton,
  roundToDecimalPlace,
  getFullName,
  isDataExists,
  secToHHMM,
  isFetchData,
  isNumber,
  isAlphaNumeric,
  checkFetchStatus,
  getTokenFromUrl,
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  secondsToHrmins,
  toSentenceCase,
  getUniqueId,
  getStrippedNum,
  loadJS,
  getActionsFormat,
  getOrgUnitIds,
  getNormalId,
  getFormattedDate,
  getSelectedRolesFormat,
  isValidData,
  getAnalyFormOfAsset,
  IsOldNewAttrValueEquals,
  getExistedAttributes,
  getLatLongFormat,
  getLatLongFormatList,
  convertHextoRgb,
  goBack,
  getRoleName,
  paginationUrlChange,
  showAlertMessage,
  fetchUserProfile
};
