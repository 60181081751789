import ACTIONS from '../../apiConfig/actions.constants';
import { STORE } from '../../utils/constants';

const initialState = {
  [STORE.GET_ASSETS_COUNT_WRT_ASSET_TYPE]: [],
  [STORE.GET_GENERIC_ASSETS_COUNT]: {},
  errorCode: '',
  inProgress: false
};

const analyticsReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ASSET_TYPES.GET_ASSETS_COUNT:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSET_TYPES.GET_ASSETS_COUNT_SUCCESS:
      return {
        ...state,
        [STORE.GET_ASSETS_COUNT_WRT_ASSET_TYPE]: payload,
        inProgress: false
      };
    case ACTIONS.ASSET_TYPES.GET_ASSETS_COUNT_FAIL: {
      return { ...state, errorCode: payload.error_code, inProgress: false };
    }

    case ACTIONS.ASSETS.GET_GENERIC_ASSETS_COUNT:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSETS.GET_GENERIC_ASSETS_COUNT_SUCCESS:
      return {
        ...state,
        [STORE.GET_GENERIC_ASSETS_COUNT]: payload,
        inProgress: false
      };
    case ACTIONS.ASSET_TYPES.GET_GENERIC_ASSETS_COUNT_FAIL: {
      return { ...state, errorCode: payload.error_code, inProgress: false };
    }

    default:
      return state;
  }
};

const dummy = () => {};
export { analyticsReducerStore, dummy };
