import { call, put } from 'redux-saga/effects';
import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';
import { fetchUserProfile } from '../../utils/common';

function* getOrganisationDetails(action) {
  let response;
  try {
    const API_URL = reverse(FOOTPRINTS_API.ORGANISATION_DETAILS);
    response = yield call(window.axios.getData, API_URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ORGANISATION_DETAILS.GET_ORGANISATION_DETAILS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ORGANISATION_DETAILS.GET_ORGANISATION_DETAILS_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ORGANISATION_DETAILS.GET_ORGANISATION_DETAILS_FAIL,
      payload: e
    });
  }
}

function* submitOrganisationDetails(action) {
  let response;
  try {
    const { data } = action;
    const API_URL = reverse(FOOTPRINTS_API.ORGANISATION_DETAILS);
    response = yield call(window.axios.patchData, API_URL, data.data);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ORGANISATION_DETAILS.EDIT_ORGANISATION_DETAILS_SUCCESS,
        payload: response.data
      });
      fetchUserProfile(true);
      yield put({
        type: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ORGANISATION_DETAILS.EDIT_ORGANISATION_DETAILS_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ORGANISATION_DETAILS.EDIT_ORGANISATION_DETAILS_FAIL,
      payload: e
    });
  }
}

export { getOrganisationDetails, submitOrganisationDetails };
