import { combineReducers } from 'redux';
import { passwordResetStore } from './containers/PasswordReset/PasswordReset.reducer';
import { changePasswordStore } from './containers/ChangePassword/ChangePassword.reducer';
import { listReducerStore } from './containers/ListPage/ListPage.reducer';
import { addEditUserReducerStore } from './containers/AddEditUser/AddEditUser.reducer';
import { DetailsReducerStore } from './containers/Details/Details.reducer';
import { addEditOrgUnitReducerStore } from './containers/AddEditOrgUnits/AddEditOrgUnits.reducer';
import addEditAssetTypes from './containers/AddEditAssetTypes/AddEditAssetTypes.reducer';
import { addEditAssetAttributesReducerStore } from './containers/AddEditAssetAttributes/AddEditAssetAttributes.reducer';
import { alertMessageReducerStore } from './components/AlertMessage/AlertMessage.reducer';
import { generateQrReducerStore } from './containers/GenerateQrCodes/GenerateQrCodes.reducer';
import { analyticsReducerStore } from './containers/Analytics/Analytics.reducer';
import { addEditAssetStore } from './containers/AddAsset/AddAsset.reducer';
import { googleMapReducerStore } from './containers/Maps/Map.reducer';
import { AssetLiveLocationStore } from './containers/AssetLiveLocation/AssetLiveLocation.reducer';
import { addEditServiceAccountReducerStore } from './containers/AddEditServiceAccount/AddEditServiceAccount.reducer';
import { importExcelReducerStore } from './containers/ImportExcel/ImportExcel.reducer';
import { DepartmentListStore } from './containers/DepartmentList/DepartmentList.reducer';
import { DepartmentDetailStore } from './containers/DepartmentDetailContainer/DepartmentDetailContainer.reducer';
import { OrganisationDetailsStore } from './containers/Organisation/Organisation.reducer';
import { STORE, FILTER_STORE } from './utils/constants';
import ACTIONS from './apiConfig/actions.constants';

const initialState = {
  [STORE.ALL_ORG_UNIT]: {},
  [STORE.USER_DETAILS]: {},
  [STORE.USER_LIST]: {},
  [STORE.ORG_UNITS_LIST]: {},
  [STORE.ASSET_ATTRIBUTES]: {},
  [STORE.ASSET_TYPES]: {},
  [STORE.ORG_UNITS]: [],
  [STORE.ORG_UNITS_DETAILS]: {},
  [STORE.QR_CODES_LIST]: {},
  [STORE.ASSETS_LIST]: {},
  [STORE.ASSET_ASSIGNEES]: [],
  [STORE.ASSET_TYPE_ATTRIUTES]: [],
  assetHistoryData: {},
  assetAttachmentsData: [],
  data: {},
  errorCode: '',
  inProgress: false
};

const commonReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ORG_UNITS.GET_ALL_ORG_UNIT:
      return { ...state, inProgress: true };
    case ACTIONS.ORG_UNITS.GET_ALL_ORG_UNIT_SUCCESS: {
      return { ...state, [STORE.ALL_ORG_UNIT]: payload, inProgress: false };
    }
    case ACTIONS.ORG_UNITS.GET_ALL_ORG_UNIT_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.TRAKING_LOGS.GET_TRACKING_LOGS:
      return { ...state, inProgress: true };
    case ACTIONS.TRAKING_LOGS.GET_TRACKING_LOGS_SUCCESS:
      return { ...state, assetHistoryData: payload, inProgress: false };
    case ACTIONS.TRAKING_LOGS.GET_TRACKING_LOGS_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.FILTER.GET_FILTER_RESULT:
      return { ...state, inProgress: true };
    case ACTIONS.FILTER.GET_FILTER_RESULT_SUCCESS:
      return {
        ...state,
        [FILTER_STORE[payload.page]]: payload.data,
        inProgress: false
      };
    case ACTIONS.FILTER.GET_FILTER_RESULT_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSETS.GET_ASSET_ATTACHMENTS:
      return { ...state, data: {}, inProgress: true };
    case ACTIONS.ASSETS.GET_ASSET_ATTACHMENTS_SUCCESS:
      return { ...state, assetAttachmentsData: payload, inProgress: false };
    case ACTIONS.ASSETS.GET_ASSET_ATTACHMENTS_FAIL:
      return { ...state, data: payload.errorCode, inProgress: false };

    case ACTIONS.USERS.GET_ASSIGNEE:
      return { ...state, data: {}, inProgress: true };
    case ACTIONS.USERS.GET_ASSIGNEE_USERS_SUCCESS:
      return { ...state, [STORE.ASSET_ASSIGNEES]: payload, inProgress: false };
    case ACTIONS.USERS.GET_ASSIGNEE_USERS_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_TYPE_ATTRIBUTES:
      return { ...state, [STORE.ASSET_TYPE_ATTRIUTES]: [], inProgress: true };
    case ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_TYPE_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        [STORE.ASSET_TYPE_ATTRIUTES]: payload,
        inProgress: false
      };
    case ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_TYPE_ATTRIBUTES_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.COMMON_STORE.COMMON_RESET_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

/* Import all the reducers and combine them here */
const rootReducer = combineReducers({
  passwordResetStore,
  changePasswordStore,
  listReducerStore,
  addEditUserReducerStore,
  DetailsReducerStore,
  addEditOrgUnitReducerStore,
  addEditAssetTypes,
  addEditAssetAttributesReducerStore,
  commonReducerStore,
  alertMessageReducerStore,
  generateQrReducerStore,
  analyticsReducerStore,
  addEditAssetStore,
  googleMapReducerStore,
  AssetLiveLocationStore,
  addEditServiceAccountReducerStore,
  importExcelReducerStore,
  DepartmentListStore,
  DepartmentDetailStore,
  OrganisationDetailsStore
});

export default rootReducer;
