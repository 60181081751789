import { all, takeEvery, call, put } from 'redux-saga/effects';

import ACTIONS from './apiConfig/actions.constants';
import {
  forgotPasswordSaga,
  passwordResetSaga,
  tokenVerifyRequest
} from './containers/PasswordReset/PasswordReset.saga';
import { changePasswordSaga } from './containers/ChangePassword/ChangePassword.saga';
import {
  getUserList,
  getOrgUnitsList,
  getAssetAttributes,
  getAssetTypes,
  getQrCodes,
  getAssetsList,
  getServiceAccountList
} from './containers/ListPage/ListPage.saga';
import {
  getUserDetails,
  getAssetTypesDetails,
  getAssetAttributesDetails,
  getAssetDetails,
  getOrgUnitDetails,
  getAssetAttachments,
  removeAssignee,
  getAssetTrackingLog,
  getServiceUserDetails,
  removeAsset
} from './containers/Details/Details.saga';
import {
  createUser,
  updateUser,
  getUserDepartments,
  getUserRoles
} from './containers/AddEditUser/AddEditUser.saga';
import {
  addAssetTypes,
  getAssetAttributesForDropdown,
  updateAssetType
} from './containers/AddEditAssetTypes/AddEditAssetTypes.saga';
import {
  addAssetAttributes,
  updateAssetAttributes
} from './containers/AddEditAssetAttributes/AddEditAssetAttributes.saga';
import {
  addOrgUnit,
  updateOrgUnit
} from './containers/AddEditOrgUnits/AddEditOrgUnits.saga';
import { FOOTPRINTS_API } from './apiConfig/api.config';
import { generateQrCodes } from './containers/GenerateQrCodes/GenerateQrCodes.saga';
import {
  getAssetTypeCount,
  getGenericAssetCount
} from './containers/Analytics/Analytics.saga';
import { FILTER_APIS, PAGE } from './utils/constants';
import {
  updateAssetInfo,
  updateAtttributeAsset,
  addAssignee,
  addAsset,
  addAttachments
} from './containers/AddAsset/AddAsset.saga';
import {
  getAssetCurrentLocation,
  getAssetLocationHistory
} from './containers/Maps/Map.saga';
import {
  createServiceAccount,
  updateServiceAccount
} from './containers/AddEditServiceAccount/AddEditServiceAccount.saga';
import getAssetLiveLocation from './containers/AssetLiveLocation/AssetLiveLocation.saga';
import { customise } from './containers/Customise/Customise.saga';
import {
  getAssetCsvFieldsConfig,
  addAssetThroughExcel,
  getDepartmentsCsvFieldsConfig,
  addDepartmentsThroughExcel,
  getUsersCsvFieldsConfig,
  addUsersThroughExcel,
  getAttributesCsvFieldsConfig,
  addAttributesThroughExcel,
  getAssetTypesCsvFieldsConfig,
  addAssetTypesThroughExcel,
  getServAccountsCsvFieldsConfig,
  addServAccountsThroughExcel
} from './containers/ImportExcel/ImportExcel.saga';
import { getDepartmentDetail } from './containers/DepartmentDetailContainer/DepartmentDetailContainer.saga';
import {
  getOrganisationDetails,
  submitOrganisationDetails
} from './containers/Organisation/Organisation.saga';

function* getAllOrgUnit(action) {
  let response;
  try {
    const url = `${FOOTPRINTS_API.ORG_UNIT_ALL}?flat_list=false`;
    response = yield call(window.axios.getData, url, action.data);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ORG_UNITS.GET_ALL_ORG_UNIT_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ORG_UNITS.GET_ALL_ORG_UNIT_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ORG_UNITS.GET_ALL_ORG_UNIT_FAIL,
      payload: e
    });
  }
}

function* getAssetHistory(action) {
  let response;
  try {
    const { assetId } = action.data;
    const url = `${FOOTPRINTS_API.TRACKING_LOGS}?asset=${assetId}`;
    response = yield call(window.axios.getData, url, action.data);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.TRAKING_LOGS.GET_TRACKING_LOGS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.TRAKING_LOGS.GET_TRACKING_LOGS_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.TRAKING_LOGS.GET_TRACKING_LOGS_FAIL,
      payload: e
    });
  }
}

function* getFilterResult(action) {
  let response;
  try {
    const { searchString, page, fitlerString } = action.data;

    const url = `${FILTER_APIS[page]}?${
      fitlerString ? `${FILTER_APIS[page]}=${fitlerString}&` : ''
    }${searchString ? `search=${searchString}` : ''}`;

    response = yield call(window.axios.getData, url);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.FILTER.GET_FILTER_RESULT_SUCCESS,
        payload: {
          page,
          data:
            page === PAGE.ASSET_ATTRIBUTES
              ? response.data.attributes
              : response.data
        }
      });
    } else {
      yield put({
        type: ACTIONS.FILTER.GET_FILTER_RESULT_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.FILTER.GET_FILTER_RESULT_FAIL,
      payload: e
    });
  }
}

function* getAssignees(action) {
  let response;
  try {
    response = yield call(window.axios.getData, FOOTPRINTS_API.GET_ASSIGNEES);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.USERS.GET_ASSIGNEE_USERS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.USERS.GET_ASSIGNEE_USERS_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.USERS.GET_ASSIGNEE_USERS_FAIL,
      payload: e
    });
  }
}

function* getAssetTypeAttributes(action) {
  let response;
  try {
    const { assetType } = action.data;
    response = yield call(
      window.axios.getData,
      `${FOOTPRINTS_API.ASSET_ATTRIBUTES}?asset_type=${assetType}`,
      action.data
    );

    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_TYPE_ATTRIBUTES_SUCCESS,
        payload: response.data.results
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_TYPE_ATTRIBUTES_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_TYPE_ATTRIBUTES_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

export default () =>
  all([
    takeEvery(ACTIONS.PASSWORD_RESET.PASSWORD_RESET, passwordResetSaga),
    takeEvery(ACTIONS.PASSWORD_RESET.FORGOT_PASSWORD, forgotPasswordSaga),
    takeEvery(ACTIONS.PROFILE.CHANGE_PASSWORD, changePasswordSaga),
    takeEvery(ACTIONS.PASSWORD_RESET.VERIFY_TOKEN, tokenVerifyRequest),
    takeEvery(ACTIONS.USERS.GET_USER_LIST, getUserList),
    takeEvery(ACTIONS.USERS.GET_USER_INFO, getUserDetails),
    takeEvery(ACTIONS.USERS.ADD_USER, createUser),
    takeEvery(ACTIONS.USERS.UPDATE_USER, updateUser),
    takeEvery(ACTIONS.ORG_UNITS.GET_ORG_UNITS_LIST, getOrgUnitsList),
    takeEvery(ACTIONS.ORG_UNITS.GET_ORG_UNITS_DETAILS, getOrgUnitDetails),
    takeEvery(ACTIONS.ORG_UNITS.GET_DEPARTMENT_DETAIL, getDepartmentDetail),
    takeEvery(ACTIONS.USERS.GET_USER_DEPARTMENTS, getUserDepartments),
    takeEvery(ACTIONS.ROLES.GET_ROLES, getUserRoles),
    takeEvery(
      ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_ATTRIBUTES,
      getAssetAttributes
    ),
    takeEvery(ACTIONS.ASSET_TYPES.GET_ASSET_TYPES, getAssetTypes),
    takeEvery(ACTIONS.ORG_UNITS.ADD_ORG_UNIT, addOrgUnit),
    takeEvery(ACTIONS.ASSET_TYPES.ASSET_TYPES_DETAILS, getAssetTypesDetails),
    takeEvery(
      ACTIONS.ASSET_ATTRIBUTES.ASSET_ATTRIBUTE_DETAILS,
      getAssetAttributesDetails
    ),

    takeEvery(
      ACTIONS.ASSET_ATTRIBUTES.ADD_ASSET_ATTRIBUTES,
      addAssetAttributes
    ),
    takeEvery(ACTIONS.ASSET_TYPES.ADD_ASSET_TYPE, addAssetTypes),
    takeEvery(
      ACTIONS.ASSET_TYPES.GET_ASSET_ATTRIBUTES,
      getAssetAttributesForDropdown
    ),
    takeEvery(ACTIONS.ORG_UNITS.GET_ALL_ORG_UNIT, getAllOrgUnit),
    takeEvery(ACTIONS.ORG_UNITS.UPDATE_ORG_UNIT, updateOrgUnit),
    takeEvery(ACTIONS.ASSETS.GET_ASSET_DETAILS, getAssetDetails),
    takeEvery(ACTIONS.TRAKING_LOGS.GET_TRACKING_LOGS, getAssetHistory),
    takeEvery(
      ACTIONS.ASSET_ATTRIBUTES.UPDATE_ASSET_ATTRIBUTES,
      updateAssetAttributes
    ),
    takeEvery(ACTIONS.ASSET_TYPES.UPDATE_ASSET_TYPE, updateAssetType),
    takeEvery(ACTIONS.ASSETS.GET_ASSETS_LIST, getAssetsList),
    takeEvery(ACTIONS.QR_CODES.ADD_QR_CODES, generateQrCodes),
    takeEvery(ACTIONS.ASSET_TYPES.GET_ASSETS_COUNT, getAssetTypeCount),
    takeEvery(ACTIONS.ASSETS.GET_GENERIC_ASSETS_COUNT, getGenericAssetCount),
    takeEvery(ACTIONS.QR_CODES.GET_QR_CODES, getQrCodes),
    takeEvery(ACTIONS.FILTER.GET_FILTER_RESULT, getFilterResult),
    takeEvery(ACTIONS.ASSETS.GET_ASSET_ATTACHMENTS, getAssetAttachments),
    takeEvery(ACTIONS.ASSETS.REMOVE_ASSET_ASSIGNEE, removeAssignee),
    takeEvery(ACTIONS.ASSETS.REMOVE_ASSET, removeAsset),
    takeEvery(ACTIONS.ASSETS.GET_ASSET_TRACKING_LOG, getAssetTrackingLog),
    takeEvery(ACTIONS.ASSETS.EDIT_ASSET_INFO, updateAssetInfo),
    takeEvery(ACTIONS.ASSETS.ADD_ASSET_ATTACHMENTS, addAttachments),
    takeEvery(ACTIONS.ASSETS.EDIT_ASSET_ATTRIBUTES, updateAtttributeAsset),
    takeEvery(ACTIONS.ASSETS.ADD_ASSET_ASSIGNEE, addAssignee),
    takeEvery(ACTIONS.USERS.GET_ASSIGNEE_USERS, getAssignees),
    takeEvery(
      ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_TYPE_ATTRIBUTES,
      getAssetTypeAttributes
    ),
    takeEvery(ACTIONS.ASSETS.ADD_ASSET, addAsset),
    takeEvery(
      ACTIONS.GOOGLE_MAPS.GET_ASSETS_CURRENT_LOCATION,
      getAssetCurrentLocation
    ),
    takeEvery(
      ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNT,
      createServiceAccount
    ),
    takeEvery(
      ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_INFO,
      getServiceUserDetails
    ),
    takeEvery(
      ACTIONS.ASSETS.GET_ASSET_LOCATION_HISTORY,
      getAssetLocationHistory
    ),
    takeEvery(ACTIONS.ASSETS.GET_ASSET_LIVE_LOCATION, getAssetLiveLocation),
    takeEvery(
      ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_LIST,
      getServiceAccountList
    ),
    takeEvery(
      ACTIONS.SERVICE_ACCOUNTS.UPDATE_SERVICE_ACCOUNT,
      updateServiceAccount
    ),
    takeEvery(ACTIONS.CUSTOMISE.CUSTOMISE_APP, customise),
    takeEvery(
      ACTIONS.ASSETS.GET_ASSET_CSV_FIELDS_CONFIG,
      getAssetCsvFieldsConfig
    ),
    takeEvery(ACTIONS.ASSETS.ADD_ASSET_THROUGH_EXCEL, addAssetThroughExcel),
    takeEvery(
      ACTIONS.ORG_UNITS.GET_DEPARTMENTS_CSV_FIELDS_CONFIG,
      getDepartmentsCsvFieldsConfig
    ),
    takeEvery(
      ACTIONS.ORG_UNITS.ADD_DEPARTMENTS_THROUGH_EXCEL,
      addDepartmentsThroughExcel
    ),
    takeEvery(
      ACTIONS.USERS.GET_USERS_CSV_FIELDS_CONFIG,
      getUsersCsvFieldsConfig
    ),
    takeEvery(ACTIONS.USERS.ADD_USERS_THROUGH_EXCEL, addUsersThroughExcel),
    takeEvery(
      ACTIONS.ASSET_ATTRIBUTES.GET_ATTRIBUTES_CSV_FIELDS_CONFIG,
      getAttributesCsvFieldsConfig
    ),
    takeEvery(
      ACTIONS.ASSET_ATTRIBUTES.ADD_ATTRIBUTES_THROUGH_EXCEL,
      addAttributesThroughExcel
    ),
    takeEvery(
      ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_CSV_FIELDS_CONFIG,
      getAssetTypesCsvFieldsConfig
    ),
    takeEvery(
      ACTIONS.ASSET_TYPES.ADD_ASSET_TYPES_THROUGH_EXCEL,
      addAssetTypesThroughExcel
    ),
    takeEvery(
      ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG,
      getServAccountsCsvFieldsConfig
    ),
    takeEvery(
      ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL,
      addServAccountsThroughExcel
    ),
    takeEvery(
      ACTIONS.ORGANISATION_DETAILS.GET_ORGANISATION_DETAILS,
      getOrganisationDetails
    ),
    takeEvery(
      ACTIONS.ORGANISATION_DETAILS.EDIT_ORGANISATION_DETAILS,
      submitOrganisationDetails
    )
  ]);
