import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Overlay } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { ERROR_CODES } from '../../utils/errorCodes';
import ACTIONS from '../../apiConfig/actions.constants';
import { ALERT_MESSAAGES, SWAL_OPTIONS } from '../../utils/constants';
import { getActionsFormat } from '../../utils/common';

const AlertMessage = props => {
  const closeAlert = () => {
    const { resetState } = props;
    const type = ACTIONS.ALERT_MESSAGE.RESET_STATE;
    resetState(type);
  };

  const { AlertMessageStore } = props;
  const { status, errorCode, page } = AlertMessageStore;
  let alertIcon = '';
  let message = '';
  let enableAlert = false;
  let alertHeader = '';
  if (status === ACTIONS.ALERT_MESSAGE.ADD_SUCCESS) {
    alertIcon = 'success';
    message = ALERT_MESSAAGES[ACTIONS.ALERT_MESSAGE.ADD_SUCCESS];
    enableAlert = true;
    alertHeader = 'Add Success';
  } else if (status === ACTIONS.ALERT_MESSAGE.FAIL && page) {
    alertIcon = 'error';
    enableAlert = true;
    message = ERROR_CODES[page][errorCode];
    alertHeader = 'Failed';
  } else if (status === ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS) {
    alertIcon = 'success';
    message = ALERT_MESSAAGES[ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS];
    enableAlert = true;
    alertHeader = 'Updated Success';
  }

  const Toast = Swal.mixin({
    ...SWAL_OPTIONS,
    onDestroy: () => {
      closeAlert();
    }
  });

  return (
    <div className="alert-message">
      <Overlay show={false} placement="right">
        {enableAlert &&
          Toast.fire({
            icon: alertIcon,
            title: alertHeader,
            html: message
          })}
      </Overlay>
    </div>
  );
};

AlertMessage.propTypes = {
  AlertMessageStore: PropTypes.object.isRequired,
  resetState: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  AlertMessageStore: state.alertMessageReducerStore
});

const mapDispatchToProps = dispatch => {
  return {
    resetState: type => {
      dispatch(getActionsFormat(type));
    }
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AlertMessage)
);
