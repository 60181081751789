import { call, put } from 'redux-saga/effects';

import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* getUserList(action) {
  let response;
  try {
    response = yield call(window.axios.getData, FOOTPRINTS_API.USER);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.USERS.GET_USER_LIST_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.USERS.GET_USER_LIST_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.USERS.GET_USER_LIST_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getServiceAccountList(action) {
  let response;
  try {
    response = yield call(window.axios.getData, FOOTPRINTS_API.SERVICE_ACCOUNT);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_LIST_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_LIST_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.SERVICE_ACCOUNTS.GET_SERVICE_ACCOUNT_LIST_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getOrgUnitsList(action) {
  let response;
  try {
    response = yield call(
      window.axios.getData,
      FOOTPRINTS_API.ORG_UNITS,
      action.data
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ORG_UNITS.GET_ORG_UNITS_LIST_SUCCESS,
        payload: response.data.results
      });
    } else {
      yield put({
        type: ACTIONS.ORG_UNITS.GET_ORG_UNITS_LIST_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ORG_UNITS.GET_ORG_UNITS_LIST_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getAssetAttributes(action) {
  let response;
  try {
    response = yield call(
      window.axios.getData,
      FOOTPRINTS_API.ASSET_ATTRIBUTES,
      action.data
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_ATTRIBUTES_SUCCESS,
        payload: response.data.results
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_ATTRIBUTES_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_ATTRIBUTES.GET_ASSET_ATTRIBUTES_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getAssetTypes(action) {
  let response;
  try {
    response = yield call(
      window.axios.getData,
      FOOTPRINTS_API.ASSET_TYPES,
      action.data
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_SUCCESS,
        payload: response.data.results
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_TYPES.GET_ASSET_TYPES_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getQrCodes(action) {
  let response;
  try {
    const { limit, offset, location } = action.data;
    const url = `${
      FOOTPRINTS_API.QR_CODES_LIST
    }?limit=${limit}&offset=${offset} ${
      location ? `&location=${location}` : ''
    }`;
    response = yield call(window.axios.getData, url);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.QR_CODES.GET_QR_CODES_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.QR_CODES.GET_QR_CODES_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.QR_CODES.GET_QR_CODES_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getAssetsList(action) {
  let response;
  try {
    const { limit, offset, location } = action.data;
    const url = `${
      FOOTPRINTS_API.ASSETS_LIST
    }?limit=${limit}&offset=${offset} ${
      location ? `&location=${location}` : ''
    }`;
    response = yield call(window.axios.getData, url);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSETS_LIST_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSETS_LIST_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.GET_ASSETS_LIST_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

export {
  getUserList,
  getOrgUnitsList,
  getAssetAttributes,
  getAssetTypes,
  getQrCodes,
  getAssetsList,
  getServiceAccountList
};
