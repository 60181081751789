import { call, put } from 'redux-saga/effects';

import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';
import { fetchUserProfile } from '../../utils/common';

function* customise(action) {
  let response;
  try {
    response = yield call(
      window.axios.patchData,
      reverse(FOOTPRINTS_API.CUSTOMISE),
      action.data
    );
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ACTIONS.CUSTOMISE.CUSTOMISE_APP_SUCCESS,
        payload: response.data
      });
      fetchUserProfile(true);
      yield put({
        type: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.CUSTOMISE.CUSTOMISE_APP_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.CUSTOMISE.CUSTOMISE_APP_FAIL, payload: e });
  }
}

const dummy = () => {};

export { customise, dummy };
