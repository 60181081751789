import { call, put } from 'redux-saga/effects';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* getAssetTypeCount(action) {
  let response;
  try {
    const { orgUnitId } = action.data;
    const url = orgUnitId
      ? `${FOOTPRINTS_API.GET_ASSE_TYPE_COUNT}?org_unit=${orgUnitId}`
      : FOOTPRINTS_API.GET_ASSE_TYPE_COUNT;
    response = yield call(window.axios.getData, url);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSET_TYPES.GET_ASSETS_COUNT_SUCCESS,
        payload: response.data.counts
      });
    } else {
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ASSET_TYPES.GET_ASSETS_COUNT_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getGenericAssetCount(action) {
  let response;
  try {
    const { orgUnitId } = action.data;
    const url = orgUnitId
      ? `${FOOTPRINTS_API.GET_GENERIC_GET_ASSETS_COUNT}?org_unit=${orgUnitId}`
      : FOOTPRINTS_API.GET_GENERIC_GET_ASSETS_COUNT;
    response = yield call(window.axios.getData, url);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSETS.GET_GENERIC_ASSETS_COUNT_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
      yield put({
        type: ACTIONS.ASSETS.GET_GENERIC_ASSETS_COUNT_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSETS.GET_GENERIC_ASSETS_COUNT_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

export { getAssetTypeCount, getGenericAssetCount };
