import { call, put } from 'redux-saga/effects';
import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* addAssetTypes(action) {
  let response;
  const data = {
    asset_types: [action.data.data]
  };
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.ASSET_TYPES,
      data
    );
    if (response.status === 200 || response.status === 204) {
      action.data.props.history.goBack();
      yield put({
        type: ACTIONS.ASSET_TYPES.ADD_ASSET_TYPE_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_TYPES.ADD_ASSET_TYPE_FAIL,
        payload: response.data
      });
    }
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  } catch (e) {
    yield put({ type: ACTIONS.ASSET_TYPES.ADD_ASSET_TYPE_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getAssetAttributesForDropdown(action) {
  let getUrl = FOOTPRINTS_API.ASSET_ATTRIBUTES;
  if (action && action.data && action.data.applicable_to) {
    const applicableTo = action.data.applicable_to;
    getUrl += `?applicable_to=${applicableTo}`;
  }
  let response;
  try {
    response = yield call(window.axios.getData, getUrl, action.data);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSET_TYPES.GET_ASSET_ATTRIBUTES_SUCCESS,
        payload: response.data.results
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_TYPES.GET_ASSET_ATTRIBUTES_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_TYPES.GET_ASSET_ATTRIBUTES_FAIL,
      payload: e
    });
  }
}

function* updateAssetType(action) {
  let response;
  try {
    response = yield call(
      window.axios.patchData,
      reverse(FOOTPRINTS_API.UPDATE_ASSET_TYPE, {
        ...action.data.props.match.params
      }),
      action.data.data
    );
    if (response.status === 201 || response.status === 200) {
      action.data.props.history.goBack();
      yield put({
        type: ACTIONS.ASSET_TYPES.UPDATE_ASSET_TYPE_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_TYPES.UPDATE_ASSET_TYPE_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ASSET_TYPES.UPDATE_ASSET_TYPE_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

const dummy = {};

export { addAssetTypes, getAssetAttributesForDropdown, updateAssetType, dummy };
