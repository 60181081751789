import { call, put } from 'redux-saga/effects';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* generateQrCodes(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.GENERATE_QR_CODES,
      action.data
    );

    if (response.status === 201 || response.status === 200) {
      yield put({
        type: ACTIONS.QR_CODES.ADD_QR_CODES_SUCCESS,
        payload: { callStatus: true }
      });
    } else {
      yield put({
        type: ACTIONS.QR_CODES.ADD_QR_CODES_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.QR_CODES.ADD_QR_CODES_FAIL, payload: e });
  }
}

const dummy = {};

export { generateQrCodes, dummy };
