import { FOOTPRINTS_API } from '../apiConfig/api.config';

const LOGIN_FORM_HEADER = 'Sign In';
const LOGIN_BUTTON_TEXT = 'Sign In';

const PASSWORD_RESET_HEADER = 'Password Reset';
const PASSWORD_RESET_TOKEN_HEADER = 'password-reset-token';

const CHANGE_PASSWORD_HEADER = 'Change Password';

const SUBMIT = 'Submit';
const ADD = 'Add';
const CREATE = 'Create';
const GO_BACK = 'Go Back';
const SAVE_CHANGES = 'Save Changes';
const CANCEL = 'Cancel';
const GET_HISTORY = 'Get History';

const FORGOT_PASSWORD_SUCCESS =
  'Almost done! We have sent you an email with a link to complete a password reset. Be sure to check your spam folder if you don’t see the email within a few minutes.';

const ROLES = {
  EMPLOYEE: 'Employee',
  ASSET_MANAGER: 'Asset Manager',
  ORG_UNIT_ADMIN: 'Department Admin',
  ORG_ADMIN: 'Organisation Admin'
};

const LOG_TYPE = [
  'Check in',
  'Check out',
  'Change Assignee',
  'Updated Attributes',
  'Add Notes',
  'Update Asset Info'
];

const ASSET_ID = {
  rfid: 'RFID',
  bar_code: 'Bar Code',
  qr_code: 'QR Code'
};

const ATTRIBUTE_APPLICABLE = {
  USERS: 0,
  DEPARTMENTS: 1,
  ASSET: 2
};

const PAGE = {
  LOGIN: 'LOGIN',
  USERS: 'USERS',
  ANALYTICS: 'ANALYTICS',
  ORG_UNITS: 'ORG_UNITS',
  ASSET_TYPES: 'ASSET_TYPES',
  ASSET_ATTRIBUTES: 'ASSET_ATTRIBUTES',
  ATTRIBUTES: 'ATTRIBUTES',
  ADD_EDIT_ATTRIBUTES: 'ADD_EDIT_ATTRIBUTES',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  USER_DETAILS: 'USER_DETAILS',
  ORG_UNITS_DETAILS: 'ORG_UNITS_DETAILS',
  ADD_USER: 'ADD_USER',
  UPDATE_USER: 'UPDATE_USER',
  ADD_ORG_UNIT: 'ADD_ORG_UNIT',
  ASSET_TYPES_DETAILS: 'ASSET_TYPES_DETAILS',
  ASSET_ATTRIBUTE_DETAILS: 'ASSET_ATTRIBUTE_DETAILS',
  ADD_ASSET_TYPES: 'ADD_ASSET_TYPES',
  ADD_ASSET_ATTRIBUTES: 'ADD_ASSET_ATTRIBUTES',
  UPDATE_ORG_UNIT: 'UPDATE_ORG_UNIT',
  ASSET: 'ASSET',
  ASSET_DETAILS: 'ASSET_DETAILS',
  ASSET_LOG: 'ASSET_LOG',
  UPDATE_ASSET_ATTRIBUTES: 'UPDATE_ASSET_ATTRIBUTES',
  UPDATE_ASSET_TYPES: 'UPDATE_ASSET_TYPES',
  QR_CODES: 'QR_CODES',
  ADD_QR_CODES: 'ADD_QR_CODES',
  ADD_ASSET: 'ADD_ASSET',
  EDIT_ASSET_INFO: 'INFO',
  EDIT_ASSET_ATTRIBUTES: 'ATTRIBUTES',
  ADD_ASSET_ASSIGNEE: 'ASSIGNEE',
  ADD_SERVICE_ACCOUNT: 'ADD_SERVICE_ACCOUNT',
  UPDATE_SERVICE_ACCOUNT: 'UPDATE_SERVICE_ACCOUNT',
  SERVICE_ACCOUNT_DETAILS: 'SERVICE_ACCOUNT_DETAILS',
  ASSET_LOCATION_HISTORY: 'ASSET_LOCATION_HISTORY',
  ASSET_LIVE_LOCATION: 'ASSET_LIVE_LOCATION',
  SERVICE_ACCOUNTS: 'SERVICE_ACCOUNTS',
  CUSTOMISE: 'CUSTOMISE',
  ASSET_IMPORT_CSV: 'ASSET_IMPORT_CSV',
  ORG_UNITS_IMPORT_CSV: 'ORG_UNITS_IMPORT_CSV',
  USERS_IMPORT_CSV: 'USERS_IMPORT_CSV',
  ASSET_ATTRIBUTES_IMPORT_CSV: 'ASSET_ATTRIBUTES_IMPORT_CSV',
  ASSET_TYPES_IMPORT_CSV: 'ASSET_TYPES_IMPORT_CSV',
  ORGANISATION: 'ORGANISATION',
  ROLE: {
    LIST: 'ROLE_LIST',
    EDIT: 'ROLE_EDIT',
    ADD: 'ROLE_ADD'
  },
  SERVICE_ACCOUNTS_IMPORT_CSV: 'SERVICE_ACCOUNTS_IMPORT_CSV',
  USER_PROFILE: 'USER_PROFILE',
  BUSINESS_RULES: {
    LIST: 'BUSINESS_RULES_LIST',
    ADD: 'BUSINESS_RULES_ADD',
    EDIT: 'BUSINESS_RULES_EDIT',
    DETAIL: 'BUSINESS_RULES_DETAIL'
  },
  ADD_ATTACHMENTS: 'ADD_ATTACHMENTS',
  LICENCE: 'LICENCE',
  ABOUT_US: 'ABOUT_US'
};

const STORE = {
  ROLES: 'USER_ROLES',
  USER_LIST: 'USER_LIST',
  USER_DETAILS: 'USER_DETAILS',
  ORG_UNITS_LIST: 'ORG_UNITS_LIST',
  ORG_UNITS_DETAILS: 'ORG_UNITS_DETAILS',
  GET_USER_DEPARTMENTS: 'GET_USER_DEPARTMENTS',
  ASSET_ATTRIBUTES: 'ASSET_ATTRIBUTES',
  ASSET_TYPES: 'ASSET_TYPES',
  ASSET_ATTRIBUTES_DROPDOWN: 'ASSET_ATTRIBUTES_DROPDOWN',
  ALL_ORG_UNIT: 'ALL_ORG_UNIT',
  ASSET_ATTRIBUTE_DETAILS: 'ASSET_ATTRIBUTE_DETAILS',
  ASSET_TYPES_DETAILS: 'ASSET_TYPES_DETAILS',
  GET_ASSETS_COUNT_WRT_ASSET_TYPE: 'GET_ASSETS_COUNT_WRT_ASSET_TYPE',
  GET_GENERIC_ASSETS_COUNT: 'GET_GENERIC_ASSETS_COUNT',
  QR_CODES_LIST: 'QR_CODES_LIST',
  ASSETS_LIST: 'ASSETS_LIST',
  ASSET_ASSIGNEES: 'ASSET_ASSIGNEES',
  ASSET_TYPE_ATTRIUTES: 'ASSET_TYPE_ATTRIUTES',
  SERVICE_ACCOUNT_LIST: 'SERVICE_ACCOUNT_LIST'
};

// API call status
const CALL_STATUS = {
  ERROR: 'error',
  PENDING: 'pending',
  RESPONDED: 'responded',
  IN_PROGRESS: 'in-progress',
  RESEND_ERROR: 'resend-error',
  TOKEN_ERROR: 'TOKEN_ERROR',
  TOKEN_RESPONDED: 'TOKEN_RESPONDED'
};

const ASSET_STATUS = ['printed', 'in-progress', 'completed'];

const DATA_NOT_FOUND = {
  USERS: 'Users',
  ANALYTICS: 'Analytics',
  ORG_UNITS: 'Departments',
  ASSET_TYPES: 'Asset types',
  ASSET_ATTRIBUTES: 'Attributes',
  USER_DETAILS: 'User details',
  ORG_UNITS_DETAILS: 'Department details',
  ASSET_TYPES_DETAILS: 'Asset type details',
  ASSET_ATTRIBUTE_DETAILS: 'Asset attribute details',
  ASSET: 'Assets',
  ASSET_DETAILS: 'Asset details',
  QR_CODES: 'QR Codes',
  ASSET_LOG: 'Asset Tracking Log'
};

const ALERT_MESSAAGES = {
  ADD_SUCCESS: 'Added Successfully',
  UPDATE_SUCCESS: 'Updated Successfully'
};

const COMPONENTS = {
  CARD: 'CARD',
  TABLE: 'TABLE'
};

const FILTER_APIS = {
  [PAGE.ASSET_ATTRIBUTES]: FOOTPRINTS_API.ASSET_ATTRIBUTES,
  [PAGE.ASSET_TYPES]: FOOTPRINTS_API.ASSET_TYPES,
  [PAGE.USERS]: FOOTPRINTS_API.USER,
  [PAGE.ORG_UNITS]: FOOTPRINTS_API.ORG_UNITS,
  [PAGE.ASSET]: FOOTPRINTS_API.ASSETS_LIST
};

const FILTER_STRING = {
  [PAGE.ASSET]: 'asset_type'
};

const FILTER_STORE = {
  [PAGE.ASSET_ATTRIBUTES]: STORE.ASSET_ATTRIBUTES,
  [PAGE.ASSET_TYPES]: STORE.ASSET_TYPES,
  [PAGE.USERS]: STORE.USER_LIST,
  [PAGE.ORG_UNITS]: STORE.ORG_UNITS_LIST,
  [PAGE.ASSET]: STORE.ASSETS_LIST
};

const NUMBER = 'Number';
const TEXT = 'Text';
const SWITCH = 'Boolean';
const LOCATION = 'Geo-location';

const FREE_VALUE = 'Free Value';
const MULTI_VALUE = 'Multi Value';
const RANGE = 'Range';

const ATTRIBUTE_TYPES = [TEXT, NUMBER, SWITCH, LOCATION];
const ATTR_TYPES = {
  0: TEXT,
  1: NUMBER,
  2: SWITCH,
  3: LOCATION
};

const CONSTRAINTS_TYPES = [MULTI_VALUE, FREE_VALUE];
const ATTR_CONSTRAINTS = {
  0: MULTI_VALUE,
  1: FREE_VALUE
  // 2: RANGE
};

const USERS = 'Users';
const DEPARTMENTS = 'Departments';
const ASSETS = 'Assets';
const APP_RESOURCE_CLASSES = {
  0: USERS,
  1: DEPARTMENTS,
  2: ASSETS
};

const BOOLEAN = ['false', 'true'];

const BOOTSTRAP_INFO = 'info';
const BOOTSTRAP_DARK = 'dark';
const BOOTSTRAP_LIGHT = 'light';
const BOOTSTRAP_DANGER = 'danger';
const BOOTSTRAP_PRIMARY = 'primary';
const BOOTSTRAP_SUCCESS = 'success';
const BOOTSTRAP_WARNING = 'warning';
const BOOTSTRAP_SECONDARY = 'secondary';

const BOOTSTRAP_VARIANTS = [
  BOOTSTRAP_DARK,
  BOOTSTRAP_INFO,
  BOOTSTRAP_LIGHT,
  BOOTSTRAP_DANGER,
  BOOTSTRAP_PRIMARY,
  BOOTSTRAP_SUCCESS,
  BOOTSTRAP_WARNING,
  BOOTSTRAP_SECONDARY
];

const USER_PERMISSIONS = {
  // Attributes
  ATTR_ADD: 'attributes.add_attribute',
  ATTR_ADD_NAME: 'Can add attributes',

  ATTR_VIEW: 'attributes.view_attribute',
  ATTR_VIEW_NAME: 'Can view attributes',

  ATTR_CHANGE: 'attributes.change_attribute',
  ATTR_CHANGE_NAME: 'Can change attribute details',

  // Departments
  DEPT_ADD: 'organisationunits.add_orgunit',
  DEPT_ADD_NAME: 'Can add departments',

  DEPT_VIEW: 'organisationunits.view_orgunit',
  DEPT_VIEW_NAME: 'Can view departments',

  DEPT_CHANGE: 'organisationunits.change_orgunit',
  DEPT_CHANGE_NAME: 'Can change department details',

  DEPT_ADD_ATTR: 'attributes.add_dept_attr',
  DEPT_ADD_ATTR_NAME: 'Can add attributes to departments',

  DEPT_CHANGE_ATTR: 'attributes.change_dept_attr',
  DEPT_CHANGE_ATTR_NAME: 'Can change attributes of departments',

  // Users and ID readers
  USER_ADD: 'users.add_footprintsuser',
  USER_ADD_NAME: 'Can add users',

  USER_VIEW: 'users.view_footprintsuser',
  USER_VIEW_NAME: 'Can view users',

  USER_CHANGE: 'users.change_footprintsuser',
  USER_CHANGE_NAME: 'Can change user details',

  USER_ADD_ATTR: 'attributes.add_user_attr',
  USER_ADD_ATTR_NAME: 'Can add attributes to users',

  USER_CHANGE_ATTR: 'attributes.change_user_attr',
  USER_CHANGE_ATTR_NAME: 'Can change attributes of users',

  // Asset types
  ASSET_TYPE_ADD: 'assets.add_assettype',
  ASSET_TYPE_ADD_NAME: 'Can add asset types',

  ASSET_TYPE_VIEW: 'assets.view_assettype',
  ASSET_TYPE_VIEW_NAME: 'Can view asset types',

  ASSET_TYPE_CHANGE: 'assets.change_assettype',
  ASSET_TYPE_CHANGE_NAME: 'Can change asset type details',

  ASSET_TYPE_ADD_ATTR: 'attributes.add_assettype_attr',
  ASSET_TYPE_ADD_ATTR_NAME: 'Can add attributes to asset types',

  ASSET_TYPE_CHANGE_ATTR: 'attributes.change_assettype_attr',
  ASSET_TYPE_CHANGE_ATTR_NAME: 'Can change attributes of asset types',

  ASSET_TYPE_DELETE_ATTR: 'attributes.delete_assettype_attr',
  ASSET_TYPE_DELETE_ATTR_NAME: 'Can delete attributes of asset types',

  // Assets
  ASSET_ADD: 'assets.add_asset',
  ASSET_ADD_NAME: 'Can add assets',

  ASSET_VIEW: 'assets.view_asset',
  ASSET_VIEW_NAME: 'Can view assets',

  ASSET_CHANGE: 'assets.change_asset',
  ASSET_CHANGE_NAME: 'Can change asset details',

  ASSET_ADD_ATTR: 'attributes.add_asset_attr',
  ASSET_ADD_ATTR_NAME: 'Can add attributes to assets',

  ASSET_CHANGE_ATTR: 'attributes.change_asset_attr',
  ASSET_CHANGE_ATTR_NAME: 'Can change attributes of assets',

  ASSET_CHANGE_ASSIGNEE: 'assets.change_asset_assignee',
  ASSET_CHANGE_ASSIGNEE_NAME: 'Can change or remove asset assignee',

  // Organisation
  ORG_VIEW: 'organisationunits.view_organisation',
  ORG_VIEW_NAME: 'Can view organisation',

  ORG_CHANGE: 'organisationunits.change_organisation',
  ORG_CHANGE_NAME: 'organisationunits.change_organisation',

  ORG_THEME_CHANGE: 'organisationunits.change_theme',
  ORG_THEME_CHANGE_NAME: 'Can change theme',

  // Roles
  ROLE_ADD: 'auth.add_group',
  ROLE_ADD_NAME: 'Can add user roles',

  ROLE_VIEW: 'auth.view_group',
  ROLE_VIEW_NAME: 'Can view user roles',

  ROLE_CHANGE: 'auth.change_group',
  ROLE_CHANGE_NAME: 'Can change user roles',

  // Business Rules
  RULE_ADD: 'rules.add_rule',
  RULE_ADD_NAME: 'Can add business rules',

  RULE_CHANGE: 'rules.change_rule',
  RULE_CHANGE_NAME: 'Can change business rules',

  RULE_VIEW: 'rules.view_rule',
  RULE_VIEW_NAME: 'Can view business rules'
};

const SWAL_OPTIONS = {
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  showCloseButton: true,
  timer: 5000,
  timerProgressBar: true
};
const SWAL_ALERT_ERROR_ICON = 'error';
const SWAL_ALERT_SUCCESS_ICON = 'success';

const SUCCESS_MESSAGES = {
  ROLE_UPDATE: 'Role update success',
  ROLE_CREATE: 'Role created'
};

export {
  BOOTSTRAP_INFO,
  BOOTSTRAP_DARK,
  BOOTSTRAP_LIGHT,
  BOOTSTRAP_DANGER,
  BOOTSTRAP_PRIMARY,
  BOOTSTRAP_SUCCESS,
  BOOTSTRAP_WARNING,
  BOOTSTRAP_SECONDARY,
  PAGE,
  CALL_STATUS,
  LOGIN_FORM_HEADER,
  LOGIN_BUTTON_TEXT,
  PASSWORD_RESET_HEADER,
  CHANGE_PASSWORD_HEADER,
  PASSWORD_RESET_TOKEN_HEADER,
  STORE,
  ROLES,
  SUBMIT,
  ASSET_STATUS,
  LOG_TYPE,
  DATA_NOT_FOUND,
  ALERT_MESSAAGES,
  COMPONENTS,
  FILTER_APIS,
  FILTER_STRING,
  FILTER_STORE,
  CONSTRAINTS_TYPES,
  ATTRIBUTE_TYPES,
  NUMBER,
  TEXT,
  SWITCH,
  FREE_VALUE,
  MULTI_VALUE,
  BOOLEAN,
  BOOTSTRAP_VARIANTS,
  LOCATION,
  RANGE,
  FORGOT_PASSWORD_SUCCESS,
  ASSET_ID,
  ATTR_TYPES,
  ATTR_CONSTRAINTS,
  APP_RESOURCE_CLASSES,
  USERS,
  DEPARTMENTS,
  ASSETS,
  CREATE,
  GO_BACK,
  ADD,
  SAVE_CHANGES,
  CANCEL,
  ATTRIBUTE_APPLICABLE,
  GET_HISTORY,
  USER_PERMISSIONS,
  SWAL_OPTIONS,
  SWAL_ALERT_ERROR_ICON,
  SWAL_ALERT_SUCCESS_ICON,
  SUCCESS_MESSAGES
};
