import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  data: [],
  errorCode: '',
  inProgress: false
};

const AssetLiveLocationStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ASSETS.GET_ASSET_LIVE_LOCATION:
      return { ...state, inProgress: true };
    case ACTIONS.ASSETS.GET_ASSET_LIVE_LOCATION_SUCCESS:
      return { ...state, data: payload.locations, inProgress: false };
    case ACTIONS.ASSETS.GET_ASSET_LIVE_LOCATION_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    default:
      return state;
  }
};

const dummy = () => {};

export { AssetLiveLocationStore, dummy };
