import { call, put } from 'redux-saga/effects';
import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* addAssetAttributes(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.ADD_ASSET_ATTRIBUTES,
      action.data.data
    );

    if (response.status === 201 || response.status === 200) {
      action.data.props.history.goBack();
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.ADD_ASSET_ATTRIBUTES_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.ADD_ASSET_ATTRIBUTES_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data.props }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_ATTRIBUTES.ADD_ASSET_ATTRIBUTES_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* updateAssetAttributes(action) {
  let response;
  try {
    response = yield call(
      window.axios.patchData,
      reverse(FOOTPRINTS_API.UPDATE_ASSET_ATTRIBUTE, {
        ...action.data.props.match.params
      }),
      action.data.data
    );

    if (response.status === 201 || response.status === 200) {
      action.data.props.history.goBack();
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.UPDATE_ASSET_ATTRIBUTES_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.UPDATE_ASSET_ATTRIBUTES_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data.props }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_ATTRIBUTES.UPDATE_ASSET_ATTRIBUTES_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

const dummy = {};

export { addAssetAttributes, updateAssetAttributes, dummy };
