import React from 'react';
import { Redirect } from 'react-router-dom';
import { setCookie, getCookie, deleteCookie } from './cookieFunctions';
import { userSessionCookie, rememberUserCookie } from '../config';

const setSession = (data, cookieName) => {
  setCookie(cookieName, JSON.stringify(data), 1);
};

const updateSession = data => {
  const currentSessionData = getSession(userSessionCookie);
  if (data && currentSessionData) {
    currentSessionData.first_name = data.first_name;
    currentSessionData.last_name = data.last_name;
    currentSessionData.color = data.organisation.color;
    currentSessionData.logo = data.organisation.logo;
    currentSessionData.permissions = data.permissions;
    currentSessionData.organisation_admin = data.organisation_admin;
    setSession(currentSessionData, userSessionCookie);
  }
};

const redirectToLogin = eve => {
  const urlParts = window.location.pathname.split('/');
  window.location.assign(`/${urlParts[0]}`);
};

const getSession = cookieName => {
  let sessionData;
  try {
    sessionData = JSON.parse(getCookie(cookieName)) || {};
  } catch (e) {
    sessionData = {};
    setSession(sessionData); // set it empty
  }
  return sessionData;
};

const deleteSession = cookieName => {
  deleteCookie(cookieName);
};

const deleteRememberMe = cookieName => {
  deleteCookie(cookieName);
};

const setRememberMe = (isRememberMe, rememberData, cookieName) => {
  const data = { isRememberMe: true, ...rememberData };
  if (isRememberMe) {
    setCookie(cookieName, JSON.stringify(data), 30);
  } else {
    deleteRememberMe(cookieName);
  }
};

const getRememberMe = cookieName => {
  let rememberMe;
  try {
    rememberMe = JSON.parse(getCookie(cookieName));
  } catch (e) {
    rememberMe = {};
  }
  return rememberMe;
};

const isUserLoggedIn = () => {
  const { sessionId, user } = getSession();
  if (user && sessionId) {
    return true;
  }
  return false;
};

const logout = (sessionCookie, rememberMeCookie) => {
  deleteSession(sessionCookie);
  deleteRememberMe(rememberMeCookie);
  redirectToLogin();
};

const handleUrl = authorizedComponent => {
  return checkUserLoggedIn() ? authorizedComponent : <Redirect to="/login" />;
};

const checkUserLoggedIn = () => {
  const sessionData = getSession(userSessionCookie);
  const rememberData = getRememberMe(rememberUserCookie);
  if (
    (sessionData &&
      Object.entries(sessionData).length > 0 &&
      sessionData.token) ||
    (rememberData &&
      Object.entries(rememberData).length > 0 &&
      rememberData.token)
  ) {
    return true;
  }
  return false;
};

const getCookieData = (session, remember) => {
  let navData = [];
  if (session && session.token) {
    navData = [
      session.first_name,
      session.last_name,
      session.organisation,
      session.logo,
      session.permissions,
      session.organisation_admin
    ];
  } else if (remember && remember.token) {
    navData = [
      remember.first_name,
      remember.last_name,
      session.organisation,
      session.logo,
      session.permissions,
      session.organisation_admin
    ];
  }

  return navData;
};

export {
  logout,
  handleUrl,
  setSession,
  getSession,
  setRememberMe,
  deleteSession,
  getRememberMe,
  isUserLoggedIn,
  redirectToLogin,
  checkUserLoggedIn,
  updateSession,
  getCookieData
};
