import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  errorCode: '',
  inProgress: false
};

const generateQrReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.QR_CODES.ADD_QR_CODES:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.QR_CODES.ADD_QR_CODES_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.QR_CODES.ADD_QR_CODES_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.QR_CODES.RESET_STATE:
      return { ...initialState };

    default:
      return state;
  }
};

const dummy = () => {};

export { generateQrReducerStore, dummy };
