import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  errorCode: 0,
  status: '',
  page: ''
};

const alertMessageReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ALERT_MESSAGE.ADD_SUCCESS:
      return { ...state, status: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS };
    case ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS:
      return { ...state, status: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS };
    case ACTIONS.ALERT_MESSAGE.FAIL:
      return {
        ...state,
        errorCode: payload.error_code,
        status: ACTIONS.ALERT_MESSAGE.FAIL,
        page: payload.page
      };
    case ACTIONS.ALERT_MESSAGE.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

const dummy = () => {};

export { alertMessageReducerStore, dummy };
