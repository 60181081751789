import ACTIONS from '../../apiConfig/actions.constants';
import { STORE } from '../../utils/constants';

const initialState = {
  errorCode: '',
  inProgress: false,
  [STORE.ASSET_ATTRIBUTES_DROPDOWN]: []
};

const addEditAssetTypes = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ASSET_TYPES.ADD_ASSET_TYPE:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSET_TYPES.ADD_ASSET_TYPE_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ASSET_TYPES.ADD_ASSET_TYPE_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };
    case ACTIONS.ASSET_TYPES.RESET_STATE:
      return { ...state, errorCode: '', inProgress: false };

    case ACTIONS.ASSET_TYPES.GET_ASSET_ATTRIBUTES:
      return {
        ...state,
        [STORE.ASSET_ATTRIBUTES_DROPDOWN]: [],
        inProgress: true
      };
    case ACTIONS.ASSET_TYPES.GET_ASSET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        [STORE.ASSET_ATTRIBUTES_DROPDOWN]: payload,
        inProgress: false
      };
    case ACTIONS.ASSET_TYPES.GET_ASSET_ATTRIBUTES_FAIL:
      return { ...state, errorCode: payload.errorCode, inProgress: false };

    case ACTIONS.ASSET_TYPES.UPDATE_ASSET_TYPE:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSET_TYPES.UPDATE_ASSET_TYPE_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ASSET_TYPES.UPDATE_ASSET_TYPE_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    default:
      return state;
  }
};

export default addEditAssetTypes;
