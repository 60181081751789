import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  data: [],
  errorCode: '',
  inProgress: false
};

const googleMapReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.GOOGLE_MAPS.GET_ASSETS_CURRENT_LOCATION:
    case ACTIONS.ASSETS.GET_ASSET_LOCATION_HISTORY:
      return { ...state, inProgress: true };
    case ACTIONS.GOOGLE_MAPS.GET_ASSETS_CURRENT_LOCATION_SUCCESS:
    case ACTIONS.ASSETS.GET_ASSET_LOCATION_HISTORY_SUCCESS:
      return { ...state, data: payload.locations, inProgress: false };
    case ACTIONS.GOOGLE_MAPS.GET_ASSETS_CURRENT_LOCATION_FAIL:
    case ACTIONS.ASSETS.GET_ASSET_LOCATION_HISTORY_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    default:
      return state;
  }
};

const dummy = () => {};

export { googleMapReducerStore, dummy };
