import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './ModalWrapper.scss';

const ESC_KEY = 27;
const noScrollClass = 'no-scroll';
class ModalWrapper extends React.Component {
  componentDidMount() {
    document.body.classList.add(noScrollClass);
    document.addEventListener('keydown', this.onEsc.bind(this), false);
  }

  componentWillUnmount() {
    document.body.classList.remove(noScrollClass);
    document.removeEventListener('keydown', this.onEsc.bind(this), false);
  }

  onEsc(e) {
    if (e.keyCode === ESC_KEY) {
      this.closeModal();
    }
  }

  closeModal = () => {
    this.props.onClose();
  };

  render() {
    const {
      show,
      title,
      children,
      lgModal,
      isImage,
      attachmentPopUpHeight
    } = this.props;
    const modalClassNames = cx('modal-dialog', 'modal-dialog-centered', {
      'modal-lg': lgModal
    });
    return (
      <div className="modal-wrapper">
        <div className={show ? 'modal-backdrop' : 'modal-backdrop d-none'}>
          <div
            className={show ? 'modal fade show' : 'modal fade'}
            tabIndex="-1"
            role="dialog"
          >
            <div
              className={
                modalClassNames +
                (isImage && (attachmentPopUpHeight < 550 && ' modal-image'))
              }
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  {title ? (
                    <h5 className="modal-title">{title}</h5>
                  ) : (
                    <div className="mb-0" />
                  )}
                  <button
                    type="button"
                    className="close"
                    onClick={this.closeModal}
                    data-dismiss="modal"
                  >
                    &times;
                  </button>
                </div>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModalWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  lgModal: PropTypes.bool,
  isImage: PropTypes.bool,
  attachmentPopUpHeight: PropTypes.number
};

ModalWrapper.defaultProps = {
  onClose: () => {},
  lgModal: false,
  isImage: false,
  attachmentPopUpHeight: 0
};

export default ModalWrapper;
