import React from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from '../../utils/common';
import { LOG_TYPE } from '../../utils/constants';

import './Notifications.scss';

const Notifications = props => {
  const { notifications } = props;
  const header = (
    <div className="notification-box-header d-flex justify-content-between">
      <div className="mt-auto mb-auto">Notifications</div>
      {props.newNotification ? (
        <button className="mark-read-button" onClick={() => props.markAsRead()}>
          Mark all as read
        </button>
      ) : (
        <div />
      )}
    </div>
  );

  const notificationsList =
    notifications &&
    notifications.map(notification => (
      <div
        className={`${
          !notification.seen ? 'unseen-notification' : ''
        } each-notification`}
        key={notification.id}
        role="button"
        onClick={
          () =>
            props.viewNotification(
              notification.tracking_log.asset.id,
              notification.id
            )
          // eslint-disable-next-line
        }
      >
        <div
          className="notification-header d-flex"
          log={notification.tracking_log.log_type}
        >
          <div className="circle" log={notification.tracking_log.log_type} />
          <div
            className="notification-header-text mt-auto mb-auto"
            log={notification.tracking_log.log_type}
          >
            {LOG_TYPE[notification.tracking_log.log_type]}
          </div>
          <div className="date-time ml-auto">
            {
              getFormattedDate(notification.tracking_log.timestamp)
                .formattedDate
            }
            ,{getFormattedDate(notification.tracking_log.timestamp).time}
          </div>
        </div>
        <div className="notification-body">
          <div>
            Asset :{' '}
            <span className="asset-name">
              {notification.tracking_log.asset.name}
            </span>{' '}
            <span className="asset-id">
              ({notification.tracking_log.asset.asset_id.asset_fp_id})
            </span>
          </div>
        </div>
      </div>
    ));

  const noNotifications = (
    <div className="no-notification">No notifications</div>
  );

  const body = (
    <div className="notification-box-body">
      {notifications && notifications.length > 0
        ? notificationsList
        : noNotifications}
    </div>
  );

  return (
    <div className="notification-box">
      {header}
      {body}
    </div>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array,
  markAsRead: PropTypes.func.isRequired,
  viewNotification: PropTypes.func.isRequired,
  newNotification: PropTypes.bool
};

Notifications.defaultProps = {
  notifications: [],
  newNotification: false
};

export default Notifications;
