import ACTIONS from '../../apiConfig/actions.constants';
import { getFormatOfdData } from '../../utils/utils';
import { STORE } from '../../utils/constants';

const initialState = {
  [STORE.GET_USER_DEPARTMENTS]: [],
  errorCode: '',
  inProgress: false
};

const addEditServiceAccountReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNT:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNT_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.SERVICE_ACCOUNTS.ADD_SERVICE_ACCOUNT_FAIL: {
      return { ...state, errorCode: payload.error_code, inProgress: false };
    }

    case ACTIONS.SERVICE_ACCOUNTS.UPDATE_SERVICE_ACCOUNT:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.SERVICE_ACCOUNTS.UPDATE_SERVICE_ACCOUNT_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.SERVICE_ACCOUNTS.UPDATE_SERVICE_ACCOUNT_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.SERVICE_ACCOUNTS.GET_USER_DEPARTMENTS:
      return { ...state, departments: payload, inProgress: true };
    case ACTIONS.SERVICE_ACCOUNTS.GET_USER_DEPARTMENTS_SUCCESS: {
      const formattedData = getFormatOfdData(payload);
      return {
        ...state,
        [STORE.GET_USER_DEPARTMENTS]: formattedData,
        inProgress: false
      };
    }
    case ACTIONS.SERVICE_ACCOUNTS.GET_USER_DEPARTMENTS_FAIL:
      return { ...state, data: payload, inProgress: false };

    case ACTIONS.SERVICE_ACCOUNTS.RESET_STATE:
      return { ...state, errorCode: '', inProgress: false };
    default:
      return state;
  }
};

const dummy = () => {};

export { addEditServiceAccountReducerStore, dummy };
