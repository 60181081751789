import { CALL_STATUS } from '../../utils/constants';
import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  data: {},
  inProgress: false,
  callStatus: CALL_STATUS.PENDING,
  errorCode: '',
  show: false
};

const changePasswordStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.PROFILE.CHANGE_PASSWORD:
      return { ...state, inProgress: true };
    case ACTIONS.PROFILE.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        inProgress: false,
        callStatus: CALL_STATUS.ERROR,
        errorCode: payload.error_code
      };
    case ACTIONS.PROFILE.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        data: payload,
        inProgress: false,
        callStatus: CALL_STATUS.RESPONDED
      };
    case ACTIONS.PROFILE.RESET_STATE:
      return {
        ...state,
        data: {},
        inProgress: false,
        callStatus: CALL_STATUS.PENDING,
        errorCode: ''
      };
    case ACTIONS.PROFILE.HIDE_CHANGE_PASSWORD:
      return {
        ...state,
        show: false
      };
    case ACTIONS.PROFILE.SHOW_CHANGE_PASSWORD:
      return {
        ...state,
        show: true
      };
    default:
      return state;
  }
};

const dummy = () => {};

export { changePasswordStore, dummy };
