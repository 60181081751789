import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';

import { FOOTPRINTS_ROUTES } from '../../config';
import { getNavBarLogo } from '../../utils/utils';
import Notifications from '../Notifications/Notifications';

import './Navbar.scss';

const Navbar = props => {
  const [img] = getNavBarLogo();

  const logoSrc = img;

  // if (props && props.logo) {
  //   logoSrc = props.logo;
  // }

  const brandItem = (
    <NavLink to={props.logoLink} className="navbar-brand">
      <div className="d-flex flex-row align-items-center navbar-img">
        <img className="logo-img" src={logoSrc} alt="logo" />
      </div>
    </NavLink>
  );

  const notification = (
    <div className="m-auto" ref={props.parentRef}>
      <img
        className="col notification-icon"
        src={props.notificationIcon}
        alt="notification-logo"
        onClick={props.onNotificationClick}
      />
      {props.notificationOpen ? (
        <div className="notification-window">
          <Notifications
            notifications={props.notifications}
            markAsRead={props.markAsRead}
            viewNotification={props.viewNotification}
            newNotification={props.newNotification}
          />
        </div>
      ) : null}
    </div>
  );

  const userItem = (
    <div className="ml-auto row">
      {notification}
      <NavDropdown
        title={props.userName}
        className="col nav-dropdown dropdown-menu-right"
        id="navbarProfile"
      >
        <NavDropdown.Item onClick={props.toViewProfile} id="navbarLogout">
          View Profile
        </NavDropdown.Item>
        <NavDropdown.Item
          onClick={props.toChangePassword}
          id="navbarChangePassword"
        >
          Change Password
        </NavDropdown.Item>
        <NavDropdown.Item onClick={props.onSignout} id="navbarLogout">
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );

  return (
    <div className="fp-navbar">
      <nav
        className={`navbar navbar-expand-lg navbar-light ${props.class}`}
        id="navbar"
      >
        <div className="container">
          {brandItem}
          {userItem}
        </div>
      </nav>
    </div>
  );
};

Navbar.propTypes = {
  class: PropTypes.string,
  logoLink: PropTypes.string,
  userName: PropTypes.string,
  onSignout: PropTypes.func.isRequired,
  toChangePassword: PropTypes.func.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  notificationOpen: PropTypes.bool,
  notificationIcon: PropTypes.string.isRequired,
  notifications: PropTypes.array,
  markAsRead: PropTypes.func.isRequired,
  viewNotification: PropTypes.func.isRequired,
  parentRef: PropTypes.any.isRequired,
  newNotification: PropTypes.bool,
  toViewProfile: PropTypes.func.isRequired
};

Navbar.defaultProps = {
  class: 'nav-1',
  userName: 'Footprints User',
  logoLink: FOOTPRINTS_ROUTES.ANALYTICS,
  notificationOpen: false,
  notifications: [],
  newNotification: false
};

export default Navbar;
