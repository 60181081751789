import { call, put } from 'redux-saga/effects';
import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* createUser(action) {
  let response;
  const createData = {
    users: action.data.data
  };
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.USER,
      createData
    );

    if (response.status === 201 || response.status === 200) {
      action.data.props.history.goBack();
      yield put({
        type: ACTIONS.USERS.ADD_USER_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.USERS.ADD_USER_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.USERS.ADD_USER_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* updateUser(action) {
  let response;
  const { userId } = action.data.props.match.params;
  try {
    const url = reverse(FOOTPRINTS_API.USER_WITH_ID, {
      userId
    });
    response = yield call(window.axios.patchData, url, action.data.data[0]);

    if (response.status === 201 || response.status === 200) {
      action.data.props.history.goBack();
      yield put({
        type: ACTIONS.USERS.UPDATE_USER_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.USERS.UPDATE_USER_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.USERS.UPDATE_USER_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getUserDepartments(action) {
  let response;
  try {
    const URL = `${FOOTPRINTS_API.ORG_UNITS}`;
    response = yield call(window.axios.getData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.USERS.GET_USER_DEPARTMENTS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.USERS.GET_USER_DEPARTMENTS_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.USERS.GET_USER_DEPARTMENTS_FAIL, payload: e });
  }
}

function* getUserRoles(action) {
  let response;
  try {
    const URL = `${FOOTPRINTS_API.ROLES}`;
    response = yield call(window.axios.getData, URL);

    if (response.status === 200) {
      yield put({
        type: ACTIONS.ROLES.GET_ROLES_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ROLES.GET_ROLES_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ROLES.GET_ROLES_FAIL, payload: e });
  }
}
export { createUser, updateUser, getUserDepartments, getUserRoles };
