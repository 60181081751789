import ACTIONS from '../../apiConfig/actions.constants';
import { STORE } from '../../utils/constants';

const initialState = {
  errorCode: '',
  inProgress: false,
  [STORE.ASSET_ATTRIBUTES_DROPDOWN]: []
};

const addEditAssetStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ASSETS.EDIT_ASSET_INFO:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSETS.EDIT_ASSET_INFO_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ASSETS.EDIT_ASSET_INFO_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSETS.EDIT_ASSET_ATTRIBUTES:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSETS.EDIT_ASSET_ATTRIBUTES_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ASSETS.EDIT_ASSET_ATTRIBUTES_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSETS.ADD_ASSET_ASSIGNEE:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSETS.ADD_ASSET_ASSIGNEE_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ASSETS.ADD_ASSET_ASSIGNEE_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSETS.ADD_ASSET_ATTACHMENTS:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSETS.ADD_ASSET_ATTACHMENTS_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ASSETS.ADD_ASSET_ATTACHMENTS_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSETS.ADD_ASSET:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSETS.ADD_ASSET_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ASSETS.ADD_ASSET_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    default:
      return state;
  }
};

const dummy = () => {};

export { addEditAssetStore, dummy };
