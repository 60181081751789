import { call, put } from 'redux-saga/effects';
import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* addAsset(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.ADD_ASSET,
      action.data.postData
    );
    if (response.status === 200 || response.status === 204) {
      action.data.history.goBack();
      yield put({
        type: ACTIONS.ASSETS.ADD_ASSET_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.ADD_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.ADD_ASSET_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ASSET_TYPES.ADD_ASSET_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* updateAssetInfo(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      reverse(FOOTPRINTS_API.UPDATE_ASSET_INFO),
      action.data.postData
    );
    if (response.status >= 200 && response.status <= 300) {
      action.data.history.goBack();
      yield put({
        type: ACTIONS.ASSETS.EDIT_ASSET_INFO_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.EDIT_ASSET_INFO_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ASSETS.EDIT_ASSET_INFO_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* addAttachments(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      reverse(FOOTPRINTS_API.ADD_ASSET_ATTACHMENTS),
      action.data.postData.bodyFormData
    );
    if (response.status >= 200 && response.status <= 300) {
      action.data.history.goBack();
      yield put({
        type: ACTIONS.ASSETS.ADD_ASSET_ATTACHMENTS_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.ADD_ASSET_ATTACHMENTS_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ASSETS.ADD_ASSET_ATTACHMENTS_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* updateAtttributeAsset(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      reverse(FOOTPRINTS_API.UPDATE_ATTRIBUTE_ASSET),
      action.data.postData.bodyFormData
    );
    if (response.status >= 200 && response.status <= 300) {
      action.data.history.goBack();
      yield put({
        type: ACTIONS.ASSETS.EDIT_ASSET_ATTRIBUTES_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.EDIT_ASSET_ATTRIBUTES_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ASSETS.EDIT_ASSET_ATTRIBUTES_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* addAssignee(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      reverse(FOOTPRINTS_API.ADD_ASSET_ASSIGNEE),
      action.data.postData.bodyFormData
    );
    if (response.status >= 200 && response.status <= 300) {
      action.data.history.goBack();
      yield put({
        type: ACTIONS.ASSETS.ADD_ASSET_ASSIGNEE_SUCCESS,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.UPDATE_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.ADD_ASSET_ASSIGNEE_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.ASSETS.ADD_ASSET_ASSIGNEE_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

export {
  addAsset,
  updateAssetInfo,
  updateAtttributeAsset,
  addAssignee,
  addAttachments
};
