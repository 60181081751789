import { call, put } from 'redux-saga/effects';

import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* changePasswordSaga(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.CHANGE_PASSWORD,
      action.data
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.PROFILE.CHANGE_PASSWORD_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.PROFILE.CHANGE_PASSWORD_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.PROFILE.CHANGE_PASSWORD_FAIL,
      payload: e
    });
  }
}

const dummy = () => {};

export { changePasswordSaga, dummy };
