const ACTIONS = {
  AUTH: {
    LOGIN: 'LOGIN',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',

    LOGOUT: 'LOGOUT',
    LOGOUT_FAIL: 'LOGOUT_FAIL',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

    INVALID_USER: 'INVALID_USER',
    RESET_STATE: 'RESET_STATE'
  },

  PASSWORD_RESET: {
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    FORGOT_PASSWORD_FAIL: 'FORGOT_PASSWORD_FAIL',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_INVALID_USER: 'FORGOT_PASSWORD_INVALID_USER',

    PASSWORD_RESET: 'PASSWORD_RESET',
    PASSWORD_RESET_FAIL: 'PASSWORD_RESET_FAIL',
    PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',

    VERIFY_TOKEN: 'VERIFY_TOKEN',
    VERIFY_TOKEN_FAIL: 'VERIFY_TOKEN_FAIL',
    VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',

    RESET_STATE: 'RESET_STATE',
    EMAIL_SEND_FAIL: 'EMAIL_SEND_FAIL'
  },

  PROFILE: {
    GET_PROFILE_INFO: 'GET_PROFILE_INFO',
    GET_PROFILE_INFO_FAIL: 'GET_PROFILE_INFO_FAIL',
    GET_PROFILE_INFO_SUCCESS: 'GET_PROFILE_INFO_SUCCESS',

    EDIT_PROFILE: 'EDIT_PROFILE',
    EDIT_PROFILE_FAIL: 'EDIT_PROFILE_FAIL',
    EDIT_PROFILE_SUCCESS: 'EDIT_PROFILE_SUCCESS',

    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    SHOW_CHANGE_PASSWORD: 'SHOW_CHANGE_PASSWORD',
    HIDE_CHANGE_PASSWORD: 'HIDE_CHANGE_PASSWORD',

    RESET_STATE: 'RESET_STATE'
  },

  SERVICE_ACCOUNTS: {
    GET_SERVICE_ACCOUNT_LIST: 'GET_SERVICE_ACCOUNT_LIST',
    GET_SERVICE_ACCOUNT_LIST_SUCCESS: 'GET_SERVICE_ACCOUNT_LIST_SUCCESS',
    GET_SERVICE_ACCOUNT_LIST_FAIL: 'GET_SERVICE_ACCOUNT_LIST_FAIL',

    GET_SERVICE_ACCOUNT_INFO: 'GET_SERVICE_ACCOUNT_INFO',
    GET_SERVICE_ACCOUNT_INFO_SUCCESS: 'GET_SERVICE_ACCOUNT_INFO_SUCCESS',
    GET_SERVICE_ACCOUNT_INFO_FAIL: 'GET_SERVICE_ACCOUNT_INFO_FAIL',

    ADD_SERVICE_ACCOUNT: 'ADD_SERVICE_ACCOUNT',
    ADD_SERVICE_ACCOUNT_SUCCESS: 'ADD_SERVICE_ACCOUNT_SUCCESS',
    ADD_SERVICE_ACCOUNT_FAIL: 'ADD_SERVICE_ACCOUNT_FAIL',

    UPDATE_SERVICE_ACCOUNT: 'UPDATE_SERVICE_ACCOUNT',
    UPDATE_SERVICE_ACCOUNT_SUCCESS: 'UPDATE_SERVICE_ACCOUNT_SUCCESS',
    UPDATE_SERVICE_ACCOUNT_FAIL: 'UPDATE_SERVICE_ACCOUNT_FAIL',

    GET_USER_DEPARTMENTS: 'GET_USER_DEPARTMENTS',
    GET_USER_DEPARTMENTS_SUCCESS: 'GET_USER_DEPARTMENTS_SUCCESS',
    GET_USER_DEPARTMENTS_FAIL: 'GET_USER_DEPARTMENTS_FAIL',

    GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG:
      'GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG',
    GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG_SUCCESS:
      'GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG_SUCCESS',
    GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG_FAIL:
      'GET_SERVICE_ACCOUNTS_CSV_FIELDS_CONFIG_FAIL',

    ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL: 'ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL',
    ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL_SUCCESS:
      'ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL_SUCCESS',
    ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL_FAIL:
      'ADD_SERVICE_ACCOUNTS_THROUGH_EXCEL_FAIL',

    RESET_STATE: 'RESET_STATE'
  },

  USERS: {
    GET_USER_LIST: 'GET_USER_LIST',
    GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
    GET_USER_LIST_FAIL: 'GET_USER_LIST_FAIL',

    GET_USER_INFO: 'GET_USER_INFO',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAIL: 'GET_USER_INFO_FAIL',

    ADD_USER: 'ADD_USER',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAIL: 'ADD_USER_FAIL',

    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAIL: 'UPDATE_USER_FAIL',

    GET_USER_DEPARTMENTS: 'GET_USER_DEPARTMENTS',
    GET_USER_DEPARTMENTS_SUCCESS: 'GET_USER_DEPARTMENTS_SUCCESS',
    GET_USER_DEPARTMENTS_FAIL: 'GET_USER_DEPARTMENTS_FAIL',

    GET_ASSIGNEE_USERS: 'GET_ASSIGNEE_USERS',
    GET_ASSIGNEE_USERS_SUCCESS: 'GET_ASSIGNEE_USERS_SUCCESS',
    GET_ASSIGNEE_USERS_FAIL: 'GET_ASSIGNEE_USERS_FAIL',

    GET_USERS_CSV_FIELDS_CONFIG: 'GET_USERS_CSV_FIELDS_CONFIG',
    GET_USERS_CSV_FIELDS_CONFIG_SUCCESS: 'GET_USERS_CSV_FIELDS_CONFIG_SUCCESS',
    GET_USERS_CSV_FIELDS_CONFIG_FAIL: 'GET_USERS_CSV_FIELDS_CONFIG_FAIL',

    ADD_USERS_THROUGH_EXCEL: 'ADD_USERS_THROUGH_EXCEL',
    ADD_USERS_THROUGH_EXCEL_SUCCESS: 'ADD_USERS_THROUGH_EXCEL_SUCCESS',
    ADD_USERS_THROUGH_EXCEL_FAIL: 'ADD_USERS_THROUGH_EXCEL_FAIL',

    RESET_STATE: 'RESET_STATE'
  },

  ORG_UNITS: {
    GET_ORG_UNITS_LIST: 'GET_ORG_UNITS_LIST',
    GET_ORG_UNITS_LIST_SUCCESS: 'GET_ORG_UNITS_LIST_SUCCESS',
    GET_ORG_UNITS_LIST_FAIL: 'GET_ORG_UNITS_LIST_FAIL',

    GET_ORG_UNITS_DETAILS: 'GET_ORG_UNITS_DETAILS',
    GET_ORG_UNITS_DETAILS_SUCCESS: 'GET_ORG_UNITS_DETAILS_SUCCESS',
    GET_ORG_UNITS_DETAILS_FAIL: 'GET_ORG_UNITS_DETAILS_FAIL',

    ADD_ORG_UNIT: 'ADD_ORG_UNIT',
    ADD_ORG_UNIT_SUCCESS: 'ADD_ORG_UNIT_SUCCESS',
    ADD_ORG_UNIT_FAIL: 'ADD_ORG_UNIT_FAIL',

    GET_ALL_ORG_UNIT: 'GET_ALL_ORG_UNIT',
    GET_ALL_ORG_UNIT_SUCCESS: 'GET_ALL_ORG_UNIT_SUCCESS',
    GET_ALL_ORG_UNIT_FAIL: 'GET_ALL_ORG_UNIT_FAIL',

    GET_DEPARTMENT_DETAIL: 'GET_DEPARTMENT_DETAIL',
    GET_DEPARTMENT_DETAIL_SUCCESS: 'GET_DEPARTMENT_DETAIL_SUCCESS',
    GET_DEPARTMENT_DETAIL_FAIL: 'GET_DEPARTMENT_DETAIL_FAIL',

    UPDATE_ORG_UNIT: 'UPDATE_ORG_UNIT',
    UPDATE_ORG_UNIT_SUCCESS: 'UPDATE_ORG_UNIT_SUCCESS',
    UPDATE_ORG_UNIT_FAIL: 'UPDATE_ORG_UNIT_FAIL',

    GET_DEPARTMENTS_CSV_FIELDS_CONFIG: 'GET_DEPARTMENTS_CSV_FIELDS_CONFIG',
    GET_DEPARTMENTS_CSV_FIELDS_CONFIG_SUCCESS:
      'GET_DEPARTMENTS_CSV_FIELDS_CONFIG_SUCCESS',
    GET_DEPARTMENTS_CSV_FIELDS_CONFIG_FAIL:
      'GET_DEPARTMENTS_CSV_FIELDS_CONFIG_FAIL',

    ADD_DEPARTMENTS_THROUGH_EXCEL: 'ADD_DEPARTMENTS_THROUGH_EXCEL',
    ADD_DEPARTMENTS_THROUGH_EXCEL_SUCCESS:
      'ADD_DEPARTMENTS_THROUGH_EXCEL_SUCCESS',
    ADD_DEPARTMENTS_THROUGH_EXCEL_FAIL: 'ADD_DEPARTMENTS_THROUGH_EXCEL_FAIL',

    RESET_STATE: 'RESET_STATE'
  },

  ASSET_ATTRIBUTES: {
    GET_ASSET_ATTRIBUTES: 'GET_ASSET_ATTRIBUTES',
    GET_ASSET_ATTRIBUTES_SUCCESS: 'GET_ASSET_ATTRIBUTES_SUCCESS',
    GET_ASSET_ATTRIBUTES_FAIL: 'GET_ASSET_ATTRIBUTES_FAIL',

    ASSET_ATTRIBUTE_DETAILS: 'ASSET_ATTRIBUTE_DETAILS',
    ASSET_ATTRIBUTE_DETAILS_SUCCESS: 'ASSET_ATTRIBUTE_DETAILS_SUCCESS',
    ASSET_ATTRIBUTE_DETAILS_FAIL: 'ASSET_ATTRIBUTE_DETAILS_FAIL',

    ADD_ASSET_ATTRIBUTES: 'ADD_ASSET_ATTRIBUTES',
    ADD_ASSET_ATTRIBUTES_SUCCESS: 'ADD_ASSET_ATTRIBUTES_SUCCESS',
    ADD_ASSET_ATTRIBUTES_FAIL: 'ADD_ASSET_ATTRIBUTES_FAIL',

    UPDATE_ASSET_ATTRIBUTES: 'UPDATE_ASSET_ATTRIBUTES',
    UPDATE_ASSET_ATTRIBUTES_SUCCESS: 'UPDATE_ASSET_ATTRIBUTES_SUCCESS',
    UPDATE_ASSET_ATTRIBUTES_FAIL: 'UPDATE_ASSET_ATTRIBUTES_FAIL',

    GET_ASSET_TYPE_ATTRIBUTES: 'GET_ASSET_TYPE_ATTRIBUTES',
    GET_ASSET_TYPE_ATTRIBUTES_SUCCESS: 'GET_ASSET_TYPE_ATTRIBUTES_SUCCESS',
    GET_ASSET_TYPE_ATTRIBUTES_FAIL: 'GET_ASSET_TYPE_ATTRIBUTES_FAIL',

    GET_ATTRIBUTES_CSV_FIELDS_CONFIG: 'GET_ATTRIBUTES_CSV_FIELDS_CONFIG',
    GET_ATTRIBUTES_CSV_FIELDS_CONFIG_SUCCESS:
      'GET_ATTRIBUTES_CSV_FIELDS_CONFIG_SUCCESS',
    GET_ATTRIBUTES_CSV_FIELDS_CONFIG_FAIL:
      'GET_ATTRIBUTES_CSV_FIELDS_CONFIG_FAIL',

    ADD_ATTRIBUTES_THROUGH_EXCEL: 'ADD_ATTRIBUTES_THROUGH_EXCEL',
    ADD_ATTRIBUTES_THROUGH_EXCEL_SUCCESS:
      'ADD_ATTRIBUTES_THROUGH_EXCEL_SUCCESS',
    ADD_ATTRIBUTES_THROUGH_EXCEL_FAIL: 'ADD_ATTRIBUTES_THROUGH_EXCEL_FAIL',

    RESET_STATE: 'RESET_STATE'
  },

  ASSET_TYPES: {
    GET_ASSET_TYPES: 'GET_ASSET_TYPES',
    GET_ASSET_TYPES_SUCCESS: 'GET_ASSET_TYPES_SUCCESS',
    GET_ASSET_TYPES_FAIL: 'GET_ASSET_TYPES_FAIL',

    ASSET_TYPES_DETAILS: 'ASSET_TYPES_DETAILS',
    ASSET_TYPES_DETAILS_SUCCESS: 'ASSET_TYPES_DETAILS_SUCCESS',
    ASSET_TYPES_DETAILS_FAIL: 'ASSET_TYPES_DETAILS_FAIL',

    ADD_ASSET_TYPE: 'ADD_ASSET_TYPE',
    ADD_ASSET_TYPE_SUCCESS: 'ADD_ASSET_TYPE_SUCCESS',
    ADD_ASSET_TYPE_FAIL: 'ADD_ASSET_TYPE_FAIL',

    GET_ASSET_ATTRIBUTES: 'GET_ASSET_ATTRIBUTES',
    GET_ASSET_ATTRIBUTES_SUCCESS: 'GET_ASSET_ATTRIBUTES_SUCCESS',
    GET_ASSET_ATTRIBUTES_FAIL: 'GET_ASSET_ATTRIBUTES_FAIL',

    UPDATE_ASSET_TYPE: 'UPDATE_ASSET_TYPE',
    UPDATE_ASSET_TYPE_SUCCESS: 'UPDATE_ASSET_TYPE_SUCCESS',
    UPDATE_ASSET_TYPE_FAIL: 'UPDATE_ASSET_TYPE_FAIL',

    GET_ASSETS_COUNT: 'GET_ASSETS_COUNT',
    GET_ASSETS_COUNT_SUCCESS: 'GET_ASSETS_COUNT_SUCCESS',
    GET_ASSETS_COUNT_FAIL: 'GET_ASSETS_COUNT_FAIL',

    GET_ASSET_TYPES_CSV_FIELDS_CONFIG: 'GET_ASSET_TYPES_CSV_FIELDS_CONFIG',
    GET_ASSET_TYPES_CSV_FIELDS_CONFIG_SUCCESS:
      'GET_ASSET_TYPES_CSV_FIELDS_CONFIG_SUCCESS',
    GET_ASSET_TYPES_CSV_FIELDS_CONFIG_FAIL:
      'GET_ASSET_TYPES_CSV_FIELDS_CONFIG_FAIL',

    ADD_ASSET_TYPES_THROUGH_EXCEL: 'ADD_ASSET_TYPES_THROUGH_EXCEL',
    ADD_ASSET_TYPES_THROUGH_EXCEL_SUCCESS:
      'ADD_ASSET_TYPES_THROUGH_EXCEL_SUCCESS',
    ADD_ASSET_TYPES_THROUGH_EXCEL_FAIL: 'ADD_ASSET_TYPES_THROUGH_EXCEL_FAIL',

    RESET_STATE: 'RESET_STATE'
  },

  DETAILS: {
    RESET_STATE: 'RESET_STATE'
  },

  ASSETS: {
    GET_ASSETS_LIST: 'GET_ASSETS_LIST',
    GET_ASSETS_LIST_SUCCESS: 'GET_ASSETS_LIST_SUCCESS',
    GET_ASSETS_LIST_FAIL: 'GET_ASSETS_LIST_FAIL',

    GET_ASSET_DETAILS: 'GET_ASSET_DETAILS',
    GET_ASSET_DETAILS_SUCCESS: 'GET_ASSET_DETAILS_SUCCESS',
    GET_ASSET_DETAILS_FAIL: 'GET_ASSET_DETAILS_FAIL',

    GET_GENERIC_ASSETS_COUNT: 'GET_GENERIC_ASSETS_COUNT',
    GET_GENERIC_ASSETS_COUNT_SUCCESS: 'GET_GENERIC_ASSETS_COUNT_SUCCESS',
    GET_GENERIC_ASSETS_COUNT_FAIL: 'GET_GENERIC_ASSETS_COUNT_FAIL',

    GET_ASSET_ATTACHMENTS: 'GET_ASSET_ATTACHMENTS',
    GET_ASSET_ATTACHMENTS_SUCCESS: 'GET_ASSET_ATTACHMENTS_SUCCESS',
    GET_ASSET_ATTACHMENTS_FAIL: 'GET_ASSET_ATTACHMENTS_FAIL',

    ADD_ASSET: 'ADD_ASSET',
    ADD_ASSET_SUCCESS: 'ADD_ASSET_SUCCESS',
    ADD_ASSET_FAIL: 'ADD_ASSET_FAIL',

    SHOW_ASSET_ATTACHMENT_IMAGE: 'SHOW_ASSET_ATTACHMENT_IMAGE',
    HIDE_ASSET_ATTACHMENT_IMAGE: 'HIDE_ASSET_ATTACHMENT_IMAGE',

    REMOVE_ASSET_ASSIGNEE: 'REMOVE_ASSET_ASSIGNEE',
    REMOVE_ASSET_ASSIGNEE_SUCCESS: 'REMOVE_ASSET_ASSIGNEE_SUCCESS',
    REMOVE_ASSET_ASSIGNEE_FAIL: 'REMOVE_ASSET_ASSIGNEE_FAIL',

    REMOVE_ASSET: 'REMOVE_ASSET',
    REMOVE_ASSET_SUCCESS: 'REMOVE_ASSET_SUCCESS',
    REMOVE_ASSET_FAIL: 'REMOVE_ASSET_FAIL',

    GET_ASSET_TRACKING_LOG: 'GET_ASSET_TRACKING_LOG',
    GET_ASSET_TRACKING_LOG_SUCCESS: 'GET_ASSET_TRACKING_LOG_SUCCESS',
    GET_ASSET_TRACKING_LOG_FAIL: 'GET_ASSET_TRACKING_LOG_FAIL',

    ADD_ASSET_ASSIGNEE: 'ADD_ASSET_ASSIGNEE',
    ADD_ASSET_ASSIGNEE_SUCCESS: 'ADD_ASSET_ASSIGNEE_SUCCESS',
    ADD_ASSET_ASSIGNEE_FAIL: 'ADD_ASSET_ASSIGNEE_FAIL',

    EDIT_ASSET_ATTRIBUTES: 'EDIT_ASSET_ATTRIBUTES',
    EDIT_ASSET_ATTRIBUTES_SUCCESS: 'EDIT_ASSET_ATTRIBUTES_SUCCESS',
    EDIT_ASSET_ATTRIBUTES_FAIL: 'EDIT_ASSET_ATTRIBUTES_FAIL',

    EDIT_ASSET_INFO: 'EDIT_ASSET_INFO',
    EDIT_ASSET_INFO_SUCCESS: 'EDIT_ASSET_INFO_SUCCESS',
    EDIT_ASSET_INFO_FAIL: 'EDIT_ASSET_INFO_FAIL',

    ADD_ASSET_ATTACHMENTS: 'ADD_ASSET_ATTACHMENTS',
    ADD_ASSET_ATTACHMENTS_SUCCESS: 'ADD_ASSET_ATTACHMENTS_SUCCESS',
    ADD_ASSET_ATTACHMENTS_FAIL: 'ADD_ASSET_ATTACHMENTS_FAIL',

    GET_ASSET_LOCATION_HISTORY: 'GET_ASSET_LOCATION_HISTORY',
    GET_ASSET_LOCATION_HISTORY_SUCCESS: 'GET_ASSET_LOCATION_HISTORY_SUCCESS',
    GET_ASSET_LOCATION_HISTORY_FAIL: 'GET_ASSET_LOCATION_HISTORY_FAIL',

    GET_ASSET_LIVE_LOCATION: 'GET_ASSET_LIVE_LOCATION',
    GET_ASSET_LIVE_LOCATION_SUCCESS: 'GET_ASSET_LIVE_LOCATION_SUCCESS',
    GET_ASSET_LIVE_LOCATION_FAIL: 'GET_ASSET_LIVE_LOCATION_FAIL',

    GET_ASSET_CSV_FIELDS_CONFIG: 'GET_ASSET_CSV_FIELDS_CONFIG',
    GET_ASSET_CSV_FIELDS_CONFIG_SUCCESS: 'GET_ASSET_CSV_FIELDS_CONFIG_SUCCESS',
    GET_ASSET_CSV_FIELDS_CONFIG_FAIL: 'GET_ASSET_CSV_FIELDS_CONFIG_FAIL',

    ADD_ASSET_THROUGH_EXCEL: 'ADD_ASSET_THROUGH_EXCEL',
    ADD_ASSET_THROUGH_EXCEL_SUCCESS: 'ADD_ASSET_THROUGH_EXCEL_SUCCESS',
    ADD_ASSET_THROUGH_EXCEL_FAIL: 'ADD_ASSET_THROUGH_EXCEL_FAIL'
  },

  TRAKING_LOGS: {
    GET_TRACKING_LOGS: 'GET_TRACKING_LOGS',
    GET_TRACKING_LOGS_SUCCESS: 'GET_TRACKING_LOGS_SUCCESS',
    GET_TRACKING_LOGS_FAIL: 'GET_TRACKING_LOGS_FAIL',

    SET_LOADER_TRUE: 'SET_LOADER_TRUE',
    SET_LOADER_FALSE: 'SET_LOADER_FALSE'
  },

  ALERT_MESSAGE: {
    ADD_SUCCESS: 'ADD_SUCCESS',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
    FAIL: 'FAIL',
    RESET_STATE: 'RESET_STATE'
  },

  LIST: {
    RESET_STATE: 'RESET_STATE'
  },

  QR_CODES: {
    GET_QR_CODES: 'GET_QR_CODES',
    GET_QR_CODES_SUCCESS: 'GET_QR_CODES_SUCCESS',
    GET_QR_CODES_FAIL: 'GET_QR_CODES_FAIL',

    ADD_QR_CODES: 'ADD_QR_CODES',
    ADD_QR_CODES_SUCCESS: 'ADD_QR_CODES_SUCCESS',
    ADD_QR_CODES_FAIL: 'ADD_QR_CODES_FAIL',

    RESET_STATE: 'RESET_STATE'
  },

  FILTER: {
    GET_FILTER_RESULT: 'GET_FILTER_RESULT',
    GET_FILTER_RESULT_SUCCESS: 'GET_FILTER_RESULT_SUCCESS',
    GET_FILTER_RESULT_FAIL: 'GET_FILTER_RESULT_FAIL'
  },

  COMMON_STORE: {
    COMMON_RESET_STATE: 'COMMON_RESET_STATE'
  },

  GOOGLE_MAPS: {
    GET_ASSETS_CURRENT_LOCATION: 'GET_ASSETS_CURRENT_LOCATION',
    GET_ASSETS_CURRENT_LOCATION_SUCCESS: 'GET_ASSETS_CURRENT_LOCATION_SUCCESS',
    GET_ASSETS_CURRENT_LOCATION_FAIL: 'GET_ASSETS_CURRENT_LOCATION_FAIL',

    RESET_STATE: 'RESET_STATE'
  },

  CUSTOMISE: {
    CUSTOMISE_APP: 'CUSTOMISE_APP',
    CUSTOMISE_APP_SUCCESS: 'CUSTOMISE_APP_SUCCESS',
    CUSTOMISE_APP_FAIL: 'CUSTOMISE_APP_FAIL'
  },

  ORGANISATION_DETAILS: {
    GET_ORGANISATION_DETAILS: 'GET_ORGANISATION_DETAILS',
    GET_ORGANISATION_DETAILS_SUCCESS: 'GET_ORGANISATION_DETAILS_SUCCESS',
    GET_ORGANISATION_DETAILS_FAIL: 'GET_ORGANISATION_DETAILS_FAIL',

    EDIT_ORGANISATION_DETAILS: 'EDIT_ORGANISATION_DETAILS',
    EDIT_ORGANISATION_DETAILS_SUCCESS: 'EDIT_ORGANISATION_DETAILS_SUCCESS',
    EDIT_ORGANISATION_DETAILS_FAIL: 'EDIT_ORGANISATION_DETAILS_FAIL'
  },

  ROLES: {
    GET_ROLES: 'GET_ROLES',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAIL: 'GET_ROLES_FAIL'
  }
};

export default ACTIONS;
