import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  details: {},
  errorCode: '',
  inProgress: false
};

const DepartmentDetailStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ORG_UNITS.GET_DEPARTMENT_DETAIL:
      return { ...state, inProgress: true };
    case ACTIONS.ORG_UNITS.GET_DEPARTMENT_DETAIL_SUCCESS:
      return { ...state, details: payload, inProgress: false };
    case ACTIONS.ORG_UNITS.GET_DEPARTMENT_DETAIL_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    default:
      return state;
  }
};

const dummy = () => {};

export { DepartmentDetailStore, dummy };
