import { call, put } from 'redux-saga/effects';

import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';
import { FOOTPRINTS_ROUTES } from '../../config';

function* getUserDetails(action) {
  let response;
  try {
    const { data } = action;
    const { userId } = data;
    const API_URL = reverse(FOOTPRINTS_API.USER_WITH_ID, { userId });
    response = yield call(window.axios.getData, API_URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.USERS.GET_USER_INFO_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.USERS.GET_USER_INFO_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.USERS.GET_USER_INFO_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getServiceUserDetails(action) {
  let response;
  try {
    const { data } = action;
    const { userId } = data;
    const API_URL = reverse(FOOTPRINTS_API.SERVICE_ACCOUNT_WITH_ID, { userId });
    response = yield call(window.axios.getData, API_URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.USERS.GET_USER_INFO_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.USERS.GET_USER_INFO_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({ type: ACTIONS.USERS.GET_USER_INFO_FAIL, payload: e });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getAssetTypesDetails(action) {
  let response;
  const { assetTypeId } = action.data;
  try {
    response = yield call(
      window.axios.getData,
      reverse(FOOTPRINTS_API.ASSET_TYPES_DETAILS, { assetTypeId }),
      action.data
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSET_TYPES.ASSET_TYPES_DETAILS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_TYPES.ASSET_TYPES_DETAILS_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_TYPES.ASSET_TYPES_DETAILS_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getAssetAttributesDetails(action) {
  let response;
  const { assetAttrId } = action.data;
  try {
    response = yield call(
      window.axios.getData,
      reverse(FOOTPRINTS_API.ASSET_ATTRIBUTE_DETAILS, { assetAttrId }),
      action.data
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.ASSET_ATTRIBUTE_DETAILS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ASSET_ATTRIBUTES.ASSET_ATTRIBUTE_DETAILS_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSET_ATTRIBUTES.ASSET_ATTRIBUTE_DETAILS_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getAssetDetails(action) {
  let response;
  try {
    const { assetId } = action.data;
    const baseDetailsUrl = reverse(FOOTPRINTS_API.ASSET_DETAILS, { assetId });
    const detailsURL = `${baseDetailsUrl}?fieldtype=pk`;
    response = yield call(window.axios.getData, detailsURL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSET_DETAILS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSET_DETAILS_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSETS.GET_ASSET_DETAILS_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getOrgUnitDetails(action) {
  let response;
  const orgUnitId = action.data.orgUnitId
    ? action.data.orgUnitId
    : action.data.department;

  try {
    response = yield call(
      window.axios.getData,
      reverse(FOOTPRINTS_API.ORG_UNITS_DETAILS, { orgUnitId }),
      action.data
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ORG_UNITS.GET_ORG_UNITS_DETAILS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ORG_UNITS.GET_ORG_UNITS_DETAILS_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ORG_UNITS.GET_ORG_UNITS_DETAILS_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

function* getAssetAttachments(action) {
  let response;
  try {
    const { assetId, logId } = action.data;
    let url = `${reverse(FOOTPRINTS_API.ASSET_ATTACHMENTS)}?asset=${assetId}`;
    if (logId) {
      url = `${reverse(
        FOOTPRINTS_API.ASSET_ATTACHMENTS
      )}?asset=${assetId}&tracking_log=${logId}`;
    }

    response = yield call(window.axios.getData, url);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSET_ATTACHMENTS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSET_ATTACHMENTS_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSETS.GET_ASSET_ATTACHMENTS_FAIL,
      payload: e
    });
  }
}

function* removeAssignee(action) {
  let response;
  try {
    const { assetId } = action.data;
    const data = {
      asset: assetId,
      assignee: null
    };
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.REMOVE_ASSET_ASSIGNEE,
      data
    );
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSET_DETAILS,
        data: action.data
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.REMOVE_ASSET_ASSIGNEE_FAIL
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSETS.GET_ASSET_TRACKING_LOG_FAIL,
      payload: e
    });
  }
}

function* removeAsset(action) {
  let response;
  try {
    const { assetId } = action.data;
    response = yield call(
      window.axios.postData,
      reverse(FOOTPRINTS_API.REMOVE_ASSET, { assetId })
    );
    if (response.status >= 200 && response.status < 300) {
      action.data.history.push(reverse(FOOTPRINTS_ROUTES.ASSET.LIST));
      yield put({
        type: ACTIONS.ASSETS.REMOVE_ASSET_SUCCESS
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.REMOVE_ASSET_FAIL
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSETS.REMOVE_ASSET_FAIL,
      payload: e
    });
  }
}

function* getAssetTrackingLog(action) {
  let response;
  try {
    const { assetId, logId } = action.data;
    response = yield call(
      window.axios.getData,
      reverse(FOOTPRINTS_API.TRACKING_LOG_DETAIL, { assetId, logId })
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSET_TRACKING_LOG_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ASSETS.GET_ASSET_TRACKING_LOG_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ASSETS.GET_ASSET_TRACKING_LOG_FAIL,
      payload: e
    });
  }
}

export {
  getUserDetails,
  getAssetTypesDetails,
  getAssetAttributesDetails,
  getAssetDetails,
  getOrgUnitDetails,
  getAssetAttachments,
  removeAssignee,
  getAssetTrackingLog,
  getServiceUserDetails,
  removeAsset
};
