import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  parentOrgUnitsData: [],
  errorCode: '',
  inProgress: false
};

const addEditOrgUnitReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ORG_UNITS.ADD_ORG_UNIT:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ORG_UNITS.ADD_ORG_UNIT_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ORG_UNITS.ADD_ORG_UNIT_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ORG_UNITS.RESET_STATE:
      return { ...state, errorCode: '' };

    case ACTIONS.ORG_UNITS.UPDATE_ORG_UNIT:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ORG_UNITS.UPDATE_ORG_UNIT_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ORG_UNITS.UPDATE_ORG_UNIT_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    default:
      return state;
  }
};

const dummy = () => {};

export { addEditOrgUnitReducerStore, dummy };
