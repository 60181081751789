import { call, put } from 'redux-saga/effects';

import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* forgotPasswordSaga(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.FORGOT_PASSWORD,
      action.data
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.PASSWORD_RESET.FORGOT_PASSWORD_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.PASSWORD_RESET.FORGOT_PASSWORD_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.PASSWORD_RESET.FORGOT_PASSWORD_FAIL,
      payload: e
    });
  }
}

function* passwordResetSaga(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.PASSWORD_RESET,
      action.data,
      action.headers
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.PASSWORD_RESET.PASSWORD_RESET_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.PASSWORD_RESET.PASSWORD_RESET_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.PASSWORD_RESET.PASSWORD_RESET_FAIL,
      payload: e
    });
  }
}

function* tokenVerifyRequest(action) {
  let response;
  try {
    response = yield call(
      window.axios.postData,
      FOOTPRINTS_API.PASSWORD_RESET_VERIFY_TOKEN,
      action.data,
      action.headers
    );
    if (response.status === 200 || response.status === 204) {
      yield put({
        type: ACTIONS.PASSWORD_RESET.VERIFY_TOKEN_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.PASSWORD_RESET.VERIFY_TOKEN_FAIL,
        payload: response.data
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.PASSWORD_RESET.VERIFY_TOKEN_FAIL,
      payload: e
    });
  }
}

export { forgotPasswordSaga, passwordResetSaga, tokenVerifyRequest };
