import Analytics from './analytics.svg';
import AnalyticsWhite from './analytics-white.svg';
import AssetTypes from './asset-types.svg';
import AssetTypesWhite from './asset-types-white.svg';
import Assets from './assets.svg';
import AssetsWhite from './assets-white.svg';
import Attributes from './attributes.svg';
import AttributesWhite from './attributes-white.svg';
import Customise from './customise.svg';
import Departments from './departments.svg';
import DepartmentsWhite from './departments-white.svg';
import IdReaders from './id-readers.svg';
import IdReadersWhite from './id-readers-white.svg';
import Users from './users.svg';
import UsersWhite from './users-white.svg';
import Organisation from './organisation.svg';
import OrganisationWhite from './organisation-white.svg';
import UserRoles from './user-roles.svg';
import UserRolesWhite from './user-roles-white.svg';
import BusinessRules from './business-rules.svg';
import BusinessRulesWhite from './business-rules-white.svg';

const sidenavIcons = {
  Analytics,
  AnalyticsWhite,
  Assets,
  AssetsWhite,
  AssetTypes,
  AssetTypesWhite,
  Attributes,
  AttributesWhite,
  Customise,
  Departments,
  DepartmentsWhite,
  IdReaders,
  IdReadersWhite,
  Users,
  UsersWhite,
  Organisation,
  OrganisationWhite,
  UserRoles,
  UserRolesWhite,
  BusinessRules,
  BusinessRulesWhite
};

export default sidenavIcons;
