import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  data: [],
  errorCode: '',
  inProgress: false
};

const DepartmentListStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ORG_UNITS.GET_ALL_ORG_UNIT:
      return { ...state, inProgress: true };
    case ACTIONS.ORG_UNITS.GET_ALL_ORG_UNIT_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ORG_UNITS.GET_ALL_ORG_UNIT_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    default:
      return state;
  }
};

const dummy = () => {};

export { DepartmentListStore, dummy };
