import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { PAGE, COMPONENTS } from './utils/constants';
import AuthRoute from './components/AuthRoute';
import AppliedRoute from './components/AppliedRoute';
import RedirectRoute from './components/RedirectRoute';
import PageLoader from './components/PageLoader/PageLoader';
import {
  FOOTPRINTS_ROUTES,
  userSessionCookie,
  rememberUserCookie
} from './config';

const delayTime = 60;
// Login
const AsyncLogin = Loadable({
  loader: () => import('./containers/Login'),
  loading: PageLoader,
  delay: delayTime
});

// Password Reset / Forgot Password
const AsyncForgotPassword = Loadable({
  loader: () => import('./containers/PasswordReset/ForgotPassword'),
  loading: PageLoader,
  delay: delayTime
});

const AsyncPasswordReset = Loadable({
  loader: () => import('./containers/PasswordReset/PasswordReset'),
  loading: PageLoader,
  delay: delayTime
});

// Analytics
const AsyncAnalytics = Loadable({
  loader: () => import('./containers/Analytics/Analytics'),
  loading: PageLoader,
  delay: delayTime
});

// Generic List Page
const AsyncListPage = Loadable({
  loader: () => import('./containers/ListPage/ListPage'),
  loading: PageLoader,
  delay: delayTime
});

// Generic Details Page
const AsyncDetails = Loadable({
  loader: () => import('./containers/Details/Details'),
  loading: PageLoader,
  delay: delayTime
});

// Add Edit User Page
const AsyncAddEditUser = Loadable({
  loader: () => import('./containers/AddEditUser/AddEditUser'),
  loading: PageLoader,
  delay: delayTime
});

// Add Edit Org Unit Page
const AsyncAddEditOrgUnit = Loadable({
  loader: () => import('./containers/AddEditOrgUnits/AddEditOrgUnits'),
  loading: PageLoader,
  delay: delayTime
});

// Add Edit Asset Types Page
const AsyncAddEditAssetTypes = Loadable({
  loader: () => import('./containers/AddEditAssetTypes/AddEditAssetTypes'),
  loading: PageLoader
});

// Add Edit Attributes
const AsyncAddEditAssetAttributes = Loadable({
  loader: () =>
    import('./containers/AddEditAssetAttributes/AddEditAssetAttributes'),
  loading: PageLoader
});

const AsyncGenerateQrCodes = Loadable({
  loader: () => import('./containers/GenerateQrCodes/GenerateQrCodes'),
  loading: PageLoader,
  delay: delayTime
});

// Add asset page
const AsyncAddEditAsset = Loadable({
  loader: () => import('./containers/AddAsset/AddAsset'),
  loading: PageLoader,
  delay: delayTime
});

// Add Edit Service Account
const AsyncAddEditServiceAccount = Loadable({
  loader: () =>
    import('./containers/AddEditServiceAccount/AddEditServiceAccount'),
  loading: PageLoader,
  delay: delayTime
});

// Asset Location history
const AsyncAssetLocationHistory = Loadable({
  loader: () => import('./components/AssetLocations/AssetLocations'),
  loading: PageLoader,
  delay: delayTime
});

// Asset Live Location
const AsyncAssetLiveLocation = Loadable({
  loader: () => import('./containers/AssetLiveLocation/AssetLiveLocation'),
  loading: PageLoader,
  delay: delayTime
});

// Customise
const AsyncCustomise = Loadable({
  loader: () => import('./containers/Customise/Customise'),
  loading: PageLoader,
  delay: delayTime
});

const AsyncImportExcel = Loadable({
  loader: () => import('./containers/ImportExcel/ImportExcel'),
  loading: PageLoader,
  delay: delayTime
});

// Organisation Details with Edit
const AsyncOrganisation = Loadable({
  loader: () => import('./containers/Organisation/Organisation'),
  loading: PageLoader,
  delay: delayTime
});

// Department Tree List
const AsyncDepartmentList = Loadable({
  loader: () => import('./containers/DepartmentList/DepartmentList'),
  loading: PageLoader,
  delay: delayTime
});

// Invalid Resource
const AsyncNotFound = Loadable({
  loader: () => import('./containers/NotFound'),
  loading: PageLoader,
  delay: delayTime
});

// List attributes page
const AttributesListPage = Loadable({
  loader: () => import('./containers/AttributesList/AttributesList'),
  loading: PageLoader,
  delay: delayTime
});

// List asset type page
const AssetTypeListPage = Loadable({
  loader: () => import('./containers/AssetTypeList/AssetTypeList'),
  loading: PageLoader,
  delay: delayTime
});
// Role list page
const RoleListPage = Loadable({
  loader: () => import('./containers/RoleList/RoleList'),
  loading: PageLoader,
  delay: delayTime
});

// List users page
const UserListPage = Loadable({
  loader: () => import('./containers/UsersList/UsersList'),
  loading: PageLoader,
  delay: delayTime
});

// List ID readers page
const ServiceUsersListPage = Loadable({
  loader: () => import('./containers/ServiceUsersList/ServiceUsersList'),
  loading: PageLoader,
  delay: delayTime
});

// Role add edit page
const RoleAddEditPage = Loadable({
  loader: () => import('./containers/RoleAddEdit/RoleAddEdit'),
  loading: PageLoader,
  delay: delayTime
});

// User Profile
const UserProfile = Loadable({
  loader: () => import('./containers/UserProfile/UserProfile'),
  loading: PageLoader,
  delay: delayTime
});

// List assets page
const AssetsListPage = Loadable({
  loader: () => import('./containers/AssetsList/AssetsList'),
  loading: PageLoader,
  delay: delayTime
});

// Business rules listing page
const BusinessRulesListPage = Loadable({
  loader: () => import('./containers/BusinessRuleList/BusinessRuleList'),
  loading: PageLoader,
  delay: delayTime
});

// Business rules details page
const BusinessRulesDetailsPage = Loadable({
  loader: () => import('./containers/BusinessRuleDetails/BusinessRuleDetails'),
  loading: PageLoader,
  delay: delayTime
});

// Business rules add/edit page
const AddEditBusinessRulesPage = Loadable({
  loader: () =>
    import('./containers/AddEditBusinessRules/AddEditBusinessRules'),
  loading: PageLoader,
  delay: delayTime
});

// Licence
const LicencePage = Loadable({
  loader: () => import('./containers/Licence'),
  loading: PageLoader,
  delay: delayTime
});

// About Us
const AboutUsPage = Loadable({
  loader: () => import('./containers/AboutUs'),
  loading: PageLoader,
  delay: delayTime
});

// Assets Reports
const AssetsReport = Loadable({
  loader: () => import('./containers/AssetsReport/AssetsReport'),
  loading: PageLoader,
  delay: delayTime
});

const Routes = ({ childProps }) => {
  const props = {
    ...childProps,
    sessionCookie: userSessionCookie,
    rememberCookie: rememberUserCookie,
    redirectionRoute: FOOTPRINTS_ROUTES.ANALYTICS
  };
  return (
    <BrowserRouter basename="">
      <>
        <Switch>
          <RedirectRoute path="/" exact component={AsyncLogin} props={props} />
          <AppliedRoute
            path={FOOTPRINTS_ROUTES.LOGIN}
            exact
            component={AsyncLogin}
            props={props}
          />
          <AppliedRoute
            path={FOOTPRINTS_ROUTES.FORGOT_PASSWORD}
            exact
            component={AsyncForgotPassword}
            props={props}
          />
          <AppliedRoute
            path={FOOTPRINTS_ROUTES.PASSWORD_RESET}
            exact
            component={AsyncPasswordReset}
            props={props}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ANALYTICS}
            exact
            component={AssetsReport}
            props={{ ...props, page: PAGE.ANALYTICS }}
          />

          {/* User Profile */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.USER_PROFILE}
            exact
            component={UserProfile}
            props={{ ...props, page: PAGE.USER_PROFILE }}
          />

          {/* Business Rules List */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.BUSINESS_RULES.LIST}
            exact
            component={BusinessRulesListPage}
            props={{ ...props, page: PAGE.BUSINESS_RULES.LIST }}
          />

          {/* Add Business Rules */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.BUSINESS_RULES.ADD}
            exact
            component={AddEditBusinessRulesPage}
            props={{ ...props, page: PAGE.BUSINESS_RULES.ADD }}
          />

          {/* Business Rules Detail */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.BUSINESS_RULES.DETAIL.SHOW}
            exact
            component={BusinessRulesDetailsPage}
            props={{ ...props, page: PAGE.BUSINESS_RULES.DETAIL }}
          />

          {/* Edit Business Rules */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.BUSINESS_RULES.DETAIL.MODIFY}
            exact
            component={AddEditBusinessRulesPage}
            props={{ ...props, page: PAGE.BUSINESS_RULES.EDIT }}
          />

          {/* Organization Units */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ORG_UNITS.LIST}
            exact
            component={AsyncDepartmentList}
            props={{
              ...props,
              page: PAGE.ORG_UNITS,
              pageBody: COMPONENTS.CARD
            }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ORG_UNITS.ADD}
            exact
            component={AsyncAddEditOrgUnit}
            props={{ ...props, page: PAGE.ADD_ORG_UNIT }}
          />

          <AuthRoute
            path={FOOTPRINTS_ROUTES.ORG_UNITS.IMPORT_CSV}
            exact
            component={AsyncImportExcel}
            props={{ ...props, page: PAGE.ORG_UNITS_IMPORT_CSV }}
          />

          {/* Organization Units Details */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ORG_UNITS.DETAIL.SHOW}
            exact
            component={AsyncDepartmentList}
            props={{ ...props, page: PAGE.ORG_UNITS_DETAILS }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ORG_UNITS.DETAIL.MODIFY}
            exact
            component={AsyncAddEditOrgUnit}
            props={{ ...props, page: PAGE.UPDATE_ORG_UNIT }}
          />
          {/* List Users */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.USERS.LIST}
            exact
            component={UserListPage}
            props={{
              ...props,
              page: PAGE.USERS
            }}
          />
          {/* List ID readers */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.SERVICE_ACCOUNTS.LIST}
            exact
            component={ServiceUsersListPage}
            props={{
              ...props,
              page: PAGE.SERVICE_ACCOUNTS
            }}
          />
          {/* List Asset Types */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET_TYPES.LIST}
            exact
            component={AssetTypeListPage}
            props={{
              ...props,
              page: PAGE.ASSET_TYPES
            }}
          />
          {/* List Attributes */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ATTRIBUTES.LIST}
            exact
            component={AttributesListPage}
            props={{ ...props, page: PAGE.ATTRIBUTES }}
          />

          {/* Add Edit Attributes */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ATTRIBUTES.ADD}
            exact
            component={AsyncAddEditAssetAttributes}
            props={{ ...props, page: PAGE.ADD_ASSET_ATTRIBUTES }}
          />
          {/* Add Asset Types */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET_TYPES.ADD}
            exact
            component={AsyncAddEditAssetTypes}
            props={{ ...props, page: PAGE.ADD_ASSET_TYPES }}
          />

          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET_TYPES.IMPORT_CSV}
            exact
            component={AsyncImportExcel}
            props={{ ...props, page: PAGE.ASSET_TYPES_IMPORT_CSV }}
          />

          {/* Add Edit Service Account */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.SERVICE_ACCOUNTS.ADD}
            exact
            component={AsyncAddEditServiceAccount}
            props={{ ...props, page: PAGE.ADD_SERVICE_ACCOUNT }}
          />
          {/* Asset Types Details */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET_TYPES.DETAIL.SHOW}
            exact
            component={AsyncDetails}
            props={{ ...props, page: PAGE.ASSET_TYPES_DETAILS }}
          />

          <AuthRoute
            path={FOOTPRINTS_ROUTES.ATTRIBUTES.IMPORT_CSV}
            exact
            component={AsyncImportExcel}
            props={{ ...props, page: PAGE.ASSET_ATTRIBUTES_IMPORT_CSV }}
          />

          {/* Attributes Details */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ATTRIBUTES.DETAIL.SHOW}
            exact
            component={AsyncDetails}
            props={{ ...props, page: PAGE.ASSET_ATTRIBUTE_DETAILS }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.USERS.ADD}
            exact
            component={AsyncAddEditUser}
            props={{ ...props, page: PAGE.ADD_USER }}
          />

          <AuthRoute
            path={FOOTPRINTS_ROUTES.USERS.IMPORT_CSV}
            exact
            component={AsyncImportExcel}
            props={{ ...props, page: PAGE.USERS_IMPORT_CSV }}
          />

          <AuthRoute
            path={FOOTPRINTS_ROUTES.USERS.DETAIL.SHOW}
            exact
            component={AsyncDetails}
            props={{ ...props, page: PAGE.USER_DETAILS }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.USERS.DETAIL.MODIFY}
            exact
            component={AsyncAddEditUser}
            props={{ ...props, page: PAGE.UPDATE_USER }}
          />

          <AuthRoute
            path={FOOTPRINTS_ROUTES.SERVICE_ACCOUNTS.IMPORT_CSV}
            exact
            component={AsyncImportExcel}
            props={{ ...props, page: PAGE.SERVICE_ACCOUNTS_IMPORT_CSV }}
          />
          {/* Service Account Details */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.SERVICE_ACCOUNTS.DETAIL.SHOW}
            exact
            component={AsyncDetails}
            props={{ ...props, page: PAGE.SERVICE_ACCOUNT_DETAILS }}
          />
          {/* Service Account Update/Edit */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.SERVICE_ACCOUNTS.DETAIL.MODIFY}
            exact
            component={AsyncAddEditServiceAccount}
            props={{ ...props, page: PAGE.UPDATE_SERVICE_ACCOUNT }}
          />
          {/* Attributes Update */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ATTRIBUTES.DETAIL.MODIFY}
            exact
            component={AsyncAddEditAssetAttributes}
            props={{ ...props, page: PAGE.UPDATE_ASSET_ATTRIBUTES }}
          />
          {/* Asset Types Update */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET_TYPES.DETAIL.MODIFY}
            exact
            component={AsyncAddEditAssetTypes}
            props={{ ...props, page: PAGE.UPDATE_ASSET_TYPES }}
          />
          {/* Assets list */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.LIST}
            exact
            component={AssetsListPage}
            props={{ ...props, page: PAGE.ASSET, pageBody: COMPONENTS.TABLE }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.ADD}
            exact
            component={AsyncAddEditAsset}
            props={{ ...props, page: PAGE.ADD_ASSET }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.IMPORT_CSV}
            exact
            component={AsyncImportExcel}
            props={{ ...props, page: PAGE.ASSET_IMPORT_CSV }}
          />

          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.DETAIL.SHOW}
            exact
            component={AsyncDetails}
            props={{ ...props, page: PAGE.ASSET_DETAILS }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.DETAIL.LOG.SHOW}
            exact
            component={AsyncDetails}
            props={{ ...props, page: PAGE.ASSET_LOG }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.DETAIL.MODIFY_INFO}
            exact
            component={AsyncAddEditAsset}
            props={{ ...props, page: PAGE.EDIT_ASSET_INFO }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.DETAIL.MODIFY_ATTRIBUTE}
            exact
            component={AsyncAddEditAsset}
            props={{ ...props, page: PAGE.EDIT_ASSET_ATTRIBUTES }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.DETAIL.MODIFY_ASSIGNEE}
            exact
            component={AsyncAddEditAsset}
            props={{ ...props, page: PAGE.ADD_ASSET_ASSIGNEE }}
          />
          {/* Add attachments */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.DETAIL.ADD_ATTACHMENTS}
            exact
            component={AsyncAddEditAsset}
            props={{ ...props, page: PAGE.ADD_ATTACHMENTS }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.QR_CODES.LIST}
            exact
            component={AsyncListPage}
            props={{
              ...props,
              page: PAGE.QR_CODES,
              pageBody: COMPONENTS.TABLE
            }}
          />
          <AuthRoute
            path={FOOTPRINTS_ROUTES.QR_CODES.ADD}
            exact
            component={AsyncGenerateQrCodes}
            props={{ ...props, page: PAGE.ADD_QR_CODES }}
          />
          {/* Asset Location history */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.DETAIL.LOCATION_HISTORY}
            exact
            component={AsyncAssetLocationHistory}
            props={{ ...props, page: PAGE.ASSET_LOCATION_HISTORY }}
          />
          {/* Asset Live Location */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ASSET.DETAIL.LIVE_LOCATION}
            exact
            component={AsyncAssetLiveLocation}
            props={{ ...props, page: PAGE.ASSET_LIVE_LOCATION }}
          />
          {/* Customise */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.CUSTOMISE}
            exact
            component={AsyncCustomise}
            props={{ ...props, page: PAGE.CUSTOMISE }}
          />
          {/* Organisation */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ORGANISATION}
            exact
            component={AsyncOrganisation}
            props={{ ...props, page: PAGE.ORGANISATION }}
          />

          {/* List all the roles */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ROLES.LIST}
            exact
            component={RoleListPage}
            props={{ ...props, page: PAGE.ROLE.LIST }}
          />

          {/* Edit role */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ROLES.DETAIL.MODIFY}
            exact
            component={RoleAddEditPage}
            props={{ ...props, page: PAGE.ROLE.EDIT }}
          />

          {/* Add role */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ROLES.ADD}
            exact
            component={RoleAddEditPage}
            props={{ ...props, page: PAGE.ROLE.ADD }}
          />

          {/* Licence */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.LICENCE}
            exact
            component={LicencePage}
            props={{ ...props, page: PAGE.LICENCE }}
          />

          {/* About us */}
          <AuthRoute
            path={FOOTPRINTS_ROUTES.ABOUT_US}
            exact
            component={AboutUsPage}
            props={{ ...props, page: PAGE.ABOUT_US }}
          />

          {/* Finally, catch all unmatched routes */}
          <Route component={AsyncNotFound} />
        </Switch>
      </>
    </BrowserRouter>
  );
};

Routes.propTypes = {
  childProps: PropTypes.element
};

Routes.defaultProps = {
  childProps: <></>
};

export default Routes;
