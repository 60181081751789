import { call, put } from 'redux-saga/effects';

import { reverse } from 'named-urls';
import ACTIONS from '../../apiConfig/actions.constants';
import { FOOTPRINTS_API } from '../../apiConfig/api.config';

function* getDepartmentDetail(action) {
  let response;
  const orgUnitId = action.data.orgUnitId
    ? action.data.orgUnitId
    : action.data.department;

  try {
    response = yield call(
      window.axios.getData,
      reverse(FOOTPRINTS_API.ORG_UNITS_DETAILS, { orgUnitId }),
      action.data
    );
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ORG_UNITS.GET_DEPARTMENT_DETAIL_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ORG_UNITS.GET_DEPARTMENT_DETAIL_FAIL,
        payload: response.data
      });
      yield put({
        type: ACTIONS.ALERT_MESSAGE.FAIL,
        payload: { ...response.data, ...action.data }
      });
    }
  } catch (e) {
    yield put({
      type: ACTIONS.ORG_UNITS.GET_DEPARTMENT_DETAIL_FAIL,
      payload: e
    });
    yield put({
      type: ACTIONS.ALERT_MESSAGE.FAIL,
      payload: { ...response.data, ...action.data }
    });
  }
}

const dummy = () => {};

export { getDepartmentDetail, dummy };
