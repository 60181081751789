import {
  CONNECTION_ERROR,
  INTERNAL_SERVER,
  TOKEN_EXPIRED,
  OLD_NEW_PASSWORD_SAME,
  EMAIL_EXIST
} from './errorMessages';
import { PAGE } from './constants';

const ERROR_CODES = {
  [PAGE.LOGIN]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Email or password is invalid',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1016: EMAIL_EXIST,
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },
  [PAGE.FORGOT_PASSWORD]: {
    invalid: 'Email id not registered with footprints',
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Invalid',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1016: EMAIL_EXIST,
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },
  [PAGE.RESET_PASSWORD]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Invalid',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1016: EMAIL_EXIST,
    1008: TOKEN_EXPIRED,
    1013: OLD_NEW_PASSWORD_SAME,
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },
  [PAGE.CHANGE_PASSWORD]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Old password is Invalid',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1016: EMAIL_EXIST,
    1024: OLD_NEW_PASSWORD_SAME,
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },
  [PAGE.ADD_ORG_UNIT]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1016: EMAIL_EXIST,
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },
  [PAGE.ADD_USER]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'NOt found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1016: EMAIL_EXIST,
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },
  [PAGE.ADD_ASSET_TYPES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },
  [PAGE.ASSET_ATTRIBUTES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Attributes not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.USERS]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Users not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ORG_UNITS]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Org unit not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ASSET]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Assets not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ASSET_TYPES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Asset type not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ASSET_ATTRIBUTES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Attributes',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ORG_UNITS_DETAILS]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Org unit is not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.USER_DETAILS]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'User not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ASSET_DETAILS]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Asset is Not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ASSET_TYPES_DETAILS]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Asset type not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ASSET_ATTRIBUTE_DETAILS]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Asset attribute is not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.UPDATE_ORG_UNIT]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Org unit is not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.UPDATE_USER]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'User is not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ADD_ASSET_ATTRIBUTES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Not Found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1016: 'Attribute name is already existed.',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.UPDATE_ASSET_ATTRIBUTES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Attribute with this name already exists.',
    1005: 'Collision',
    1006: 'Other attribute value already exists with the same value.',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ADD_ASSET_TYPES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'One or more values passed are not of the mentioned attribute type.',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1016: 'Asset Type name is already existed.',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.UPDATE_ASSET_TYPES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Asset Type is Not Found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ADD_QR_CODES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Not Found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.QR_CODES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Not Found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ANALYTICS]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Org unit not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.EDIT_ASSET_INFO]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Asset name cannot be the same as the current one.',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1010: 'Name is Required',
    1018: 'Wrong data format',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.EDIT_ASSET_ATTRIBUTES]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Org unit not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1010: 'Name is Required',
    1018: 'Wrong data format',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ADD_ASSET_ASSIGNEE]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Org unit not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1010: 'Name is Required',
    1018: 'Wrong data format',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ADD_ATTACHMENTS]: {
    1010: 'Notes is Required',
    1018: 'Wrong data format',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ADD_ASSET]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Org unit not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1010: 'Name is Required',
    1018: 'Wrong data format',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ASSET_IMPORT_CSV]: {
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1005: 'Collision',
    1006: 'Asset Duplication',
    1007: 'Path Complete',
    1049: 'One or More Asset Types Does Not Exist',
    1050: 'One or More Departments Does Not Exist',
    1051: 'One or More Attributes Does Not Exist',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ORG_UNITS_IMPORT_CSV]: {
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1005: 'Collision',
    1007: 'Path Complete',
    1019: 'Invalid Parent Id',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.USERS_IMPORT_CSV]: {
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1005: 'Collision',
    1007: 'Path Complete',
    1016: EMAIL_EXIST,
    1019: 'Invalid Parent Id',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ASSET_ATTRIBUTES_IMPORT_CSV]: {
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'One or more values passed are not of the mentioned attribute type.',
    1005: 'Collision',
    1006: 'Other attribute value already exists with the same value.',
    1016: 'The fields name, organisation must make a unique set.',
    1021: 'One or more value are null',
    1022: 'Attributes type, constraint or set invalid.',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ASSET_TYPES_IMPORT_CSV]: {
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Invalid attribute Id passed.',
    1005: 'Collision',
    1010: 'Some data missing',
    1016: 'The fields name, organisation must make a unique set.',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.ROLE.ADD]: {
    1046: 'A role with the same name exists'
  },

  [PAGE.SERVICE_ACCOUNTS_IMPORT_CSV]: {
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Invalid attribute Id passed.',
    1005: 'Collision',
    1019: 'Some data not exists',
    1010: 'Some data missing',
    1016: 'The fields name, organisation must make a unique set.',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.SERVICE_ACCOUNTS]: {
    1000: 'Query Params Missing',
    1001: 'JSON Body Missing',
    1002: 'Value Error',
    1003: 'Type Error',
    1004: 'Users not found',
    1005: 'Collision',
    1006: 'RedunDancy Check Error',
    1007: 'Path Complete',
    1500: INTERNAL_SERVER,
    1530: CONNECTION_ERROR
  },

  [PAGE.CUSTOMISE]: {
    1004: 'Logo size should be less than 2 MB'
  },

  [PAGE.ORGANISATION]: {
    1004: 'Logo size should be less than 2 MB'
  }
};

const dummy = () => {};

export { ERROR_CODES, dummy };
