import ACTIONS from '../../apiConfig/actions.constants';
import { STORE } from '../../utils/constants';

const initialState = {
  errorCode: '',
  inProgress: false,
  [STORE.ASSET_ATTRIBUTE_DETAILS]: ''
};

const addEditAssetAttributesReducerStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ASSET_ATTRIBUTES.ADD_ASSET_ATTRIBUTES:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSET_ATTRIBUTES.ADD_ASSET_ATTRIBUTES_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ASSET_ATTRIBUTES.ADD_ASSET_ATTRIBUTES_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ASSET_ATTRIBUTES.UPDATE_ASSET_ATTRIBUTES:
      return { ...state, data: payload, inProgress: true };
    case ACTIONS.ASSET_ATTRIBUTES.UPDATE_ASSET_ATTRIBUTES_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ASSET_ATTRIBUTES.UPDATE_ASSET_ATTRIBUTES_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ORG_UNITS.RESET_STATE:
      return { ...state, errorCode: '', inProgress: false };
    default:
      return state;
  }
};

const dummy = () => {};

export { addEditAssetAttributesReducerStore, dummy };
