import ACTIONS from '../../apiConfig/actions.constants';

const initialState = {
  data: {},
  errorCode: '',
  inProgress: false
};

const OrganisationDetailsStore = (stateParam, { type, payload }) => {
  const state = stateParam || initialState;
  switch (type) {
    case ACTIONS.ORGANISATION_DETAILS.GET_ORGANISATION_DETAILS:
      return { ...state, inProgress: true };
    case ACTIONS.ORGANISATION_DETAILS.GET_ORGANISATION_DETAILS_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ORGANISATION_DETAILS.GET_ORGANISATION_DETAILS_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    case ACTIONS.ORGANISATION_DETAILS.EDIT_ORGANISATION_DETAILS:
      return { ...state, inProgress: true };
    case ACTIONS.ORGANISATION_DETAILS.EDIT_ORGANISATION_DETAILS_SUCCESS:
      return { ...state, data: payload, inProgress: false };
    case ACTIONS.ORGANISATION_DETAILS.EDIT_ORGANISATION_DETAILS_FAIL:
      return { ...state, errorCode: payload.error_code, inProgress: false };

    default:
      return state;
  }
};

const dummy = () => {};

export { OrganisationDetailsStore, dummy };
